export default {
    add: 'Добавити',
    addMedia: 'Добавити видиво',
    addMediaCTA: 'Почніть із додаванням видиво до сеансу.',
    admin: 'Господар',
    advanced: 'Додатково',
    allow: 'Дозволити',
    allowTracking: 'Надсилати інформацію про використання програми',
    allowTrackingDesc: 'Анонімна інформація про використання надсилається розробнику, щоб краще зрозуміти, як використовується програма.',
    always: 'Завжди',
    appearance: 'Інтерфейс',
    autoFullscreen: 'Авто-повноекранний лад',
    autoFullscreenDesc: 'Збільшити, щоб вмістити активне видиво у рамці вікна.',
    avatar: 'Образ',
    avatarCredit: '<0>Вибраний образ</0> <1>{{artist}}</1>',
    cancel: 'Скасувати',
    changelog: 'Зміни',
    chat: 'Бесіда',
    chatColor: 'Колір бесіди',
    chatMessageFailed: 'Не вдалося надіслати повідомлення. Будь ласка спробуйте ще раз.',
    chatPlaceholder: 'Повідомлення або посилання',
    chatRevealHint: 'Натисніть <1></1>, щоб відкрити бесіду.',
    chatNewMessage: 'Опубліковано нове повідомлення!',
    chatTimestamp: 'Позначки часу у бесіді',
    chatTimestampDesc: 'Показувати позначку часу поруч із повідомленням у бесіді.',
    chatTyping1: '<0></0> друкує…',
    chatTyping2: '<0></0> та <2></2> друкують…',
    chatTyping3: '<0></0>, <2></2>, та <4></4> друкують…',
    chatTypingSeveral: 'Кілька людей набирають текст…',
    chooseDisplayName: 'Виберіть своє відображуване ім’я',
    connecting: 'Підключення',
    continue: 'Продовжити',
    copy: 'Копіювати',
    copyLink: 'Копіювати посилання',
    debug: 'Налагоджувач',
    developerMode: 'Режим розробника',
    developerModeDesc: 'Показати інструменти розробника для перевірки веб-сторінок.',
    directIP: 'Прямий IP',
    disallow: 'Заборонити',
    disconnect: 'Відключити',
    disconnected: 'Відключено',
    displayName: 'Відображуване ім’я',
    dj: 'DJ',
    donate: 'Пожертвувати',
    donators: 'Меценати',
    duplicate: 'Дублювати',
    embedBlocked: 'Щоб увімкнути відтворення за допомогою <1>{host}}</1>, Metastream має відкрити веб-сайт у спливаючому меню.',
    endSessionTitle: 'Завершити сеанс?',
    endSessionDescription: 'Перехід до автономного сеансу призведе до відключення всіх підключених користувачів.',
    endSessionModalDescription: 'Усі користувачі будуть відключені від сеансу.',
    endSessionButton: 'Завершити сеанс',
    enterJoinDest: 'Введіть посилання на сеанс Metastream.',
    findSession: 'Знайти сеанс',
    findSessionDescription: 'Приєднуйтесь до каналу <1>#sessions</1> в спільноті Metastream Discord, щоб знайти сеанси інших користувачів. Натисніть логотип Discord нижче, щоб приєднатися.',
    focusPopup: 'Зосередити спливаюче вікно',
    friendCode: 'Код друга',
    getStarted: 'Початок роботи',
    help: 'Допомога',
    info: 'Інформація',
    installToDesktop: 'Встановити на робочий стіл',
    interact: 'Взаємодіяти',
    invite: 'Запросити',
    join: 'Приєднатися',
    joinSession: 'Приєднатися до сеансу',
    kick: 'Видворити',
    language: 'Мова',
    lockQueue: 'Заблокувати чергу',
    moveToTop: 'Перемістити на початок',
    maxUsers: 'Максимальна кількість користувачів',
    next: 'Наступний',
    nextUp: 'У черзі',
    networkDisconnectHostDisconnect: "Господар закрив зв'язок.",
    networkDisconnectError: 'Помилка мережі.',
    networkDisconnectInvalidClientInfo: 'Недійсні відомості про споживоча.',
    networkDisconnectVersionMismatch: `Невідповідність споживаючої версії. Ваш споживач або господар вимагає оновлення.`,
    networkDisconnectFull: 'Сеанс заповнено.',
    networkDisconnectKicked: 'Вигнали з сесії.',
    networkDisconnectMultiTab: 'Ви розміщуєте сеанс Metastream з більш ніж одного браузера або вкладки. Закрийте інші вкладки або інші браузери, а потім перезавантажте сторінку.',
    networkDisconnectSessionNotFound: 'Сеанс не знайдено.',
    networkTroubleshootingHelp: 'Щоб отримати довідку, перегляньте <1>Посібник із Виправлення неполадок мережі</1>.',
    noticeAddedMedia: 'Додано <Media id="{{mediaId}}">{{mediaTitle}}</Media>',
    noticeMediaError: 'Під час запиту сталася помилка {{url}}',
    noticeNowPlaying: 'Відтворюється <Media id="{{mediaId}}">{{mediaTitle}}</Media> запропонував <Username id="{{userId}}">{{username}}</Username>.',
    noticeUserRequestJoin: '<Username id="{{userId}}">{{username}}</Username> запитує дозвіл на приєднання.',
    offline: 'Закритий',
    ok: 'Гаразд',
    once: 'Один раз',
    openInBrowser: 'Відкрити у браузері',
    openInPopup: 'Відкрити у спливаючому вікні',
    openSecondPopup: '(ще раз)',
    patreonLogin: 'Увійти за допомогою Patreon',
    patreonLogout: 'Вийти',
    patreonPledge: 'Стати меценатом',
    patreonPledgeRequired: 'Доступно лише меценатам',
    patreonPledgeTierRequired: 'Доступно лише меценатам, у яких рівень {{tier}}',
    playingInPopup: '<0>{{host}}</0> відтворюється у спливаючому вікні.',
    popupBlocked: 'Відкриття спливаючого вікно заблоковано. Будь ласка, дозвольте спливаючі вікна на цьому сайті або натисніть кнопку ще раз.',
    private: 'Особистий',
    profile: 'Обрис',
    public: 'Прилюдний',
    reload: 'Оновити',
    remove: 'Видалити',
    repeat: 'Повторити',
    retry: 'Повторити спробу',
    requestUrl: 'Додати до сеансу',
    requestUrlPlaceholder: '',
    required: 'Необхідний',
    requiresDJPermissions: 'Потрібні дозволи DJ',
    requiresPortForward: 'Потрібно <1>налаштувати пересилання портів</1> з <3>{{port}}</3>, щоб прийняти прямі підключення.',
    safeBrowse: 'Безпечний перегляд',
    safeBrowseDesc: 'Запитувати про затвердження перед завантаженням нерозпізнаного веб-сайту.',
    safeBrowseLoadContent: 'Завантажити вміст із <1>{{host}}</1>?',
    safeBrowseDisableForSession: 'Вимкнути безпечний пошук для цього сеансу',
    session: 'Сеанс',
    sessionMode: 'Режим сеансу',
    sessionModePublicDescription: 'Будь-хто може приєднатися.',
    sessionModePrivateDescription: 'Дозвіл на приєднання має бути наданий.',
    sessionModeOfflineDescription: 'Ніхто не може приєднатися.',
    settings: 'Налаштування',
    shareFriendCode: 'Поділіться кодом свого друга нижче, щоб запросити друзів.',
    shareFriendCodeOther: 'Поділіться кодом друга {{name}}’s нижче, щоб запросити друзів.',
    sharePublicIP: 'Поділіться <1>своєю загальнодоступною IP-адресою</1>, щоб дозволити друзям підключатися безпосередньо.',
    shareSessionUrl: 'Поділіться посиланням на сеанс нижче, щоб запросити друзів.',
    supportMetastream: 'Підтримуйте Metastream, щоб розблокувати нагороди!',
    startSession: 'Почати сеанс',
    theaterMode: 'Режим театру',
    theaterModeDesc: "Приховати весь не відеоконтент на веб-сторінці. Зауважте, що це також може приховати м'які субтитри.",
    thirdPartyIntegrations: 'Інтеграція сторонніх постачальників',
    toggleDJ: 'Увімкнути або вимкнути DJ',
    uiDockToRight: 'Пристикувати до правого боку',
    uiUndock: 'Відкріпити у плавучу накладку',
    unlimited: 'Необмежений',
    unlockQueue: 'Розблокувати чергу',
    updateAvailable: 'Доступне оновлення Metastream. Натисніть кнопку UPDATE у верхньому правому куті, щоб отримати оновлення.',
    username: "Ім'я користувача",
    users: 'Користувачі',
    userJoined: '<Username id="{{userId}}">{{username}}</Username> приєднався.',
    userLeft: '<Username id="{{userId}}">{{username}}</Username> пішов.',
    userNameChanged: '<Username id="{{userId}}">{{prevName}}</Username> тепер відомий як <Username id="{{userId}}">{{name}}</Username>.',
    viewOnGitHub: 'Переглянути на GitHub',
    waitingForHost: "Очікування господаря на дозвіл з'єднання",
    welcome: 'Ласкаво просимо',
    welcomeToMetastream: 'Ласкаво просимо до Metastream',
    welcomeMessage1: 'Привіт, дякуємо за спробу Metastream!'
};
