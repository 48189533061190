const formatSeconds = (sec) => {
    sec = Math.round(sec);
    let hours = Math.floor(sec / 3600);
    let minutes = Math.floor((sec % 3600) / 60);
    let seconds = sec % 60;
    if (minutes < 10) {
        minutes = '0' + minutes;
    }
    if (seconds < 10) {
        seconds = '0' + seconds;
    }
    return hours > 0 ? `${hours}:${minutes}:${seconds}` : `${minutes}:${seconds}`;
};
export const formatMs = (ms) => {
    return formatSeconds(ms / 1000);
};
export const formatShortMs = (ms) => {
    const sec = Math.round(ms / 1000);
    let hours = Math.floor(sec / 3600);
    let minutes = Math.floor((sec % 3600) / 60);
    let seconds = sec % 60;
    if (hours > 0 && minutes < 10) {
        minutes = '0' + minutes;
    }
    if ((hours > 0 || minutes > 0) && seconds < 10) {
        seconds = '0' + seconds;
    }
    return hours > 0
        ? `${hours}:${minutes}:${seconds}`
        : minutes > 0
            ? `${minutes}:${seconds}`
            : `${seconds}`;
};
const canFormatTime = Boolean(Intl.DateTimeFormat && Intl.DateTimeFormat.prototype.formatToParts);
const time12Formatter = canFormatTime
    ? new Intl.DateTimeFormat(undefined, { hour: 'numeric', minute: 'numeric' })
    : null;
export const formatShortTimestamp = (timestamp) => {
    if (!canFormatTime)
        return null;
    const date = new Date(timestamp);
    const parts = time12Formatter.formatToParts(date);
    const result = parts
        .reduce((str, part) => {
        if (part.type === 'dayPeriod')
            return str;
        return str + part.value;
    }, '')
        .trim();
    return result;
};
