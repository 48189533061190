import React, { Component } from 'react';
import { PRODUCT_NAME, VERSION } from 'constants/app';
import styles from './Home.css';
import LayoutMain from 'components/layout/Main';
import { Icon } from 'components/Icon';
import { MenuButton } from 'components/menu/MenuButton';
import { MenuHeader } from './menu/MenuHeader';
import { ExternalLink } from './common/link';
import Tooltip from '@material-ui/core/Tooltip';
import { assetUrl } from 'utils/appUrl';
import { MenuTabs } from './menu/MenuTabs';
import { DISCORD_INVITE_URL, GITHUB_REPO_URL } from 'constants/social';
import { withNamespaces } from 'react-i18next';
import { localUserId } from '../network/index';
const SocialLink = (props) => (React.createElement(ExternalLink, { href: props.href, className: styles.socialLink },
    React.createElement(Tooltip, { title: props.title }, props.image ? (React.createElement("img", { src: props.image, className: styles.socialIcon, alt: props.title })) : (React.createElement(Icon, { name: props.icon, className: styles.socialIcon })))));
class Home extends Component {
    render() {
        const { t } = this.props;
        const DEV = process.env.NODE_ENV === 'development';
        const gitv = `${process.env.GIT_BRANCH}@${process.env.GIT_COMMIT}`;
        return (React.createElement(LayoutMain, { className: styles.container, showBackButton: false },
            React.createElement(MenuHeader, { className: styles.header, text: React.createElement(React.Fragment, null,
                    React.createElement("img", { src: assetUrl('icons/metastream-icon.svg'), className: styles.logo, width: "48", alt: "" }),
                    PRODUCT_NAME,
                    React.createElement("div", { className: styles.buildInfo },
                        React.createElement("h3", null,
                            "Beta ",
                            VERSION,
                            DEV && ` (${gitv})`),
                        DEV && React.createElement("h3", null, "Development build"))) }),
            React.createElement("section", { className: styles.nav },
                React.createElement("ul", null,
                    React.createElement("li", null,
                        React.createElement(MenuButton, { id: "startsession", to: `/join/${localUserId()}`, className: styles.btn, icon: "play" }, t('startSession'))),
                    React.createElement("li", null,
                        React.createElement(MenuButton, { id: "joinsession", to: "/join", className: styles.btn, icon: "globe" }, t('joinSession'))),
                    React.createElement("li", null,
                        React.createElement(MenuButton, { id: "settings", to: "/settings", className: styles.btn, icon: "settings" }, t('settings'))),
                    React.createElement("li", null,
                        React.createElement(MenuButton, { component: ExternalLink, className: styles.btn, href: "https://www.patreon.com/metastream", icon: "heart" }, t('donate'))),
                    this.props.installable && (React.createElement("li", null,
                        React.createElement(MenuButton, { icon: "download", onClick: this.props.install }, t('installToDesktop')))))),
            React.createElement("section", { className: styles.intro },
                React.createElement(MenuTabs, null)),
            React.createElement("footer", { className: styles.social },
                React.createElement("div", null,
                    React.createElement(SocialLink, { href: "https://www.patreon.com/metastream", image: "./assets/icons/social/patreon-wordmark.svg", title: "Become a patron" }),
                    React.createElement(SocialLink, { href: "https://twitter.com/GetMetastream", image: "./assets/icons/social/twitter-color.svg", title: "Twitter" }),
                    React.createElement(SocialLink, { href: DISCORD_INVITE_URL, image: "./assets/icons/social/discord-color.svg", title: "Join Discord community" }),
                    React.createElement(SocialLink, { href: GITHUB_REPO_URL, image: "./assets/icons/social/github-mark.svg", title: "GitHub" })),
                React.createElement("div", { className: styles.socialRight },
                    React.createElement("div", null,
                        React.createElement("span", { className: styles.credits },
                            "Created by",
                            ' ',
                            React.createElement(ExternalLink, { href: "http://samuelmaddock.com" }, "Sam\u00A0Maddock")),
                        React.createElement("span", { className: styles.contributors },
                            React.createElement("br", null),
                            "&",
                            ' ',
                            React.createElement(ExternalLink, { href: "https://github.com/samuelmaddock/metastream/graphs/contributors" }, "contributors"),
                            ' ',
                            "\u2665"))))));
    }
}
export default withNamespaces()(Home);
