import { stringify } from 'qs';
import { URL } from './consts';
import { getAppName, getClientId, getAppVersion, getLanguage, getUserAgent, getViewport, getScreenResolution, fetch } from './side-effects';
export const prepareUserAgent = (userAgent, appName) => userAgent
    .replace(new RegExp(`${appName}\\/\\d+\\.\\d+\\.\\d+ `), '')
    .replace(/Electron\/\d+\.\d+\.\d+ /, '');
export const getDefaultInitParams = () => {
    const appName = getAppName();
    return {
        protocolVersion: '1',
        clientId: getClientId(),
        appName,
        appVersion: getAppVersion(),
        language: getLanguage(),
        userAgent: prepareUserAgent(getUserAgent(), appName),
        viewport: getViewport,
        screenResolution: getScreenResolution
    };
};
export const resolveParam = (value) => typeof value === 'function' ? value() : value;
export const prepareItems = (items, trackId, time) => items.map(item => ({ ...item, tid: trackId, qt: time - item.__timestamp }));
export const getBatches = (items, batchSize) => items.reduce((batches, item) => batches[batches.length - 1].length >= batchSize
    ? [...batches, [item]]
    : [
        ...batches.slice(0, batches.length - 1),
        [...batches[batches.length - 1], item]
    ], [[]]);
export const sendBatches = async ([batch, ...others], failedItems = []) => {
    if (!batch || batch.length === 0)
        return failedItems;
    try {
        await fetch(URL, { method: 'post', body: batch.map(item => stringify(item)).join('\n') });
        return await sendBatches(others, failedItems);
    }
    catch (error) {
        return await sendBatches(others, [...failedItems, ...batch]);
    }
};
