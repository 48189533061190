import React from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';
import styles from './ExtensionInstall.css';
import { checkExtensionInstall } from 'actions/ui';
class _ExtensionInstall extends React.Component {
    constructor() {
        super(...arguments);
        this.onVisibilityChange = () => {
            // Check for extension installed if user switches browser tabs
            if (document.visibilityState === 'visible') {
                this.props.dispatch(checkExtensionInstall());
            }
        };
    }
    componentDidMount() {
        document.addEventListener('visibilitychange', this.onVisibilityChange, false);
    }
    componentWillUnmount() {
        document.removeEventListener('visibilitychange', this.onVisibilityChange, false);
    }
    render() {
        return (React.createElement("div", { className: cx(styles.container, this.props.className) },
            React.createElement("p", null, "A browser extension is required for playback."),
            React.createElement("div", { className: styles.badgeList },
                React.createElement("a", { href: "https://chrome.google.com/webstore/detail/fakegmdomhmegokfomgmkbopjibonfcp", target: "_blank" },
                    React.createElement("img", { src: "/images/badge-chrome-webstore.png", alt: "View extension in the Chrome Web Store" })),
                React.createElement("a", { href: "https://addons.mozilla.org/en-US/firefox/addon/metastream-remote/", target: "_blank" },
                    React.createElement("img", { src: "/images/badge-firefox-addon.png", alt: "View extension in the Firefox Addon store" })))));
    }
}
export const ExtensionInstall = connect()(_ExtensionInstall);
