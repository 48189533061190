import React, { Component } from 'react';
import styles from './WelcomePage.css';
import LayoutMain from 'components/layout/Main';
import { MenuButton } from 'components/menu/MenuButton';
import { t } from 'locale';
import { SwitchOption } from '../components/settings/controls';
import { connect } from 'react-redux';
import { TextInput } from '../components/common/input';
import { USERNAME_MAX_LEN } from 'constants/settings';
import { MenuHeader } from '../components/menu/MenuHeader';
import { replace } from 'connected-react-router';
import { setUsername, setSetting } from '../actions/settings';
import { LanguageSetting } from 'components/settings/Language';
class WelcomePage extends Component {
    // Design: https://venturebeat.com/wp-content/uploads/2015/03/Slack-test-start.png
    render() {
        const { dispatch } = this.props;
        const submit = () => {
            const { location } = this.props;
            localStorage.setItem('welcomed', 'true');
            const path = `${location.pathname}${location.search}`;
            dispatch(replace(path));
        };
        return (React.createElement(LayoutMain, { className: styles.container },
            React.createElement("form", { className: styles.column, onSubmit: e => {
                    e.preventDefault();
                    submit();
                } },
                React.createElement(MenuHeader, { text: t('welcomeToMetastream') }),
                React.createElement("div", { className: styles.formControl },
                    React.createElement("label", { htmlFor: "profile_username" }, t('chooseDisplayName')),
                    React.createElement(TextInput, { id: "profile_username", autoComplete: "username", spellCheck: false, defaultValue: this.props.settings.username, maxLength: USERNAME_MAX_LEN, onChange: e => {
                            const username = e.target.value;
                            if (username) {
                                dispatch(setUsername(username));
                            }
                        }, onBlur: e => {
                            if (this.props.settings.username) {
                                ;
                                e.target.value = this.props.settings.username;
                            }
                        }, autoFocus: true })),
                React.createElement("div", { className: styles.formControl },
                    React.createElement(LanguageSetting, null)),
                React.createElement(SwitchOption, { inputId: "advanced_tracking", title: t('allowTracking'), description: t('allowTrackingDesc'), checked: this.props.settings.allowTracking, onChange: checked => dispatch(setSetting('allowTracking', checked)), className: styles.formControl, divider: false }),
                React.createElement(MenuButton, { id: "getstarted", icon: "check-circle", onClick: submit }, t('getStarted')))));
    }
}
export default connect((state) => {
    const { location } = state.router;
    return {
        location: location,
        settings: state.settings
    };
})(WelcomePage);
