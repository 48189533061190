import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import styles from '../SettingsMenu.css';
import { TextInput } from 'components/common/input';
import { getLocalUsername, getLocalColor, getLocalAvatar } from 'reducers/settings';
import { USERNAME_MAX_LEN } from 'constants/settings';
import { setUsername, setColor } from 'actions/settings';
import { t } from 'locale';
import { UserAvatar } from '../../lobby/UserAvatar';
import { ExternalLink } from 'components/common/link';
import { Trans } from 'react-i18next';
import { server_updateUser } from '../../../lobby/actions/users';
import Tooltip from '@material-ui/core/Tooltip';
import { AccountService } from 'account/account';
import { usePatronTier } from 'account/hooks';
import { LogoutButton } from 'components/account/buttons';
import { useAppContext } from 'appContext';
const ProfileSettings = props => {
    const propsRef = useRef(props);
    const dirtyRef = useRef(false);
    const usernameInputRef = useRef(null);
    const tier = usePatronTier();
    const tierRef = useRef(tier);
    useEffect(function onTierChange() {
        if (tier !== tierRef.current) {
            // update color on exit
            dirtyRef.current = true;
            tierRef.current = tier;
        }
    }, [tier]);
    useEffect(function saveProps() {
        propsRef.current = props;
    }, [props]);
    useEffect(() => {
        return function componentWillUnmount() {
            const { current: props } = propsRef;
            if (dirtyRef.current) {
                props.dispatch(server_updateUser({
                    name: props.username,
                    color: props.color,
                    avatar: props.avatar
                }));
            }
        };
    }, []);
    // TODO: debounce
    const onChangeUsername = () => {
        const username = usernameInputRef.current && usernameInputRef.current.value;
        if (!username)
            return;
        if (username !== props.username) {
            props.dispatch(setUsername(username));
            dirtyRef.current = true;
        }
    };
    const { avatarRegistry } = useAppContext();
    const selectedAvatar = props.avatar ? avatarRegistry.getByURI(props.avatar) : null;
    const hasArtist = selectedAvatar ? !!selectedAvatar.artist : false;
    const hasPledged = tier > 0 /* None */;
    return (React.createElement("section", { className: styles.section },
        React.createElement("label", null, t('avatar')),
        React.createElement("div", { className: styles.avatarContainer },
            React.createElement("div", { className: styles.avatarList }, avatarRegistry.getAll().map((avatar, idx) => (React.createElement(UserAvatar, { key: idx, avatar: avatar.uri, selected: avatar.uri === props.avatar, onClick: () => {
                    props.setSetting('avatar', avatar.uri);
                    dirtyRef.current = true;
                    ga('event', {
                        ec: 'settings',
                        ea: 'select_avatar',
                        el: avatar.pii ? avatar.type : avatar.uri
                    });
                } })))),
            hasArtist && (React.createElement("div", { className: styles.small },
                React.createElement(Trans, { i18nKey: "avatarCredit", values: { artist: selectedAvatar.artist } },
                    React.createElement("span", { className: styles.blend }, "Selected avatar art by"),
                    selectedAvatar.href ? (React.createElement(ExternalLink, { href: selectedAvatar.href, className: "link-alt" }, "Unknown")) : (React.createElement("span", { className: styles.blend }, "Unknown")))))),
        React.createElement("label", { htmlFor: "profile_username" }, t('displayName')),
        React.createElement(TextInput, { id: "profile_username", theRef: e => (usernameInputRef.current = e), defaultValue: props.username, maxLength: USERNAME_MAX_LEN, onChange: onChangeUsername, onBlur: e => {
                if (usernameInputRef.current) {
                    usernameInputRef.current.value = props.username;
                }
            } }),
        React.createElement("label", { htmlFor: "profile_color" }, t('chatColor')),
        React.createElement(Tooltip, { title: t('patreonPledgeRequired'), placement: "right", disableHoverListener: hasPledged, disableFocusListener: hasPledged, disableTouchListener: hasPledged },
            React.createElement("input", { disabled: !hasPledged, id: "profile_color", type: "color", className: styles.colorSwatch, defaultValue: props.color, onChange: e => {
                    props.dispatch(setColor(e.target.value));
                    dirtyRef.current = true;
                } })),
        hasPledged && (React.createElement("div", null,
            React.createElement(LogoutButton, { onClick: () => AccountService.get().logout() })))));
};
export default connect((state) => {
    return {
        avatar: getLocalAvatar(state),
        username: getLocalUsername(state),
        color: getLocalColor(state)
    };
})(ProfileSettings);
