import React, { Component } from 'react';
import Home from 'components/Home';
import { connect } from 'react-redux';
import { SHOW_INSTALL_PROMPT } from '../middleware/pwa';
import { replace } from 'connected-react-router';
import { localUserId } from 'network';
import { setPendingMedia } from 'lobby/actions/mediaPlayer';
import { SEC2MS } from 'utils/math';
function mapStateToProps(state) {
    const { location } = state.router;
    return {
        pwaInstallReady: state.ui.pwaInstallReady,
        search: location ? location.search : ''
    };
}
const mapDispatchToProps = (dispatch) => ({
    showInstallPrompt() {
        dispatch({ type: SHOW_INSTALL_PROMPT });
    },
    setPendingMedia(media) {
        dispatch(setPendingMedia(media));
        dispatch(replace({ pathname: `/join/${localUserId()}`, search: '' }));
    }
});
class _HomePage extends Component {
    componentDidMount() {
        const params = new URLSearchParams(this.props.search);
        const url = params.get('url');
        if (url) {
            const time = parseInt(params.get('t') || '', 10) || undefined;
            const source = params.get('source') || undefined;
            this.props.setPendingMedia({ url, time: time ? time * SEC2MS : undefined, source });
        }
    }
    render() {
        return (React.createElement(Home, { installable: !!this.props.pwaInstallReady, install: this.props.showInstallPrompt }));
    }
}
export const HomePage = connect(mapStateToProps, mapDispatchToProps)(_HomePage);
