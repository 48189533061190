import React, { Component } from 'react';
import { connect } from 'react-redux';
import { SettingsMenu } from '../components/settings/SettingsMenu';
import LayoutMain from 'components/layout/Main';
import { MenuHeader } from 'components/menu/MenuHeader';
import { t } from 'locale';
import styles from './SettingsPage.css';
class _SettingsPage extends Component {
    constructor() {
        super(...arguments);
        this.state = {};
        /** Force rerender of all children */
        this.invalidate = () => {
            this.setState({ hide: true }, () => {
                this.setState({ hide: false });
            });
        };
    }
    render() {
        if (this.state.hide)
            return null;
        return (React.createElement(LayoutMain, { className: styles.container },
            React.createElement(MenuHeader, { text: t('settings') }),
            React.createElement(SettingsMenu, { invalidate: this.invalidate })));
    }
}
export const SettingsPage = connect()(_SettingsPage);
