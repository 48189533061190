import React from 'react';
import { Switch } from './Switch';
import styles from './options.css';
import cx from 'classnames';
export const SwitchOption = props => {
    return (React.createElement("div", { className: cx(props.className, styles.option, {
            [styles.divider]: typeof props.divider === 'undefined' ? true : false
        }) },
        React.createElement("div", { className: styles.title }, props.title),
        React.createElement(Switch, { id: props.inputId, className: styles.switchContainer, checked: props.checked, onChange: props.onChange }),
        props.description && React.createElement("div", { className: styles.description }, props.description)));
};
export const Dropdown = ({ children, className, theme = 'primary', ...props }) => {
    return (React.createElement("select", Object.assign({ className: cx(className, styles.dropdown, styles[`dropdown-${theme}`]) }, props), children));
};
