import React, { Component } from 'react';
import { Message } from './Message';
import styles from './Chat.css';
import { t } from 'locale';
export class Messages extends Component {
    constructor() {
        super(...arguments);
        this.list = null;
        this.wasAtBottom = true;
        this.state = { hasNewMessage: false };
        this.handleScroll = () => {
            this.wasAtBottom = this.isScrolledToBottom();
            if (this.state.hasNewMessage && this.isScrolledToBottom()) {
                this.setState({ hasNewMessage: false });
            }
        };
    }
    /** Height that the scrollbar can move. */
    get scrollBottom() {
        return this.list ? Math.trunc(this.list.scrollHeight - this.list.clientHeight) : 0;
    }
    componentDidMount() {
        if (this.list) {
            this.list.addEventListener('scroll', this.handleScroll);
        }
    }
    componentWillUnmount() {
        if (this.list) {
            this.list.removeEventListener('scroll', this.handleScroll);
        }
    }
    componentDidUpdate(prevProps) {
        if (this.props.messages !== prevProps.messages) {
            if (this.wasAtBottom) {
                this.scrollToBottom();
            }
            else {
                this.setState({ hasNewMessage: true });
            }
        }
    }
    scrollToBottom() {
        if (this.list) {
            this.list.scrollTop = this.scrollBottom;
            this.wasAtBottom = true;
        }
    }
    isScrolledToBottom() {
        const scrollTop = this.list ? this.list.scrollTop : 0;
        const pxFromBottom = Math.abs(scrollTop - this.scrollBottom);
        return pxFromBottom <= 10; /* px threshold */
    }
    render() {
        const messages = this.props.messages.map(message => (React.createElement(Message, { key: message.id, message: message, showTimestamp: this.props.showTimestamp })));
        return (React.createElement("div", { className: styles.chatWrapper },
            React.createElement("ul", { id: "chat_messages", ref: el => (this.list = el), className: styles.messages }, messages),
            this.state.hasNewMessage && (React.createElement("div", { className: styles.newMessages, onClick: this.scrollToBottom.bind(this) }, t('chatNewMessage')))));
    }
}
