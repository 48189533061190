import React, { Component } from 'react';
import cx from 'classnames';
import styles from './Modal.css';
import { IconButton } from 'components/common/button';
export class Modal extends Component {
    constructor() {
        super(...arguments);
        this.onKeyPress = (event) => {
            if (event.key === 'Escape') {
                this.props.onClose && this.props.onClose();
            }
        };
    }
    componentDidMount() {
        if (this.props.onClose) {
            document.addEventListener('keydown', this.onKeyPress, false);
        }
    }
    componentWillUnmount() {
        if (this.props.onClose) {
            document.removeEventListener('keydown', this.onKeyPress, false);
        }
    }
    render() {
        return (React.createElement("div", { className: cx(styles.container, this.props.className) },
            React.createElement(IconButton, { id: "modal_close", icon: "x", className: styles.close, onClick: this.props.onClose }),
            React.createElement("div", { className: cx(styles.content, {
                    [styles.fill]: this.props.fill
                }) }, this.props.children)));
    }
}
