import React from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';
import styles from './WebviewError.css';
class _WebviewError extends React.Component {
    render() {
        const { url, className } = this.props;
        // TODO: localize
        return (React.createElement("div", { className: cx(className, styles.container) },
            React.createElement("p", { className: styles.emoji }, "\uD83D\uDE1E"),
            React.createElement("p", null,
                React.createElement("strong", null, "There was a problem loading the site.")),
            React.createElement("p", { className: styles.fullUrl }, url),
            React.createElement("ol", { className: styles.list },
                React.createElement("li", null,
                    "Check that the Metastream Remote browser extension has",
                    ' ',
                    React.createElement("strong", null, "access to all sites"),
                    "."),
                React.createElement("li", null, "Test the site outside of Metastream."),
                React.createElement("li", null, "Reload the site."),
                FEATURE_POPUP_PLAYER && React.createElement("li", null, "Try opening the popup player."))));
    }
}
export const WebviewError = connect()(_WebviewError);
