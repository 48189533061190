import React, { Component } from 'react';
import { t } from 'locale';
import { SwitchOption } from '../controls';
import styles from '../SettingsMenu.css';
import optionsStyles from '../options.css';
import { ExternalLink } from 'components/common/link';
import { LanguageSetting } from '../Language';
export default class AppearanceSettings extends Component {
    render() {
        const { setSetting, settings } = this.props;
        // prettier-ignore
        return (React.createElement("section", { className: styles.section },
            React.createElement(LanguageSetting, { onChange: this.props.onChange }),
            React.createElement("p", { className: styles.small },
                React.createElement("span", { className: optionsStyles.description }, "Want to contribute?"),
                " ",
                React.createElement(ExternalLink, { className: "link-alt", href: `https://github.com/samuelmaddock/metastream/tree/master/packages/metastream-app/src/locale#localization` }, "Read localization instructions here.")),
            React.createElement(SwitchOption, { inputId: "autofullscreen", title: t('autoFullscreen'), description: t('autoFullscreenDesc'), checked: settings.autoFullscreen, onChange: checked => setSetting('autoFullscreen', checked) }),
            React.createElement(SwitchOption, { inputId: "theatermode", title: t('theaterMode'), description: t('theaterModeDesc'), checked: settings.theaterMode, onChange: checked => setSetting('theaterMode', checked) }),
            React.createElement(SwitchOption, { inputId: "dock_chat", title: t('uiDockToRight'), checked: settings.chatLocation === 1 /* DockRight */, onChange: checked => setSetting('chatLocation', location => location === 1 /* DockRight */ ? 0 /* FloatLeft */ : 1 /* DockRight */) }),
            React.createElement(SwitchOption, { inputId: "chat_timestamp", title: t('chatTimestamp'), description: t('chatTimestampDesc'), checked: settings.chatTimestamp, onChange: checked => setSetting('chatTimestamp', checked) })));
    }
}
