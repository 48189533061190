export function actionCreator(type) {
    const creator = (payload) => ({ type, payload });
    creator.displayName = type;
    creator.type = type;
    Object.defineProperties(creator, {
        displayName: {
            value: type
        },
        type: {
            value: type,
            writable: false
        }
    });
    return creator;
}
export function isType(action, actionCreator) {
    return action.type === actionCreator.type;
}
