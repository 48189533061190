export default {
    add: 'Hozzáadás',
    addMedia: 'Új média',
    admin: 'Adminisztráció',
    advanced: 'Haladó',
    allow: 'Engedélyezés',
    allowTracking: 'Felhasználási statisztika küldése',
    allowTrackingDesc: 'Névtelen statisztika küldése a fejlesztő számára, a felhasználói élmény későbbi fejlesztése érdekében.',
    avatar: 'Profilkép',
    avatarCredit: '<0>Profilkép készítője</0> <1>{{artist}}</1>',
    cancel: 'Mégsem',
    changelog: 'Változási napló',
    chatColor: 'Chat szín',
    chatPlaceholder: 'Üzenet URL',
    chatRevealHint: 'Nyomd meg a <1></1> gombot a chat megjelenítéséhez.',
    chooseDisplayName: 'Válassz felhasználónevet',
    connecting: 'Kapcsolódás',
    continue: 'Folytatás',
    copy: 'Másolás',
    copyLink: 'Hivatkozás másolása',
    debug: 'Hibakeresés',
    developerMode: 'Fejlesztői mód',
    developerModeDesc: 'Fejlesztői eszközök mutatása',
    directIP: 'Direct IP',
    disallow: 'Visszautasít',
    disconnect: 'Kapcsolat bontása',
    disconnected: 'Kapcsolat bontva',
    dj: 'DJ',
    donate: 'Adományozás',
    donators: 'Adományozók',
    duplicate: 'Duplikálás',
    endSessionTitle: 'Munkamenet leállítása?',
    endSessionDescription: 'Privát munkamenet engedélyezéskor, minden már kapcsolódott felhasználó munkamenete megszűnik',
    friendCode: 'Baráti kód',
    getStarted: 'Kezdés',
    info: 'Információ',
    invite: 'Meghívás',
    join: 'Csatlakozás',
    joinSession: 'Csatlakozás munkamenethez',
    kick: 'Kidobás',
    lockQueue: 'Sor lezárása',
    moveToTop: 'Mozgatás a tetejére',
    next: 'Következő',
    nextUp: 'Hátralévő',
    networkDisconnectHostDisconnect: 'A kiszolgáló lezárta a kapcsolatot.',
    networkDisconnectError: 'Hálózati időtúllépés.',
    networkDisconnectInvalidClientInfo: 'Érvénytelen kliens információ.',
    networkDisconnectVersionMismatch: `Nem egyező kliens verzió.`,
    networkDisconnectFull: 'A munkamenet telítve van.',
    networkDisconnectKicked: 'Kizártak a munkamenetből.',
    networkTroubleshootingHelp: 'Látogasd meg a <1>Hálózati helyreállítások dokumentációt</1> további segítésgért.',
    noticeAddedMedia: 'Hozzáadva <Media id="{{mediaId}}">{{mediaTitle}}</Media>',
    noticeMediaError: 'Hiba lépett fel a {{url}} lekérése közben',
    noticeNowPlaying: 'Most megy <Media id="{{mediaId}}">{{mediaTitle}}</Media>, <Username id="{{userId}}">{{username}}</Username> kérésére.',
    noticeUserRequestJoin: '<Username id="{{userId}}">{{username}}</Username> engedélyt kért a csatlakozásra.',
    offline: 'Offline',
    ok: 'OK',
    openInBrowser: 'Megnyitás böngészőben',
    private: 'Privát',
    profile: 'Profil',
    public: 'Nyilvános',
    reload: 'Újratöltés',
    remove: 'Törlés',
    repeat: 'Ismétlés',
    requestUrl: 'Hozzáadás a munkamenethez',
    requestUrlPlaceholder: '',
    requiresDJPermissions: 'A kérés DJ szerepkörhöz kötött',
    requiresPortForward: '<1>Port továbbítás</1> szükséges: <3>{{port}}</3>',
    sessionModePublicDescription: 'Bárki csatlakozhat.',
    sessionModePrivateDescription: 'Közvetlen engedély szükséges a csatlakozáshoz.',
    sessionModeOfflineDescription: 'Senki nem engedélyezte a csatlakozást.',
    settings: 'Beállítások',
    shareFriendCode: 'Baráti kód megsztása',
    shareFriendCodeOther: '{{name}} baráti kód megosztása',
    sharePublicIP: 'Oszd meg az IP címedet, <1>your public IP address</1>, hogy engedélyezd a közvetlen csatlakozást.',
    startSession: 'Munkamenet indítása',
    thirdPartyIntegrations: 'Harmadik fél integrációja',
    toggleDJ: 'DJ váltása',
    unlockQueue: 'Sor feloldása',
    username: 'Felhasználónév',
    users: 'Felhasználók',
    userJoined: '<Username id="{{userId}}">{{username}}</Username> csatlakozott.',
    userLeft: '<Username id="{{userId}}">{{username}}</Username> lecsatlakozott.',
    viewOnGitHub: 'Megtekintés GitHub-on',
    waitingForHost: 'Várakozás a kiszolgálóra',
    welcome: 'Üdvözlet'
};
