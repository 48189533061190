import React, { Component } from 'react';
import cx from 'classnames';
import styles from './PlaybackControls.css';
import { connect } from 'react-redux';
import { server_requestPlayPause, server_requestNextMedia, server_requestSeek, server_requestRepeatMedia } from 'lobby/actions/mediaPlayer';
import { hasPlaybackPermissions } from 'lobby/reducers/mediaPlayer.helpers';
import { parseCuePoints, copyMediaLink, openMediaInBrowser } from 'media/utils';
import { setVolume, setMute } from 'actions/settings';
// Must include Slider first for CSS precedence order
import { Slider } from 'components/media/Slider';
Slider.name; // don't strip unused import
import { VolumeSlider } from 'components/media/VolumeSlider';
import { Timeline } from 'components/media/Timeline';
import { MoreButton } from 'components/media/MoreButton';
import { IconButton } from 'components/common/button';
import { t } from 'locale';
import { setPopupPlayer } from 'actions/ui';
import { PopupWindow } from 'components/Popup';
const Button = props => {
    return (React.createElement(IconButton, { id: props.id, icon: props.icon, disabled: props.disabled, className: cx(props.className, styles.button, {
            [styles.buttonEnabled]: props.enabled
        }), title: props.title, onClick: props.onClick }, props.children));
};
const ButtonListItem = props => {
    return (React.createElement(IconButton, { icon: props.icon, className: styles.buttonListItem, title: props.title, onClick: props.onClick }, props.children));
};
const mapStateToProps = (state) => {
    return {
        ...state.mediaPlayer,
        mute: state.settings.mute,
        volume: state.settings.volume,
        dj: hasPlaybackPermissions(state)
    };
};
class _PlaybackControls extends Component {
    constructor() {
        super(...arguments);
        this.playPause = () => {
            this.props.dispatch(server_requestPlayPause());
        };
        this.next = () => {
            this.props.dispatch(server_requestNextMedia());
        };
        this.repeat = () => {
            this.props.dispatch(server_requestRepeatMedia());
        };
        this.seek = (time) => {
            this.props.dispatch(server_requestSeek(time));
        };
        this.setVolume = (volume) => {
            this.props.dispatch(setVolume(volume));
        };
        this.toggleMute = () => {
            const mute = !this.props.mute;
            this.props.dispatch(setMute(mute));
        };
        this.openLink = () => {
            const { current: media } = this.props;
            if (media) {
                openMediaInBrowser(media);
            }
        };
        this.copyLink = () => {
            const { current: media } = this.props;
            if (media) {
                copyMediaLink(media);
            }
        };
    }
    getCuePoints() {
        const { current: media } = this.props;
        if (media) {
            let cuePoints = parseCuePoints(media);
            return cuePoints;
        }
    }
    render() {
        const { current: media, playback, startTime, pauseTime, dj, repeatMode } = this.props;
        const playbackIcon = playback === 1 /* Playing */ ? 'pause' : 'play';
        const isIdle = playback === 0 /* Idle */;
        const isPaused = playback === 2 /* Paused */;
        const duration = (media && media.duration) || 0;
        const isTimed = duration > 0;
        const permTitle = t('requiresDJPermissions');
        const playPauseBtn = (React.createElement(Button, { key: "playpause", icon: playbackIcon, title: dj ? undefined : permTitle, disabled: isIdle, onClick: this.playPause }));
        const nextBtn = (React.createElement(Button, { key: "next", icon: "skip-forward", title: dj ? t('next') : permTitle, disabled: isIdle, onClick: this.next }));
        const repeatBtn = (React.createElement(Button, { icon: repeatMode === 2 /* One */ ? 'repeat-one' : 'repeat', title: dj ? t('repeat') : permTitle, enabled: repeatMode !== 0 /* Off */, onClick: this.repeat }));
        const timeline = isIdle || !isTimed ? (React.createElement("span", { className: styles.spacer })) : (React.createElement(Timeline, { className: styles.spacer, time: (isPaused ? pauseTime : startTime + this.props.serverClockSkew) || 0, paused: isPaused, duration: media && media.duration, playbackRate: this.props.playbackRate, onSeek: this.seek, cuePoints: this.getCuePoints() }));
        const volumeSlider = (React.createElement(VolumeSlider, { mute: this.props.mute, volume: this.props.volume, onChange: this.setVolume, onMute: this.toggleMute }));
        const infoBtn = media && media.description && (React.createElement(Button, { icon: "info", title: t('info'), onClick: () => this.props.showInfo() }));
        const settingsBtn = (React.createElement(Button, { id: "settings_btn", icon: "settings", title: t('settings'), onClick: () => this.props.openSettings() }));
        return (React.createElement("div", { className: cx(this.props.className, styles.container) },
            playPauseBtn,
            nextBtn,
            repeatBtn,
            timeline,
            volumeSlider,
            infoBtn,
            settingsBtn,
            this.renderMenu()));
    }
    renderMenu() {
        const { current: media } = this.props;
        const mediaButtons = media && (React.createElement(React.Fragment, null,
            React.createElement("hr", { className: styles.menuDivider }),
            FEATURE_POPUP_PLAYER ? (React.createElement(ButtonListItem, { icon: "external-link", onClick: () => {
                    PopupWindow.focus();
                    this.props.dispatch(setPopupPlayer(true));
                } }, t('openInPopup'))) : null,
            React.createElement(ButtonListItem, { icon: "external-link", onClick: this.openLink }, t('openInBrowser')),
            React.createElement(ButtonListItem, { icon: "clipboard", onClick: this.copyLink }, t('copyLink')),
            React.createElement(ButtonListItem, { icon: "rotate-cw", onClick: this.props.reload }, t('reload'))));
        return (React.createElement(MoreButton, { buttonClassName: styles.button },
            React.createElement(ButtonListItem, { icon: "message-circle", onClick: () => this.props.toggleChat() }, t('chat')),
            React.createElement(ButtonListItem, { icon: "mouse-pointer", onClick: () => this.props.showInteract() }, t('interact')),
            mediaButtons));
    }
}
export const PlaybackControls = connect(mapStateToProps)(_PlaybackControls);
