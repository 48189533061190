import { chat } from './chat';
import { users } from './users';
import { session } from './session';
import { mediaPlayer } from './mediaPlayer';
export const lobbyReducers = {
    chat,
    mediaPlayer,
    session,
    users
};
