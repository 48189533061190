export default {
    add: '追加する',
    addMedia: '動画を追加',
    addMediaCTA: '見たい動画をセッションに追加しましょう。',
    admin: '管理者',
    advanced: '上級設定',
    allow: '許可する',
    allowTracking: 'アプリの使用状況を送信する',
    allowTrackingDesc: 'アプリの使われ方を調べるために、匿名の使用情報が開発者に送信されます',
    always: '常時',
    appearance: '外観',
    autoFullscreen: '自動フルスクリーン',
    autoFullscreenDesc: '動画の画面サイズをウィンドウの大きさに合わせます',
    avatar: 'アバター画像',
    avatarCredit: '<0>選択中のアバター画像提供: </0> <1>{{artist}}</1>',
    cancel: 'キャンセル',
    changelog: '更新履歴',
    chat: 'チャット',
    chatColor: '表示名の色',
    chatMessageFailed: 'メッセージの送信に失敗しました。後で再度お試しください。',
    chatPlaceholder: 'メッセージ/URL',
    chatRevealHint: '<1></1> でチャット画面を開きます',
    chatNewMessage: '新しいメッセージが送信されました！',
    chooseDisplayName: 'ユーザー名を選んでください',
    chatTimestamp: 'チャットの発言時刻表示',
    chatTimestampDesc: 'チャットのメッセージの横に発言した時刻を表示します。',
    chatTyping1: '<0></0> が入力中……',
    chatTyping2: '<0></0>, <2></2> が入力中……',
    chatTyping3: '<0></0>, <2></2>, <4></4> が入力中……',
    chatTypingSeveral: '数人が入力中…',
    connecting: '接続中',
    continue: '続ける',
    copy: 'コピー',
    copyLink: 'リンクをコピー',
    debug: 'デバッグ',
    developerMode: '開発者モード',
    developerModeDesc: 'ウェブページの検証をする開発者コンソールを開きます。',
    directIP: 'IPによるダイレクト接続',
    disallow: '許可しない',
    disconnect: '切断する',
    disconnected: '切断しました',
    displayName: '表示名',
    dj: 'DJ',
    donate: '寄付する',
    donators: '寄付者',
    duplicate: '複製',
    endSessionTitle: '公開セッションを終了しますか？',
    endSessionDescription: '限定公開セッションに切り替えると、すべてのユーザーが切断されます。',
    endSessionModalDescription: 'すべてのユーザーがセッションから切断されます。',
    endSessionButton: 'セッションを終了する',
    enterJoinDest: 'MetastreamのセッションのURLを入力してください。',
    findSession: 'セッションを探す',
    findSessionDescription: 'Discordのコミュニティ「Metastream」の<1>#sessions</1>チャンネルで他の人のセッションを見つけることができます。 以下のロゴマークをクリックしてDiscordコミュニティ参加できます。',
    friendCode: 'セッションのURL',
    getStarted: '開始',
    help: 'ヘルプ',
    info: '情報',
    installToDesktop: 'デスクトップ版をインストール',
    interact: 'ウェブ操作',
    invite: '招待',
    join: '参加する',
    joinSession: 'セッションに参加',
    kick: 'キックする',
    language: '言語',
    lockQueue: '再生リストを編集不可にする',
    moveToTop: '一番上に移動',
    maxUsers: '最大ユーザー数',
    next: '次の動画',
    nextUp: '再生リスト',
    networkDisconnectHostDisconnect: 'ホスト側が接続を終了しました。',
    networkDisconnectError: 'タイムアウトしました。',
    networkDisconnectInvalidClientInfo: '本体情報が無効です。',
    networkDisconnectVersionMismatch: '本体のバージョンが合いません。',
    networkDisconnectFull: 'セッションが満員です。',
    networkDisconnectKicked: 'セッションからキックされました。',
    networkDisconnectMultiTab: 'あなたは複数のブラウザまたはタブでMetastreamのセッションをホストしています。 他のブラウザまたはタブを閉じてから、ページを再読み込みしてください。',
    networkDisconnectSessionNotFound: 'セッションが見つかりませんでした。',
    networkTroubleshootingHelp: '<1>トラブルシューティングガイド</1> を確認してください。',
    noticeAddedMedia: '<Media id="{{mediaId}}">{{mediaTitle}}</Media> を追加しました。',
    noticeMediaError: '{{url}} をリクエスト中にエラーが発生しました。',
    noticeNowPlaying: '再生中: <Media id="{{mediaId}}">{{mediaTitle}}</Media> (<Username id="{{userId}}">{{username}}</Username>のリクエスト)',
    noticeUserRequestJoin: '<Username id="{{userId}}">{{username}}</Username> が参加申請を送りました。',
    offline: 'オフライン',
    ok: 'OK',
    once: '一度',
    openInBrowser: 'ブラウザで開く',
    openInPopup: 'ポップアップで表示する',
    patreonLogin: 'Patronでログイン',
    patreonLogout: 'ログアウト',
    patreonPledge: 'Patronになる',
    patreonPledgeRequired: 'Patronメンバー専用です',
    patreonPledgeTierRequired: 'ティア{{tier}}のPatronメンバー専用です',
    private: '限定公開',
    profile: 'プロフィール',
    public: '公開',
    reload: '再読み込み',
    remove: '削除',
    repeat: 'リピート再生',
    retry: '再試行',
    requestUrl: 'URLを追加',
    requestUrlPlaceholder: '',
    required: '必須',
    requiresDJPermissions: 'DJ権限が必要です',
    requiresPortForward: 'ダイレクト接続を使用するには、<1>{{port}}</1>の<3>ポート開放</3>が必要です。',
    safeBrowse: 'セーフブラウジング',
    safeBrowseDesc: '一部以外のサイトを読み込む前に確認メッセージを表示します',
    safeBrowseLoadContent: '<1>{{host}}</1>のサイトを読み込みますか？',
    safeBrowseDisableForSession: 'このセッションでセーフブラウジングを無効化',
    session: 'セッション',
    sessionMode: 'セッションの種類',
    sessionModePublicDescription: '誰でも参加できます。',
    sessionModePrivateDescription: '参加の際に確認が必要です。',
    sessionModeOfflineDescription: '誰も参加できません。',
    settings: '設定',
    shareFriendCode: '他の人を招待するには、以下のフレンドコードを共有してください。',
    shareFriendCodeOther: '他の人を招待するには、以下の{{name}}のフレンドコードを共有してください。',
    sharePublicIP: 'ダイレクト接続をするには <1>グローバルアドレス</1> を共有してください。',
    shareSessionUrl: '他の人を招待するには、以下のURLを共有してください。',
    supportMetastream: 'Metastreamを支援して特典をゲットしよう！',
    startSession: 'セッションを開始',
    theaterMode: 'シアターモード',
    theaterModeDesc: 'ウェブ内の動画以外の項目を非表示にします。字幕が表示されなくなる可能性があります',
    thirdPartyIntegrations: 'サードパーティーの機能',
    toggleDJ: 'DJの切り替え',
    uiDockToRight: 'サイドバーを表示',
    unlimited: '無制限',
    unlockQueue: '再生リストを編集可にする',
    updateAvailable: '新しいMetastreamのアップデートがあります。右上のUPDATEボタンを押すとアップデートが始まります。',
    username: 'ユーザー名',
    users: 'ユーザー',
    userJoined: '<Username id="{{userId}}">{{username}}</Username> が参加しました。',
    userLeft: '<Username id="{{userId}}">{{username}}</Username> が退出しました。',
    userNameChanged: '<Username id="{{userId}}">{{prevName}}</Username> が <Username id="{{userId}}">{{name}}</Username> に名前を変えました。',
    viewOnGitHub: 'GitHubで見る',
    waitingForHost: 'ホストが接続を許可するのを待機しています……',
    welcome: 'ようこそ',
    welcomeToMetastream: 'Metastreamへようこそ',
    welcomeMessage1: 'Metastreamをお試しいただきありがとうございます！'
};
