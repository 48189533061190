import React, { Component } from 'react';
import styles from '../SettingsMenu.css';
import { SwitchOption } from '../controls';
import { t } from 'locale';
export default class AdvancedSettings extends Component {
    render() {
        return (React.createElement("section", { className: styles.section },
            React.createElement(SwitchOption, { inputId: "safebrowse", title: t('safeBrowse'), description: t('safeBrowseDesc'), checked: this.props.settings.safeBrowse, onChange: checked => this.props.setSetting('safeBrowse', checked) }),
            React.createElement(SwitchOption, { inputId: "advanced_tracking", title: t('allowTracking'), description: t('allowTrackingDesc'), checked: this.props.settings.allowTracking, onChange: checked => this.props.setSetting('allowTracking', checked) })));
    }
}
