export default {
    add: 'Ekle',
    addMedia: 'Medya Ekle',
    admin: 'Yönetici',
    advanced: 'Gelişmiş',
    allow: 'İzin Ver',
    allowTracking: 'Uygulama kullanım bilgilerini göndermeye izin ver.',
    allowTrackingDesc: 'Kullanım bilgileriniz uygulamanın nasıl kullanıldığına karşın daha iyi anlaşılabilmesi adına anonim bir şekilde geliştiriciye gönderilmektedir.',
    always: 'Her zaman',
    appearance: 'Görünüm',
    autoFullscreen: 'Otomatik tam ekran',
    autoFullscreenDesc: 'Oynatılan videoyu tam ekran olarak sığdırmak için yakınlaştırır.',
    avatar: 'Karakter',
    avatarCredit: '<1>{{artist}}</1> tarafından tasarlanan karakter seçildi.',
    cancel: 'İptal et',
    changelog: 'Değişiklikler',
    chat: 'Sohbet',
    chatColor: 'Sohbet rengi',
    chatMessageFailed: 'Mesajınız gönderilemedi, lütfen daha sonra tekrar deneyiniz.',
    chatPlaceholder: 'Mesajı veya linki buraya yaz',
    chatRevealHint: "Sohbete erişmek için <1></1>'a tıklayın.",
    chatNewMessage: 'Yeni bir mesaj gönderildi!',
    chatTyping1: '<0></0> yazıyor…',
    chatTyping2: '<0></0> ve <2></2> yazıyor…',
    chatTyping3: '<0></0>, <2></2>, ve <4></4> yazıyor…',
    chatTypingSeveral: 'Birden fazla kullanıcı yazıyor…',
    chooseDisplayName: 'Sohbette görünecek olan adınızı seçiniz.',
    connecting: 'Bağlanıyor',
    continue: 'Devam et',
    copy: 'Kopyala',
    copyLink: 'Linki kopyala',
    debug: 'Onarma',
    developerMode: 'Geliştirici Modu',
    developerModeDesc: 'Ağ sayfalarını görüntüleyebilmek adına geliştirici araçlarını gösterir.',
    directIP: 'Direkt IP',
    disallow: 'İzin verme',
    disconnect: 'Bağlantıyı kes',
    disconnected: 'Bağlantı kesildi',
    displayName: 'Görünen isim',
    dj: 'DJ',
    donate: 'Bağış yap',
    donators: 'Bağışçılar',
    duplicate: 'Kopyala',
    endSessionTitle: 'Oturumu kapatmak istediğinize emin misiniz?',
    endSessionDescription: 'Özel oturuma geçmek bütün kullanıcıların oturumdan atılmasına yol açacaktır.',
    endSessionModalDescription: 'Bütün kullanıcılar oturumdan atılacaktır.',
    endSessionButton: 'Oturumu kapat',
    enterJoinDest: 'Bir Metastream oturum linki giriniz.',
    findSession: 'Oturumu bul',
    findSessionDescription: 'Metastream Discord Topluluğunda olan <1>#sessions</1> kanalına katılarak diğer kullanıcıların paylaşmış olduğu oturumlara erişebilirsiniz. Katılmak için Discord logosuna tıklayınız.',
    friendCode: 'Davet kodu',
    getStarted: 'Hemen Başla',
    help: 'Yardım',
    info: 'Bilgi',
    installToDesktop: 'Masaüstüne indir',
    interact: 'Etkile',
    invite: 'Davet et',
    join: 'Katıl',
    joinSession: 'Oturuma katıl',
    kick: 'Oturumdan at',
    language: 'Dil',
    lockQueue: 'Listeyi kilitle',
    moveToTop: 'En üste taşı',
    maxUsers: 'En fazla kaç kullanıcı yer alsın?',
    next: 'Sonraki',
    nextUp: 'Sıradaki',
    networkDisconnectHostDisconnect: 'Sunucu bağlantıyı kapattı.',
    networkDisconnectError: 'Bağlantı hatası.',
    networkDisconnectInvalidClientInfo: 'İstemci kimliği hatalı.',
    networkDisconnectVersionMismatch: 'İstemci versiyonu uyumsuz. Oturuma katılmak adına uygulamayı son sürüme güncelleyiniz.',
    networkDisconnectFull: 'Oturumda yer yok.',
    networkDisconnectKicked: 'Oturumdan atıldınız.',
    networkDisconnectMultiTab: 'Birden fazla tarayıcı ya da sekmede Metastream oturumu yönetmektesiniz. Lütfen diğer sekmeleri ya da tarayıcıları kapatınız ve daha sonrasında bulunduğunuz sayfayı yenileyiniz.',
    networkDisconnectSessionNotFound: 'Oturum bulunamadı.',
    networkTroubleshootingHelp: 'Yardım için lütfen <1>bağlantı hatalarını giderme rehberine</1> bakınız.',
    noticeAddedMedia: '<Media id="{{mediaId}}">{{mediaTitle}}</Media> eklendi.',
    noticeMediaError: '{{url}} eklenirken bir hata oluştu.',
    noticeNowPlaying: 'Şu anda <Username id="{{userId}}">{{username}}</Username> tarafından eklenen <Media id="{{mediaId}}">{{mediaTitle}}</Media> oynatılıyor.',
    noticeUserRequestJoin: '<Username id="{{userId}}">{{username}}</Username> oturuma katılmak için izin istiyor.',
    offline: 'Çevrim dışı',
    ok: 'Tamam',
    once: 'Bir kez',
    openInBrowser: 'Tarayıcıda aç',
    private: 'Özel',
    profile: 'Profil',
    public: 'Açık',
    reload: 'Yenile',
    remove: 'Kaldır',
    repeat: 'Tekrarla',
    retry: 'Tekrar dene',
    requestUrl: 'Oturuma ekle',
    requestUrlPlaceholder: '',
    requiresDJPermissions: 'Bunu yapabilmek için DJ yetkisi gereklidir.',
    requiresPortForward: 'Doğrudan bağlantıların sağlanabilmesi için <3>{{port}}</3> nolu <1>port yönlendirmesi</1> yapılması gerekmektedir.',
    safeBrowse: 'Güvenli mod',
    safeBrowseDesc: 'Bilinmeyen internet sitelerinden yükleme yapmadan önce uyarı ver.',
    safeBrowseLoadContent: "<1>{{host}}</1>'da/de yer alan medya yüklensin mi?",
    safeBrowseDisableForSession: 'Bu oturum için güvenli modu devre dışı bırak.',
    session: 'Oturum',
    sessionMode: 'Oturum modu',
    sessionModePublicDescription: 'Herkes bu oturuma katılabilir.',
    sessionModePrivateDescription: 'Oturuma katılmak için yönetici onayı beklenilmektedir.',
    sessionModeOfflineDescription: 'Hiç kimse bu oturuma katılamaz.',
    settings: 'Ayarlar',
    shareFriendCode: 'Davet kodunuzu paylaşarak arkadaşlarınızı davet edebilirsiniz.',
    shareFriendCodeOther: 'Arkadaşlarınızı davet etmek için {{name}} kodunu kullanabilirsiniz.',
    sharePublicIP: '<1>IP adresinizi</1> paylaşarak arkadaşlarınıza doğrudan bağlantı izni verebilirsiniz.',
    shareSessionUrl: 'Aşağıdaki oturum linkinden arkadaşlarınızı oturuma davet edebilirsiniz.',
    supportMetastream: "Metastream'i destekleyerek daha fazla özelliğe sahip olabilirsiniz!",
    theaterMode: 'Sinema modu',
    theaterModeDesc: 'Ekranda video hariçi her şeyi engeller. Bu durum videoya gömülü olmayan altyazıların görünmemesine yol açabilir.',
    startSession: 'Oturumu Başlat',
    thirdPartyIntegrations: 'Üçüncü-Parti bütünleştirmeler',
    toggleDJ: 'DJ yetkisi ver',
    unlimited: 'Sınırsız',
    unlockQueue: 'Kilidi aç',
    username: 'Kullanıcı Adı',
    users: 'Kullanıcılar',
    userJoined: '<Username id="{{userId}}">{{username}}</Username> katıldı.',
    userLeft: '<Username id="{{userId}}">{{username}}</Username> ayrıldı.',
    userNameChanged: '<Username id="{{userId}}">{{prevName}}</Username> adını <Username id="{{userId}}">{{name}}</Username> olarak değiştirdi.',
    viewOnGitHub: "GitHub'da görüntüle",
    waitingForHost: 'Yönetici izni bekleniliyor',
    welcome: 'Hoş geldiniz',
    welcomeToMetastream: "Metastream'e hoş geldiniz"
};
