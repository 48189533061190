import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { merge } from 'lodash-es';
import { settings } from './settings';
import { ui } from './ui';
import { lobbyReducers } from '../lobby/reducers';
import { netApplyFullUpdate, netApplyUpdate } from 'network/middleware/sync';
import { mediaPlayerReplicatedState } from '../lobby/reducers/mediaPlayer';
import { usersReplicatedState } from '../lobby/reducers/users';
import { sessionReplicatedState } from 'lobby/reducers/session';
import { isType } from 'utils/redux';
import { reduceChange } from './deepDiff';
export const AppReplicatedState = {
    mediaPlayer: mediaPlayerReplicatedState,
    session: sessionReplicatedState,
    users: usersReplicatedState
};
export const createReducer = (history) => {
    const rootReducer = combineReducers({
        router: connectRouter(history),
        ...lobbyReducers,
        settings,
        ui
    });
    const reducer = (state, action) => {
        if (isType(action, netApplyFullUpdate)) {
            return merge({}, state, action.payload);
        }
        else if (isType(action, netApplyUpdate)) {
            const diffs = action.payload;
            let newState = state;
            for (let i = 0; i < diffs.length; i++) {
                newState = reduceChange(newState, diffs[i]);
            }
            return newState;
        }
        return rootReducer(state, action);
    };
    return reducer;
};
