import React, { Component } from 'react';
import styles from './UserItem.css';
import { Icon } from '../Icon';
import Tooltip from '@material-ui/core/Tooltip';
import { IconButton } from '../common/button';
import { connect } from 'react-redux';
import { isAdmin, isDJ } from '../../lobby/reducers/users.helpers';
import { UserAvatar } from './UserAvatar';
import { t } from 'locale';
export class UserItem extends Component {
    constructor() {
        super(...arguments);
        this.state = {};
    }
    render() {
        const { onApprovalResponse } = this.props;
        const roleIcon = this.props.admin
            ? { title: t('admin'), icon: 'check-circle' }
            : this.props.dj
                ? { title: t('dj'), icon: 'headphones' }
                : null;
        let actionBtns;
        if (this.props.requestApproval && onApprovalResponse) {
            actionBtns = (React.createElement(React.Fragment, null,
                React.createElement(IconButton, { icon: "check", className: styles.allowBtn, title: t('allow'), onClick: () => onApprovalResponse(true), "data-id": "allow" }),
                React.createElement(IconButton, { icon: "x", className: styles.disallowBtn, title: t('disallow'), onClick: () => onApprovalResponse(false), "data-id": "disallow" })));
        }
        else if (this.props.showMenu) {
            actionBtns = (React.createElement(IconButton, { icon: "more-vertical", className: styles.menuBtn, onClick: this.props.onClickMenu }));
        }
        return (React.createElement("figure", { className: styles.container, "data-user": this.props.user && this.props.user.id, "data-pending": this.props.requestApproval },
            React.createElement(UserAvatar, { className: styles.avatar, avatar: this.props.avatar, badge: this.props.avatarBadge }),
            React.createElement("figcaption", { className: styles.name }, this.props.name),
            roleIcon && (React.createElement(Tooltip, { title: roleIcon.title, placement: "right" },
                React.createElement(Icon, { name: roleIcon.icon, className: styles.role }))),
            actionBtns));
    }
}
export const ConnectedUserItem = connect((state, props) => {
    return {
        admin: isAdmin(state, props.user.id),
        dj: isDJ(state, props.user.id)
    };
})(UserItem);
