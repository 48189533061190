import { isType } from 'utils/redux';
import { setUpdateState, setLobbyModal, checkExtensionInstall, setPopupPlayer } from 'actions/ui';
import { getIsInstalled } from '../utils/extension';
import { BEFORE_INSTALL_PROMPT, APP_INSTALLED } from '../middleware/pwa';
const initialState = () => ({
    isExtensionInstalled: getIsInstalled()
});
export const ui = (state = initialState(), action) => {
    if (isType(action, setUpdateState)) {
        return { ...state, updateAvailable: action.payload };
    }
    else if (isType(action, setLobbyModal)) {
        return { ...state, lobbyModal: action.payload };
    }
    else if (isType(action, checkExtensionInstall)) {
        return { ...state, isExtensionInstalled: getIsInstalled() };
    }
    else if (isType(action, setPopupPlayer)) {
        return { ...state, popupPlayer: action.payload };
    }
    if (action.type === BEFORE_INSTALL_PROMPT) {
        return { ...state, pwaInstallReady: true };
    }
    else if (action.type === APP_INSTALLED) {
        return { ...state, pwaInstallReady: false };
    }
    return state;
};
export const isUpdateAvailable = (state) => {
    return !!state.ui.updateAvailable;
};
