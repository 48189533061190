import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import { assetUrl } from 'utils/appUrl';
import styles from './UserAvatar.css';
import { useAppContext } from 'appContext';
export const UserAvatar = ({ className, avatar, badge, selected, onClick }) => {
    const { avatarRegistry } = useAppContext();
    const [avatarSrc, setAvatarSrc] = useState();
    function requestAvatar(avatarUri) {
        let src;
        try {
            src = avatarUri && avatarRegistry.resolve(avatarUri);
        }
        catch { }
        if (!src)
            return;
        let img = new Image();
        img.onload = () => setAvatarSrc(src);
        img.src = src;
    }
    useEffect(() => {
        if (avatar) {
            requestAvatar(avatar);
        }
    }, [avatar]);
    const children = (React.createElement(React.Fragment, null,
        React.createElement("img", { className: styles.image, src: avatarSrc || assetUrl('avatars/default.svg'), alt: "" }),
        badge && React.createElement("img", { className: styles.badge, src: badge, alt: "" })));
    return React.createElement(onClick ? 'button' : 'div', {
        className: cx(className, styles.container, {
            [styles.selected]: selected
        }),
        onClick
    }, children);
};
