import shortid from 'shortid';
import { isIP } from 'utils/network';
import { getUserName } from 'lobby/reducers/users.helpers';
import { t } from 'locale';
import { hasPlaybackPermissions, getMediaById, getCurrentMedia } from 'lobby/reducers/mediaPlayer.helpers';
import { addChat } from './chat';
import { getMediaParser } from './media-common';
import { enqueueMedia, nextMedia } from './mediaPlayer';
import { rpc } from 'network/middleware/rpc';
export const sendMediaRequest = (opts) => {
    return async (dispatch, getState) => {
        let state = getState();
        if (state.mediaPlayer.queueLocked && !hasPlaybackPermissions(state)) {
            return null;
        }
        const { source, ...serverOpts } = opts;
        const requestPromise = dispatch(server_requestMedia(serverOpts));
        const requestCount = parseInt(localStorage.getItem("requestCount" /* RequestCount */) || '0', 10) || 0;
        localStorage.setItem("requestCount" /* RequestCount */, `${requestCount + 1}`);
        {
            ga('event', { ec: 'session', ea: 'request_media', el: source });
            let host;
            try {
                const urlObj = new URL(opts.url);
                host = urlObj.host;
                if (isIP(host)) {
                    host = 'ipaddress';
                }
            }
            catch { }
            // Track request domain host (e.g. www.youtube.com)
            if (host) {
                ga('event', { ec: 'session', ea: 'request_host', el: host });
            }
        }
        const mediaResponse = await requestPromise;
        if (!mediaResponse.ok) {
            let content;
            switch (mediaResponse.err) {
                default:
                    content = t('noticeMediaError', { url: opts.url });
            }
            dispatch(addChat({ content, html: true, timestamp: Date.now() }));
            return;
        }
        const { id: mediaId } = mediaResponse;
        state = getState();
        const media = getMediaById(state, mediaId);
        if (media && media !== getCurrentMedia(state)) {
            const content = t('noticeAddedMedia', { mediaId: media.id, mediaTitle: media.title });
            dispatch(addChat({ content, html: true, timestamp: Date.now() }));
        }
        else {
            console.error(`Received media request success, but couldn't find media for ${mediaId}`);
        }
    };
};
const requestMedia = (opts) => async (dispatch, getState, context) => {
    const { url } = opts;
    console.info('Media request', opts, context);
    const state = getState();
    if (state.mediaPlayer.queueLocked && !hasPlaybackPermissions(state, context.client))
        return { ok: false, err: 1 /* NotAllowed */ };
    if (opts.immediate && !hasPlaybackPermissions(state, context.client))
        return { ok: false, err: 1 /* NotAllowed */ };
    let res;
    try {
        const mediaParser = await getMediaParser();
        res = await mediaParser.resolveMediaUrl(url);
    }
    catch (e) {
        console.error(e);
    }
    if (!res) {
        console.log(`Failed to fetch media for ${url}`);
        return { ok: false, err: 0 /* Generic */ };
    }
    console.log('Media response', res);
    const userId = context.client.id.toString();
    const media = {
        id: shortid(),
        type: res.type,
        url: res.url,
        title: res.title || url,
        duration: res.duration,
        description: res.description,
        imageUrl: res.thumbnails && res.thumbnails[0 /* Default */],
        requestUrl: url,
        ownerId: userId,
        ownerName: getUserName(getState(), userId),
        hasMore: res.hasMore,
        startTime: opts.time && res.duration && opts.time < res.duration ? opts.time : undefined,
        favicon: res.favicon
    };
    if (res.state) {
        media.state = res.state;
    }
    if (opts.immediate) {
        const queued = dispatch(enqueueMedia(media, 0));
        if (queued)
            dispatch(nextMedia(true));
    }
    else {
        dispatch(enqueueMedia(media));
    }
    return { ok: true, id: media.id };
};
const server_requestMedia = rpc('requestMedia', "server" /* Server */, requestMedia);
