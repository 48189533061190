import { isType } from 'utils/redux';
import { setSessionData, setDisconnectReason, setAuthorized, setConnectionStatus } from '../actions/session';
import { resetLobby } from '../actions/common';
import { updateServerClockSkew } from '../actions/mediaPlayer';
import { DEFAULT_USERS_MAX, MAX_USERS_INFINITE } from 'constants/settings';
export const sessionReplicatedState = {
    id: true,
    media: true,
    playback: true,
    startTime: true,
    users: true,
    secret: true
};
const initialState = {
    id: '',
    users: 0,
    playback: 0 /* Idle */,
    startTime: new Date().getTime(),
    secret: '',
    serverClockSkew: 0,
    maxUsers: DEFAULT_USERS_MAX
};
export const session = (state = initialState, action) => {
    if (isType(action, setSessionData)) {
        return { ...state, ...action.payload };
    }
    // Client data
    if (isType(action, setDisconnectReason)) {
        return { ...state, disconnectReason: action.payload };
    }
    else if (isType(action, setAuthorized)) {
        return { ...state, authorized: action.payload, connectionStatus: "Connected" /* Connected */ };
    }
    else if (isType(action, setConnectionStatus)) {
        return { ...state, connectionStatus: action.payload };
    }
    else if (isType(action, updateServerClockSkew)) {
        return { ...state, serverClockSkew: action.payload };
    }
    if (isType(action, resetLobby)) {
        return initialState;
    }
    return state;
};
export const getMaxUsers = (state) => state.session.maxUsers === MAX_USERS_INFINITE ? Infinity : state.session.maxUsers;
export const getDisconnectReason = (state) => state.session.disconnectReason;
