import React, { PureComponent } from 'react';
import { Trans, NamespacesConsumer } from 'react-i18next';
import styles from './Chat.css';
import { CHAT_MAX_MESSAGE_LENGTH } from 'constants/chat';
import { Key } from './Key';
export class ChatForm extends PureComponent {
    constructor() {
        super(...arguments);
        this.state = {
            hasOpened: Boolean(localStorage.getItem('hasOpenedChat'))
        };
        this.input = null;
        this.submitText = (event) => {
            const target = event.currentTarget;
            switch (event.key) {
                case 'Enter': {
                    event.nativeEvent.stopImmediatePropagation();
                    event.preventDefault();
                    const text = target.value;
                    if (text.length > 0) {
                        this.props.send(text);
                        target.value = '';
                    }
                    if (this.props.blurOnSubmit) {
                        this.dismiss();
                    }
                    break;
                }
            }
        };
        this.onBlur = () => {
            if (this.props.onBlur && this.dismissed) {
                this.dismissed = undefined;
                this.props.onBlur();
            }
        };
        this.onInputChange = () => {
            const text = this.inputText;
            const spellcheck = !(text && text.startsWith('http'));
            if (this.state.spellcheck !== spellcheck) {
                this.setState({ spellcheck });
            }
        };
    }
    get inputText() {
        return this.input ? this.input.value : null;
    }
    render() {
        return (React.createElement("div", { className: styles.form },
            React.createElement(NamespacesConsumer, { ns: "translation" }, t => (React.createElement("input", { ref: e => {
                    this.input = e;
                }, type: "text", className: styles.messageInput, placeholder: t('chatPlaceholder'), spellCheck: this.state.spellcheck, onKeyPress: this.submitText, onInput: this.props.onTyping, maxLength: CHAT_MAX_MESSAGE_LENGTH, onChange: this.onInputChange, onFocus: this.props.onFocus, onBlur: this.onBlur }))),
            this.props.showHint && !this.state.hasOpened && (React.createElement("div", { className: styles.hint },
                React.createElement(Trans, { i18nKey: "chatRevealHint" },
                    "Press ",
                    React.createElement(Key, null),
                    " to reveal chat."))),
            this.props.children));
    }
    focus() {
        if (this.input) {
            this.input.focus();
        }
        if (!this.state.hasOpened) {
            localStorage.setItem('hasOpenedChat', '1');
            this.setState({ hasOpened: true });
        }
    }
    dismiss() {
        this.dismissed = true;
        if (this.input) {
            this.input.blur();
            // In case input wasn't focused, invoke blur callback manually
            if (this.dismissed) {
                this.onBlur();
            }
        }
    }
}
