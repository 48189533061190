import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setSetting } from 'actions/settings';
import { t } from 'locale';
import { IconButton } from 'components/common/button';
export const ChatLayoutButton = ({ className }) => {
    const dispatch = useDispatch();
    const chatLocation = useSelector(state => state.settings.chatLocation);
    const isFloating = chatLocation === 0 /* FloatLeft */;
    function toggleChatLayout() {
        dispatch(setSetting('chatLocation', location => location === 1 /* DockRight */ ? 0 /* FloatLeft */ : 1 /* DockRight */));
    }
    return (React.createElement(IconButton, { icon: isFloating ? 'dock-right' : 'undock-float', iconSize: "small", className: className, onClick: toggleChatLayout, title: t(isFloating ? 'uiDockToRight' : 'uiUndock') }));
};
