import React from 'react';
import { connect } from 'react-redux';
import styles from './Chat.css';
import { Trans } from 'react-i18next';
import { t } from '../../locale/index';
import { getTypingUsers } from '../../lobby/reducers/chat.helpers';
const User = ({ user }) => React.createElement("strong", null, user.name);
const UserTypingComponent = props => {
    const { typingUsers: users } = props;
    const num = users.length;
    let children;
    switch (num) {
        case 0:
            children = null;
            break;
        case 1:
            children = (React.createElement(Trans, { i18nKey: "chatTyping1" },
                React.createElement(User, { user: users[0] }),
                " is typing\u2026"));
            break;
        case 2:
            children = (React.createElement(Trans, { i18nKey: "chatTyping2" },
                React.createElement(User, { user: users[0] }),
                " and ",
                React.createElement(User, { user: users[1] }),
                " are typing\u2026"));
            break;
        case 3:
            children = (React.createElement(Trans, { i18nKey: "chatTyping3" },
                React.createElement(User, { user: users[0] }),
                " ",
                React.createElement(User, { user: users[1] }),
                " and ",
                React.createElement(User, { user: users[2] }),
                " are typing\u2026"));
            break;
        default:
            children = t('chatTypingSeveral');
            break;
    }
    return React.createElement("div", { className: styles.typing }, children);
};
export const UserTyping = connect((state, props) => ({
    typingUsers: getTypingUsers(state)
}))(UserTypingComponent);
