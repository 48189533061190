import React, { Component } from 'react';
import styles from './EndSession.css';
import { HighlightButton } from '../../common/button';
import { withNamespaces } from 'react-i18next';
import { t } from 'locale';
class EndSession extends Component {
    render() {
        return (React.createElement("div", { className: styles.container },
            React.createElement("h2", { className: styles.header }, t('endSessionTitle')),
            React.createElement("p", null, t('endSessionModalDescription')),
            React.createElement("div", null,
                React.createElement(HighlightButton, { icon: "check", size: "large", onClick: this.props.onConfirm }, t('endSessionButton')),
                React.createElement(HighlightButton, { icon: "x", size: "large", highlight: true, onClick: this.props.onCancel }, t('cancel')))));
    }
}
export default withNamespaces()(EndSession);
