export default {
    add: 'إضافة',
    addMedia: 'إضافة محتوى',
    admin: 'مسؤل',
    advanced: 'متقدم',
    allow: 'سماح',
    allowTracking: 'إرسال معلومات استخدام التطبيق',
    allowTrackingDesc: 'يتم إرسال معلومات الاستخدام مجهولة المصدر إلى المطور لفهم كيفية استخدام التطبيق بشكل أفضل',
    avatar: 'الصورة الرمزية',
    avatarCredit: '<0>الصورة المختارة مصممة من قبل</0> <1>{{artist}}</1>',
    cancel: 'إالغاء',
    changelog: 'سجل التغيرات',
    chat: 'الدردشة',
    chatColor: 'لون الدردشة',
    chatPlaceholder: 'رسالة او URL',
    chatRevealHint: 'اضغط <1></1> لإظهار الدردشة.',
    chatNewMessage: 'رسالة جديدة تم نشرها!',
    chooseDisplayName: 'إختر إسمك',
    connecting: 'جارً التوصيل',
    continue: 'متابعة',
    copy: 'نسخ',
    copyLink: 'نسخ الرابط',
    debug: 'التصحيح',
    developerMode: 'وضع المطور',
    developerModeDesc: 'إظهار أدوات المطور لفحص صفحات الويب',
    directIP: 'مباشر IP',
    disallow: 'عدم سماح',
    disconnect: 'قطع الاتصال',
    disconnected: 'انقطع الاتصال',
    dj: 'DJ',
    donate: 'تبرع للمطور',
    donators: 'المتبرعين',
    duplicate: 'تكرار',
    endSessionTitle: 'إنهاء البث العام ',
    endSessionDescription: 'التغيير إلى بث خاص سوف يقطع الارسال لكل المستخدمين ',
    enterJoinDest: 'أدخل URL البث',
    findSession: 'إيجاد بث',
    findSessionDescription: 'للإنضمام لبث عام يرجى الإنضمام لنا على Discord <1>#sessions</1>',
    friendCode: 'كود الصديق',
    getStarted: 'البدء',
    info: 'معلومات',
    installToDesktop: 'تنزيل ل سطح المكتب ',
    interact: 'تحكم بالصفحة',
    invite: 'دعوة',
    join: 'إنضمام',
    joinSession: 'إنضمام لبث',
    kick: 'طرد',
    language: 'اللغة',
    lockQueue: 'قفل قائمة الإنتظار',
    moveToTop: 'نقل ل اعلى',
    maxUsers: 'أقصى عدد للمستخدمين',
    next: 'تالي',
    nextUp: 'االتالي',
    networkDisconnectHostDisconnect: 'المضيف أغلق الإتصال',
    networkDisconnectError: 'إنتهت مهلة الإتصال',
    networkDisconnectInvalidClientInfo: 'معلومات العميل غير صالحة',
    networkDisconnectVersionMismatch: `إصدار العميل غير متطابق`,
    networkDisconnectFull: 'البث ممتلئ',
    networkDisconnectKicked: 'طردت من البث',
    networkTroubleshootingHelp: 'إبحث عن <1> مستكشف اخطاء الشبكة و مصلحها</1> للمساعدة',
    noticeAddedMedia: 'تم إضافة <Media id="{{mediaId}}">{{mediaTitle}}</Media>',
    noticeMediaError: 'حدث خطأ أثناء الطلب {{url}}',
    noticeNowPlaying: 'الآن يتم عرض  <Media id="{{mediaId}}">{{mediaTitle}}</Media> طلبت من <Username id="{{userId}}">{{username}}</Username>.',
    noticeUserRequestJoin: '<Username id="{{userId}}">{{username}}</Username> يتطلب موافقة للإنضمام',
    offline: 'غير متصل على الانترنت',
    ok: 'OK',
    openInBrowser: 'فتح في المتصفح ',
    private: 'خاص',
    profile: 'الملف شخصي',
    public: 'عام',
    reload: 'إعادة التحميل',
    remove: 'إزالة',
    repeat: 'تكرار',
    requestUrl: 'إضافة للبث',
    requestUrlPlaceholder: '',
    requiresDJPermissions: 'يتطلب صلاحيات ال DJ',
    requiresPortForward: 'يتطلب <1> إعداد ميناء شحن </1> من <3>{{port}}</3> لقبول الإتصال المباشر',
    sessionMode: 'وضع البث',
    sessionModePublicDescription: 'يسمح لأي شخص بالإنضمام',
    sessionModePrivateDescription: 'يتطلب موافقة لللإنضمام.',
    sessionModeOfflineDescription: 'لا يسمح لأي شخص بالإنضمام',
    settings: 'الإعدادات',
    shareFriendCode: ' شارك أصدقائك الكود الذي في الأسفل لدعوتهم',
    shareFriendCodeOther: 'شارك {{name}}’s أصدقائك الكود الذي في الأسفل لدعوتهم',
    sharePublicIP: 'شارك <1>عنوان الIP الخاص بك</1> للسماح لأصدقائك بالإنضمام مباشرة',
    shareSessionUrl: 'شارك URL البث الذي في الأسفل لدعوة الأصدقاء.',
    startSession: 'بدء البث',
    thirdPartyIntegrations: 'تكامل الجهات الخارجية',
    toggleDJ: 'منح DJ',
    unlimited: 'غير محدود',
    unlockQueue: 'فتح قائمة الإنتظار',
    username: 'إسم المستخدم ',
    users: 'المستخدمين',
    userJoined: '<Username id="{{userId}}">{{username}}</Username> إنضم',
    userLeft: '<Username id="{{userId}}">{{username}}</Username> غادر',
    viewOnGitHub: 'عرض على GitHub',
    waitingForHost: 'إنتظر حتى يسمح المضيف بالإتصال',
    welcome: 'مرحبا'
};
