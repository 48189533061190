import { actionCreator, isType } from 'utils/redux';
import { localUser } from 'network';
import { NetActions } from 'network/actions';
import { multi_userLeft } from '../actions/users';
import { initialize } from 'lobby/actions/user-init';
import { getLocalUsername, getLocalColor, getLocalAvatar } from '../../reducers/settings';
import { initLobby } from '../actions/common';
export const addUser = actionCreator('ADD_USER');
export const removeUser = actionCreator('REMOVE_USER');
export const clearUsers = actionCreator('CLEAR_USERS');
export const usersMiddleware = () => {
    return store => {
        const { dispatch, getState } = store;
        let server, host;
        const onDisconnect = (conn) => {
            const id = conn.id.toString();
            if (conn.isAuthed()) {
                dispatch(multi_userLeft(id));
            }
            dispatch(removeUser(id));
        };
        const initHost = async () => {
            const state = getState();
            // Add local user as initial user
            dispatch(addUser({
                conn: localUser(),
                host: true,
                name: getLocalUsername(state),
                avatar: getLocalAvatar(state),
                color: getLocalColor(state)
            }));
        };
        const init = async (options) => {
            server = options.server || null;
            host = options.host;
            if (host) {
                if (server) {
                    server.on('disconnect', onDisconnect);
                }
            }
            else {
                dispatch(initialize(server));
            }
        };
        const destroy = () => {
            if (server) {
                server.removeListener('disconnect', onDisconnect);
            }
            server = null;
            host = false;
        };
        return next => action => {
            if (isType(action, initLobby) && action.payload.host) {
                initHost();
                return next(action);
            }
            else if (isType(action, NetActions.connect)) {
                init(action.payload);
                return next(action);
            }
            else if (isType(action, NetActions.disconnect)) {
                destroy();
                return next(action);
            }
            return next(action);
        };
    };
};
