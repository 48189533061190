import { isType } from 'utils/redux';
import { isEqual } from 'lodash-es';
import { initHostSession, setSessionData } from '../actions/session';
import { getCurrentMedia } from '../reducers/mediaPlayer.helpers';
import { getNumUsers } from '../reducers/users.helpers';
import { initLobby, resetLobby } from '../actions/common';
export const sessionMiddleware = (observers = []) => {
    return ({ dispatch, getState }) => {
        let inSession = false;
        let isSessionHost = false;
        const init = (host) => {
            inSession = true;
            isSessionHost = host;
            if (host) {
                dispatch(initHostSession());
            }
        };
        const destroy = () => {
            inSession = false;
            notifyObservers();
        };
        const notifyObservers = () => {
            const session = inSession ? getState().session : null;
            observers.forEach(observer => observer.onChange(session));
        };
        const shouldUpdateSession = (state, prevState) => {
            if (!isSessionHost)
                return false;
            let sessionData;
            const prevMedia = getCurrentMedia(prevState);
            const media = getCurrentMedia(state);
            const { playback, startTime } = state.mediaPlayer;
            // Update session media state
            if (media !== prevMedia ||
                startTime !== prevState.mediaPlayer.startTime ||
                playback !== prevState.mediaPlayer.playback) {
                sessionData = {
                    ...(sessionData || {}),
                    media: media && {
                        url: media.requestUrl,
                        title: media.title,
                        thumbnail: media.imageUrl,
                        duration: media.duration
                    },
                    playback,
                    startTime
                };
            }
            // Update user info
            if (state.users !== prevState.users) {
                sessionData = {
                    ...(sessionData || {}),
                    users: getNumUsers(state)
                };
            }
            if (sessionData) {
                dispatch(setSessionData(sessionData));
                return true;
            }
            return false;
        };
        const compareState = (state, prevState) => {
            if (state.settings !== prevState.settings) {
                applySettings(state.settings, prevState.settings);
            }
            const updated = shouldUpdateSession(state, prevState);
            if (!updated && !isEqual(state.session, prevState.session)) {
                notifyObservers();
            }
        };
        /** Apply setting if loaded initial values. */
        let didRehydrate = false;
        const applySettings = (state, prevState) => {
            observers.forEach(observer => {
                const { setting, applySetting } = observer;
                if (!setting || !applySetting)
                    return;
                const value = state[setting];
                const prevValue = prevState[setting];
                if (value !== prevValue || didRehydrate) {
                    applySetting(value);
                }
            });
            // Notify with initial empty state
            if (didRehydrate) {
                notifyObservers();
            }
        };
        return next => action => {
            if (isType(action, initLobby)) {
                init(action.payload.host);
            }
            else if (isType(action, resetLobby)) {
                destroy();
                return next(action);
            }
            const prevState = getState();
            const result = next(action);
            const state = getState();
            didRehydrate = action.type === 'persist/REHYDRATE';
            compareState(state, prevState);
            return result;
        };
    };
};
