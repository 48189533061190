import { actionCreator } from 'utils/redux';
export const setVolume = actionCreator('SET_VOLUME');
export const addVolume = actionCreator('ADD_VOLUME');
export const setMute = actionCreator('SET_MUTE');
const SET_SETTING = 'SET_SETTING';
export const setSetting = (key, value) => {
    return { type: SET_SETTING, payload: { key, value } };
};
setSetting.type = SET_SETTING;
export const setUsername = actionCreator('SET_USERNAME');
export const setColor = actionCreator('SET_COLOR');
