import React, { Component } from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';
import { getCurrentMedia, getMediaQueue, hasPlaybackPermissions } from '../../lobby/reducers/mediaPlayer.helpers';
import { server_requestDeleteMedia, server_requestMoveToTop, server_requestToggleQueueLock } from '../../lobby/actions/mediaPlayer';
import { IconButton } from '../common/button';
import { ListOverlay } from './ListOverlay';
import MenuItem from '@material-ui/core/MenuItem';
import { MediaItem } from '../media/MediaItem';
import { localUser } from 'network';
import { copyMediaLink, openMediaInBrowser } from '../../media/utils';
import { withNamespaces } from 'react-i18next';
import { sendMediaRequest } from 'lobby/actions/media-request';
import { setSetting } from 'actions/settings';
class _MediaList extends Component {
    constructor() {
        super(...arguments);
        this.listOverlay = null;
    }
    get mediaList() {
        const { currentMedia, mediaQueue } = this.props;
        return currentMedia && currentMedia.hasMore ? [currentMedia, ...mediaQueue] : mediaQueue;
    }
    render() {
        const { t } = this.props;
        return (React.createElement(ListOverlay, { ref: (e) => (this.listOverlay = e), id: "mediaqueue", className: cx(this.props.className, { collapsed: this.props.collapsed }), title: t('nextUp'), tagline: this.props.mediaQueue.length ? `${this.props.mediaQueue.length}` : undefined, action: React.createElement(React.Fragment, null,
                this.renderQueueLock(),
                this.renderAddMedia()), onTitleClick: this.props.collapsible ? this.props.toggleCollapsed : undefined, renderMenuOptions: (media, close) => {
                let items = [
                    {
                        label: t('openInBrowser'),
                        onClick: () => openMediaInBrowser(media)
                    },
                    {
                        label: t('copyLink'),
                        onClick: () => copyMediaLink(media)
                    }
                ];
                if (media.description) {
                    items = [
                        ...items,
                        {
                            label: t('info'),
                            onClick: () => this.props.onShowInfo(media)
                        }
                    ];
                }
                if (this.props.hasPlaybackPermissions) {
                    items = [
                        ...items,
                        {
                            label: t('moveToTop'),
                            onClick: () => this.props.moveToTop(media.id)
                        },
                        {
                            label: t('duplicate'),
                            onClick: () => this.props.sendMediaRequest(media.requestUrl)
                        },
                        {
                            label: t('remove'),
                            onClick: () => this.props.deleteMedia(media.id)
                        }
                    ];
                }
                return items.map((item, idx) => (React.createElement(MenuItem, { key: idx, onClick: () => {
                        item.onClick();
                        close();
                    }, dense: true }, item.label)));
            } }, this.props.collapsible && this.props.collapsed
            ? null
            : this.mediaList.map(media => {
                return (React.createElement(MediaItem, { key: media.id, media: media, onClickMenu: e => {
                        this.listOverlay.onSelect(e, media);
                    } }));
            })));
    }
    renderQueueLock() {
        const { t, hasPlaybackPermissions, mediaQueueLocked: locked } = this.props;
        if (!hasPlaybackPermissions && !locked)
            return;
        const title = hasPlaybackPermissions ? t(locked ? 'unlockQueue' : 'lockQueue') : undefined;
        return (React.createElement(IconButton, { icon: locked ? 'lock' : 'unlock', iconSize: "small", title: title, disabled: !hasPlaybackPermissions, onClick: this.props.toggleQueueLock }));
    }
    renderAddMedia() {
        const { t, mediaQueueLocked, hasPlaybackPermissions } = this.props;
        if (!hasPlaybackPermissions && mediaQueueLocked)
            return;
        return (React.createElement(IconButton, { icon: "plus", iconSize: "small", title: t('addMedia'), onClick: this.props.onOpenMediaBrowser }));
    }
}
export const MediaList = withNamespaces()(connect((state) => ({
    hasPlaybackPermissions: hasPlaybackPermissions(state, localUser()),
    currentMedia: getCurrentMedia(state),
    mediaQueue: getMediaQueue(state),
    mediaQueueLocked: state.mediaPlayer.queueLocked,
    collapsed: !!state.settings.mediaListCollapsed
}), (dispatch) => ({
    moveToTop(mediaId) {
        dispatch(server_requestMoveToTop(mediaId));
    },
    sendMediaRequest(url) {
        dispatch(sendMediaRequest({
            url,
            source: 'media-context-menu-duplicate'
        }));
    },
    deleteMedia(mediaId) {
        dispatch(server_requestDeleteMedia(mediaId));
    },
    toggleQueueLock() {
        dispatch(server_requestToggleQueueLock());
    },
    toggleCollapsed() {
        dispatch(setSetting('mediaListCollapsed', collapsed => !collapsed));
    }
}))(_MediaList));
