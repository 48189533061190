import { actionCreator } from 'utils/redux';
import { getUserName, getNumUsers } from 'lobby/reducers/users.helpers';
import { rpc } from 'network/middleware/rpc';
import { CHAT_MAX_MESSAGE_LENGTH, CHAT_MAX_USERS_TYPING } from 'constants/chat';
import { t } from 'locale';
import { isUrl } from 'utils/url';
import { localUserId } from '../../network/index';
import { TYPING_DURATION } from '../reducers/chat.helpers';
import { sendMediaRequest } from './media-request';
export const addChat = actionCreator('ADD_CHAT');
export const recordTyping = actionCreator('RECORD_TYPING');
export const clearTyping = actionCreator('CLEAR_TYPING');
const userTypingTimeouts = {};
const broadcastChat = (text, userId) => (dispatch, getState) => {
    dispatch(addChat({
        author: userId
            ? {
                id: userId,
                username: getUserName(getState(), userId)
            }
            : undefined,
        content: text,
        timestamp: Date.now()
    }));
    // Clear user typing immediately if we received a message from them
    const typingTimeout = userId && userTypingTimeouts[userId];
    if (typingTimeout) {
        clearTimeout(typingTimeout);
        userTypingTimeouts[userId] = undefined;
        dispatch(clearTyping(userId));
    }
};
export const multi_broadcastChat = rpc('broadcastChat', "multicast" /* Multicast */, broadcastChat);
const rpcAddChat = (text) => (dispatch, getState, context) => {
    text = text.trim();
    if (text.length === 0)
        return false;
    if (text.length > CHAT_MAX_MESSAGE_LENGTH) {
        text = text.substr(0, CHAT_MAX_MESSAGE_LENGTH);
    }
    const userId = context.client.id.toString();
    dispatch(multi_broadcastChat(text, userId));
    return true;
};
const server_addChat = rpc('rpcAddChat', "server" /* Server */, rpcAddChat);
export const sendChat = (text) => {
    return async (dispatch, getState) => {
        if (isUrl(text)) {
            dispatch(sendMediaRequest({ url: text, source: 'chat' }));
            return;
        }
        try {
            await dispatch(server_addChat(text));
        }
        catch {
            const content = t('chatMessageFailed');
            dispatch(addChat({ content, timestamp: Date.now() }));
        }
    };
};
const broadcastTyping = (userId) => dispatch => {
    if (userId === localUserId())
        return;
    dispatch(recordTyping(userId));
    let timeout = userTypingTimeouts[userId];
    if (timeout)
        clearTimeout(timeout);
    userTypingTimeouts[userId] = setTimeout(() => {
        dispatch(clearTyping(userId));
    }, TYPING_DURATION);
};
const multi_broadcastTyping = rpc('broadcastTyping', "multicast" /* Multicast */, broadcastTyping);
const rpcNotifyTyping = () => (dispatch, getState, context) => {
    const userId = context.client.id.toString();
    dispatch(multi_broadcastTyping(userId));
};
const server_notifyTyping = rpc('rpcNotifyTyping', "server" /* Server */, rpcNotifyTyping);
export const notifyTyping = () => {
    return async (dispatch, getState) => {
        const state = getState();
        const numUsers = getNumUsers(state);
        if (numUsers > CHAT_MAX_USERS_TYPING)
            return;
        dispatch(server_notifyTyping());
    };
};
