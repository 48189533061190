export default {
    add: '添加',
    addMedia: '添加影片',
    addMediaCTA: '請先添加影片到這個派對',
    admin: '管理員',
    advanced: '高級',
    allow: '允許',
    allowTracking: '發送使用信息',
    allowTrackingDesc: '您的使用信息將會匿名發送給開發者以便更好的瞭解使用情況。',
    always: '總是',
    appearance: '外觀',
    autoFullscreen: '自動全屏幕',
    autoFullscreenDesc: '放大播放中的影片。',
    avatar: '頭像',
    avatarCredit: '<0>選中的頭像由</0> <1>{{artist}}</1> 設計',
    cancel: '取消',
    changelog: '更新日誌',
    chat: '對話',
    chatColor: '對話顏色',
    chatMessageFailed: '無法傳送訊息。請重試。',
    chatPlaceholder: '消息或網址',
    chatRevealHint: '按 <1></1> 來顯示聊天.',
    chatNewMessage: '新消息已經發送!',
    chatTimestamp: '對應時間',
    chatTimestampDesc: '在對話旁顯示影片對應時間。',
    chatTyping1: '<0></0> 正在輸入…',
    chatTyping2: '<0></0> 和 <2></2> 正在輸入…',
    chatTyping3: '<0></0>, <2></2>, 和 <4></4> 正在輸入…',
    chatTypingSeveral: '幾個人正在輸入…',
    chooseDisplayName: '填入您的暱稱',
    connecting: '正在連接',
    continue: '繼續',
    copy: '複製',
    copyLink: '複製鏈結',
    debug: '除錯',
    developerMode: '開發者模式',
    developerModeDesc: '顯示開發者工具來檢查頁面',
    directIP: 'IP地址',
    disallow: '不允許',
    disconnect: '斷開連接',
    disconnected: '已經斷開連接',
    displayName: '暱稱',
    dj: 'DJ',
    donate: '贊助',
    donators: '贊助過的人',
    duplicate: '重復',
    embedBlocked: '要開始 <1>{{host}}</1> 的播放, Metastream 需要在彈出視窗開啟網頁。',
    endSessionTitle: '結束派對嗎?',
    endSessionDescription: '變為離線派對時會切斷所有目前用戶的連接。',
    endSessionModalDescription: '所有用戶將與此會話切斷連接.',
    endSessionButton: '結束',
    enterJoinDest: '輸入一個 Metastream 派對鏈接.',
    findSession: '查找派對',
    findSessionDescription: '加入在 Metastream Discord 上的 <1>#sessions</1> 頻道來尋找其他用戶的派對. 點擊 Discord 的 LOGO 加入.',
    focusPopup: '專注模式',
    friendCode: '夥伴代碼',
    getStarted: '開始',
    help: '幫助',
    info: '資訊',
    installToDesktop: '安裝到桌面',
    interact: '互動',
    invite: '邀請',
    join: '加入',
    joinSession: '加入派對',
    kick: '踢走',
    language: '語言',
    lockQueue: '鎖住隊列',
    moveToTop: '移至頂部',
    maxUsers: '最大用戶數',
    next: '下一個影片',
    nextUp: '接下來',
    networkDisconnectHostDisconnect: '連接已經關閉.',
    networkDisconnectError: '網絡錯誤.',
    networkDisconnectInvalidClientInfo: '錯誤的連接信息.',
    networkDisconnectVersionMismatch: '未知的客戶端版本.',
    networkDisconnectFull: '會話已滿.',
    networkDisconnectKicked: '已經從會話中踢掉.',
    networkDisconnectMultiTab: '您已經在多個標籤或瀏覽器中創建了會話. 請關閉其他瀏覽器或標籤, 然後刷新頁面.',
    networkDisconnectSessionNotFound: '會話未找到.',
    networkTroubleshootingHelp: '從 <1>網絡問題指導冊</1> 中尋找幫助.',
    noticeAddedMedia: '添加 <Media id="{{mediaId}}">{{mediaTitle}}</Media>',
    noticeMediaError: '請求發生了錯誤 {{url}}',
    noticeNowPlaying: '現在正在播放 <Username id="{{userId}}">{{username}}</Username> 點播的 <Media id="{{mediaId}}">{{mediaTitle}}</Media>.',
    noticeUserRequestJoin: '<Username id="{{userId}}">{{username}}</Username> 請求加入會話.',
    offline: '離線',
    ok: 'OK',
    once: '單次',
    openInBrowser: '在瀏覽器開啟',
    openInPopup: '在彈岀式視窗開啟',
    openSecondPopup: '(再開啟)',
    patreonLogin: 'Patreon 登入',
    patreonLogout: '登出',
    patreonPledge: '成為 patron',
    patreonPledgeRequired: '只限 patrons',
    patreonPledgeTierRequired: '只限達到 {{tier}} 等級的 patrons',
    playingInPopup: '<0>{{host}}</0> 正在彈出式視窗播放。',
    popupBlocked: '無法開啟彈出式視窗。請容許此網站開啟彈出式視窗或再按一次。',
    private: '私有的',
    profile: '資料',
    public: '公有的',
    reload: '刷新',
    remove: '移除',
    repeat: '重復',
    retry: '重試',
    requestUrl: '添加到會話',
    requestUrlPlaceholder: '',
    required: '需要',
    requiresDJPermissions: '需要 DJ 權限',
    requiresPortForward: '需要將 <3>{{port}}</3> 端口進行 <1>轉發</1> 來接受直接連接.',
    safeBrowse: '安全觀看',
    safeBrowseDesc: '載入非熱門網頁前先要求允許。',
    safeBrowseLoadContent: '載入 <1>{{host}}</1>?',
    safeBrowseDisableForSession: '在此派對停用安全觀看。',
    session: '派對',
    sessionMode: '派對模式',
    sessionModePublicDescription: '任何人都允許加入.',
    sessionModePrivateDescription: '需授予權限後才能加入.',
    sessionModeOfflineDescription: '任何人都不能加入.',
    settings: '設置',
    shareFriendCode: '分享你的夥伴代碼來邀請別人.',
    shareFriendCodeOther: '分享 {{name}} 的夥伴代碼來邀請別人.',
    sharePublicIP: '分享 <1>你的公網IP地址</1> 來允許他人直接連接.',
    shareSessionUrl: '分享會話鏈接來邀請別人.',
    supportMetastream: '支持 Metastream 以解鎖更多功能!',
    startSession: '開始派對',
    theaterMode: '影院模式',
    theaterModeDesc: '移除所有非影片內容。可能會隱藏字幕。',
    thirdPartyIntegrations: '第三方插件',
    toggleDJ: '切換 DJ',
    uiDockToRight: '側邊欄在右邊',
    uiUndock: '浮動側邊欄',
    unlimited: '無限制',
    unlockQueue: '解鎖隊列',
    updateAvailable: 'Metastream 有更新可用。按右上方 “更新” 鈕以取得更新。',
    username: '用戶名',
    users: '用戶',
    userJoined: '<Username id="{{userId}}">{{username}}</Username> 加入了.',
    userLeft: '<Username id="{{userId}}">{{username}}</Username> 離開了.',
    userNameChanged: '<Username id="{{userId}}">{{prevName}}</Username> 已更改為 <Username id="{{userId}}">{{name}}</Username>.',
    viewOnGitHub: '在 GitHub 上查看',
    waitingForHost: '等待管理員允許加入',
    welcome: '歡迎',
    welcomeToMetastream: '歡迎來到 Metastream',
    welcomeMessage1: 'Hi, thanks for trying out Metastream!'
};
