import { Component } from 'react';
/** Time before user is considered inactive */
const INACTIVE_DURATION = 3000;
export class ActivityMonitor extends Component {
    constructor() {
        super(...arguments);
        this._active = true;
        this.onActivityTimeout = () => {
            this.active = false;
            this.activityTimeoutId = undefined;
        };
        this.onActivity = (event) => {
            // Ignore chat activity
            if (event instanceof KeyboardEvent && document.activeElement instanceof HTMLInputElement) {
                return;
            }
            this.active = true;
            if (this.activityTimeoutId) {
                clearTimeout(this.activityTimeoutId);
            }
            if (!this.isMouseDown) {
                this.activityTimeoutId = setTimeout(this.onActivityTimeout, INACTIVE_DURATION);
            }
        };
        /** Don't go inactive while mouse is held down; useful for scrolling */
        this.onMouseDown = () => {
            this.isMouseDown = true;
            this.onActivity();
        };
        this.onMouseUp = () => {
            this.isMouseDown = false;
            this.onActivity();
        };
    }
    get active() {
        return this._active;
    }
    set active(state) {
        if (this._active !== state) {
            this._active = state;
            this.props.onChange(this._active);
        }
    }
    componentDidMount() {
        document.addEventListener('mousedown', this.onMouseDown, false);
        document.addEventListener('mouseup', this.onMouseUp, false);
        document.addEventListener('mousemove', this.onActivity, false);
        document.addEventListener('mousewheel', this.onActivity, false);
        document.addEventListener('keydown', this.onActivity, false);
    }
    componentWillUnmount() {
        document.removeEventListener('mousedown', this.onMouseDown, false);
        document.removeEventListener('mouseup', this.onMouseUp, false);
        document.removeEventListener('mousemove', this.onActivity, false);
        document.removeEventListener('mousewheel', this.onActivity, false);
        document.removeEventListener('keydown', this.onActivity, false);
        if (this.activityTimeoutId) {
            clearTimeout(this.activityTimeoutId);
            this.activityTimeoutId = undefined;
        }
    }
    render() {
        return null;
    }
}
