import { EventEmitter } from 'events';
import { VERSION } from '../constants/app';
import { sleep } from '../utils/async';
const UPDATE_INTERVAL = 12 * 60 * 60 * 1000;
let updateService;
export class UpdateService extends EventEmitter {
    constructor() {
        super();
        this.isUpdateAvailable = false;
        this.update = async () => {
            // unregister all service workers to clear cache
            const registrations = await navigator.serviceWorker.getRegistrations();
            await Promise.all(registrations.map(registration => registration.unregister()));
            window.location.reload(true);
        };
        this.checkForUpdate = this.checkForUpdate.bind(this);
        setInterval(this.checkForUpdate, UPDATE_INTERVAL);
    }
    static getInstance() {
        if (!updateService)
            updateService = new UpdateService();
        return updateService;
    }
    async checkForUpdate(delay) {
        if (delay)
            await sleep(delay);
        let liveVersion;
        try {
            const response = await fetch('/version.txt', { cache: 'no-cache' });
            liveVersion = response.status === 200 && (await response.text());
        }
        catch (e) {
            console.error('Failed to check for update', e);
        }
        if (!liveVersion)
            return;
        this.isUpdateAvailable = VERSION !== liveVersion;
        if (this.isUpdateAvailable) {
            this.emit('update', liveVersion, VERSION);
        }
    }
}
