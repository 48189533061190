import React from 'react';
import { connect } from 'react-redux';
import { TimestampLinkText } from '../common/typography';
import { server_requestSeek } from '../../lobby/actions/mediaPlayer';
import { timestampToMilliseconds } from '../../utils/cuepoints';
const _TimestampButton = ({ timestamp, requestSeek }) => {
    const timeMs = timestampToMilliseconds(timestamp);
    return (React.createElement("button", { onClick: () => requestSeek(timeMs), "aria-label": `Seek to ${timestamp}` },
        React.createElement(TimestampLinkText, null, timestamp)));
};
export const TimestampButton = connect(null, (dispatch) => ({
    requestSeek(time) {
        dispatch(server_requestSeek(time));
    }
}))(_TimestampButton);
