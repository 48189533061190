import React, { Component } from 'react';
import cx from 'classnames';
import { t } from 'locale';
import { IconButton, HighlightButton } from 'components/common/button';
import { HighlightText, MediumText } from '../common/typography';
import styles from './Controls.css';
export class WebControls extends Component {
    constructor() {
        super(...arguments);
        this.webview = null;
        this.addressInput = null;
        this.mounted = false;
        this.state = {};
        this.onNavigation = (e) => this.updateURL(e.url);
        this.setStartedLoading = () => this.setState({ loading: true });
        this.setStoppedLoading = () => this.setState({ loading: false });
        this.onLocationKeyPress = (event) => {
            if (event.key === 'Enter') {
                event.preventDefault();
                const url = this.addressUrl;
                const shouldRequest = event.ctrlKey || event.shiftKey || event.altKey;
                if (url && shouldRequest) {
                    this.requestUrl(url);
                }
                else if (url) {
                    this.loadURL(url);
                }
            }
        };
    }
    get addressUrl() {
        return (this.addressInput && this.addressInput.value) || '';
    }
    componentDidMount() {
        this.mounted = true;
    }
    componentWillUnmount() {
        this.mounted = false;
    }
    render() {
        // back, forward, location bar, play button, exit
        const addressUrl = this.addressUrl;
        const backBtn = (React.createElement(IconButton, { className: styles.button, icon: "arrow-left", onClick: () => {
                if (this.webview) {
                    this.webview.goBack();
                }
            }, 
            // try to prevent player iframe navigation
            disabled: addressUrl.length === 0 }));
        const forwardBtn = (React.createElement(IconButton, { className: styles.button, icon: "arrow-right", onClick: () => {
                if (this.webview) {
                    this.webview.goForward();
                }
            } }));
        const refreshBtn = (React.createElement(IconButton, { className: styles.button, icon: this.state.loading ? 'x' : 'rotate-cw', onClick: e => {
                if (this.webview) {
                    if (this.state.loading) {
                        this.webview.stop();
                    }
                    else if (e.shiftKey || process.env.NODE_ENV === 'development') {
                        this.webview.reloadIgnoringCache();
                    }
                    else {
                        this.webview.reload();
                    }
                }
            } }));
        const homeBtn = (React.createElement(IconButton, { className: styles.button, icon: "home", onClick: e => {
                if (this.webview) {
                    this.webview.loadURL(this.props.initialUrl);
                }
            } }));
        const playBtn = (React.createElement("div", { className: styles.requestButtonContainer },
            React.createElement(HighlightButton, { icon: "play", onClick: this.onPlayClicked.bind(this), disabled: !this.state.canRequest, highlight: this.state.canRequest, title: React.createElement(MediumText, null,
                    "When you\u2019re ready to share a link, press the",
                    ' ',
                    React.createElement(HighlightText, null, "Add To Session"),
                    " button."), tooltipProps: {
                    classes: { tooltip: styles.addHintTooltip },
                    placement: 'bottom-end',
                    open: !!this.state.showAddHint
                } }, t('requestUrl'))));
        const closeBtn = (React.createElement(IconButton, { className: styles.button, icon: "x", onClick: this.onCloseClicked.bind(this) }));
        return (React.createElement("div", { className: cx(this.props.className, styles.container) },
            backBtn,
            forwardBtn,
            refreshBtn,
            homeBtn,
            this.renderLocation(),
            playBtn,
            closeBtn));
    }
    renderLocation() {
        return (React.createElement("div", { className: styles.locationContainer },
            React.createElement("div", { className: styles.locationBar },
                React.createElement("input", { ref: el => {
                        this.addressInput = el;
                    }, type: "text", className: styles.addressInput, onKeyPress: this.onLocationKeyPress, onChange: () => {
                        /* force react controlled input */
                        this.onAddressChange();
                    }, placeholder: t('requestUrlPlaceholder'), spellCheck: false, autoFocus: true }))));
    }
    setWebview(webview) {
        if (webview) {
            webview.addEventListener('will-navigate', this.onNavigation);
            webview.addEventListener('did-navigate', this.onNavigation);
            webview.addEventListener('did-navigate-in-page', this.onNavigation);
            webview.addEventListener('will-navigate', this.setStartedLoading);
            webview.addEventListener('did-navigate', this.setStoppedLoading);
            webview.addEventListener('did-navigate-in-page', this.setStoppedLoading);
            webview.addEventListener('did-stop-loading', this.setStoppedLoading);
        }
        else if (this.webview) {
            this.webview.removeEventListener('will-navigate', this.onNavigation);
            this.webview.removeEventListener('did-navigate', this.onNavigation);
            this.webview.removeEventListener('did-navigate-in-page', this.onNavigation);
            this.webview.removeEventListener('will-navigate', this.setStartedLoading);
            this.webview.removeEventListener('did-navigate', this.setStoppedLoading);
            this.webview.removeEventListener('did-navigate-in-page', this.setStoppedLoading);
            this.webview.removeEventListener('did-stop-loading', this.setStoppedLoading);
        }
        this.webview = webview;
    }
    focusURL() {
        if (this.addressInput) {
            this.addressInput.focus();
            this.addressInput.select();
        }
    }
    updateURL(url) {
        if (url.startsWith(location.origin)) {
            url = '';
        }
        if (this.addressInput) {
            const prevUrl = this.addressInput.value;
            if (prevUrl !== url) {
                this.addressInput.value = url;
                this.onAddressChange();
                this.forceUpdate();
            }
        }
    }
    requestUrl(url) {
        if (url.startsWith(location.origin)) {
            return;
        }
        const { onRequestUrl } = this.props;
        if (onRequestUrl) {
            onRequestUrl(url);
        }
    }
    onAddressChange() {
        const url = this.addressUrl;
        const canRequest = !!(url && url.length >= 1 && !url.startsWith('about:blank'));
        if (canRequest !== this.state.canRequest) {
            this.setState({ canRequest });
            if (canRequest) {
                this.checkShowAddHint();
            }
        }
    }
    checkShowAddHint() {
        const hasRequestedMediaBefore = Boolean(localStorage.getItem("requestCount" /* RequestCount */));
        if (!hasRequestedMediaBefore) {
            setTimeout(() => {
                if (this.mounted) {
                    this.setState({ showAddHint: true });
                }
            }, 5e3);
        }
    }
    loadURL(url) {
        if (!url.match(/^[\w-]+?:\/\//i)) {
            url = `https://${url}`;
        }
        if (this.webview) {
            this.webview.loadURL(url);
        }
    }
    onPlayClicked() {
        const url = this.addressUrl;
        if (url) {
            this.requestUrl(url);
        }
    }
    onCloseClicked() {
        const { onClose } = this.props;
        if (onClose) {
            onClose();
        }
    }
}
