import { localUserId } from '../../network';
import { isAdmin, isDJ } from './users.helpers';
export const getCurrentMedia = (state) => {
    return state.mediaPlayer.current;
};
export const getCurrentMediaId = (state) => {
    const { current } = state.mediaPlayer;
    return current && current.id;
};
export const getPlaybackState = (state) => {
    return state.mediaPlayer.playback;
};
export const isPlaying = (state) => getPlaybackState(state) === 1 /* Playing */;
const calcTime = (state) => {
    switch (state.playback) {
        case 1 /* Playing */:
            const deltaTime = Date.now() - (state.startTime + state.serverClockSkew);
            const curTime = deltaTime * state.playbackRate;
            return curTime;
        case 2 /* Paused */:
            return state.pauseTime;
        default:
            return -1;
    }
};
export const getPlaybackTime = (state) => calcTime(state.mediaPlayer);
/** Derive playback time from mediaPlayer state subset */
export const getPlaybackTime2 = (state) => calcTime(state);
export const getMediaQueue = (state) => {
    return state.mediaPlayer.queue;
};
export const getMediaById = (state, id) => {
    if (getCurrentMediaId(state) === id) {
        return getCurrentMedia(state);
    }
    return state.mediaPlayer.queue.find(media => media.id === id);
};
export const hasPlaybackPermissions = (state, id = localUserId()) => {
    const userId = typeof id === 'object' ? id.id.toString() : id;
    return isAdmin(state, userId) || isDJ(state, userId);
};
