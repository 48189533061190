import React, { Component } from 'react';
import { Trans } from 'react-i18next';
import styles from './SessionJoin.css';
import LayoutMain from 'components/layout/Main';
import { MenuButton } from 'components/menu/MenuButton';
import { TextInput, InputGroup } from './common/input';
import { MenuHeader } from './menu/MenuHeader';
import { t } from 'locale';
import { PRODUCT_NAME } from 'constants/app';
import { assetUrl } from 'utils/appUrl';
import { ExternalLink } from './common/link';
import { DISCORD_INVITE_URL } from 'constants/social';
export class SessionJoin extends Component {
    constructor() {
        super(...arguments);
        this.sessionInput = null;
    }
    render() {
        return (React.createElement(LayoutMain, { className: styles.container },
            React.createElement(MenuHeader, { text: t('joinSession') }),
            React.createElement("section", null,
                React.createElement("form", { onSubmit: e => e.preventDefault() },
                    React.createElement("p", null, t('enterJoinDest')),
                    React.createElement(InputGroup, null,
                        React.createElement(TextInput, { theRef: el => (this.sessionInput = el), className: styles.peerId, placeholder: "e.g. https://app.getmetastream.com/join/abcd123\u2026", defaultValue: localStorage.getItem('prevFriendCode') || undefined, spellCheck: false, autoFocus: true, required: true }),
                        React.createElement(MenuButton, { icon: "globe", size: "medium", onClick: () => {
                                const valid = Boolean(this.sessionInput && this.sessionInput.checkValidity());
                                if (valid) {
                                    const value = this.sessionInput.value.trim();
                                    localStorage.setItem('prevFriendCode', value);
                                    this.props.connect(value);
                                }
                                else {
                                    this.sessionInput.classList.add('invalid');
                                }
                            } }, t('join'))))),
            React.createElement("section", { className: styles.discovery },
                React.createElement("h2", { className: styles.header }, t('findSession')),
                React.createElement("p", null,
                    React.createElement(Trans, { i18nKey: "findSessionDescription" },
                        "Join the ",
                        React.createElement("strong", null, "#sessions"),
                        " channel on the ",
                        PRODUCT_NAME,
                        " Discord community to find other users\u2019 sessions. Click the Discord logo below to join."),
                    React.createElement(ExternalLink, { href: DISCORD_INVITE_URL },
                        React.createElement("img", { src: assetUrl('icons/social/discord-color.svg'), className: styles.discordLogo, alt: "Discord" }))))));
    }
}
