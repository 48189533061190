export const sleep = (time) => {
    return new Promise(resolve => {
        setTimeout(resolve, time);
    });
};
/**
 * Wraps a promise to be resolved at a later time.
 */
export class Deferred {
    constructor() {
        this.resolved = false;
        this.rejected = false;
        this.promise = new Promise((resolve, reject) => {
            this._resolve = resolve;
            this._reject = reject;
        });
    }
    resolve(value) {
        this.resolved = true;
        this._resolve(value);
    }
    reject(reason) {
        this.rejected = true;
        this._reject(reason);
    }
}
