export default {
    add: 'Add',
    addMedia: 'Add Media',
    addMediaCTA: 'Get started by adding media to the session.',
    admin: 'Admin',
    advanced: 'Advanced',
    allow: 'Allow',
    allowTracking: 'Send app usage information',
    allowTrackingDesc: 'Anonymous usage information is sent to the developer to better understand how the app is being used.',
    always: 'Always',
    appearance: 'Appearance',
    autoFullscreen: 'Auto-fullscreen',
    autoFullscreenDesc: 'Zoom to fit active video within the window frame.',
    avatar: 'Avatar',
    avatarCredit: '<0>Selected avatar art by</0> <1>{{artist}}</1>',
    cancel: 'Cancel',
    changelog: 'Changelog',
    chat: 'Chat',
    chatColor: 'Chat Color',
    chatMessageFailed: 'Unable to send your message. Please try again.',
    chatPlaceholder: 'Message or link',
    chatRevealHint: 'Press <1></1> to reveal chat.',
    chatNewMessage: 'A new message has been posted!',
    chatTimestamp: 'Chat timestamps',
    chatTimestampDesc: 'Show timestamp next to chat messages.',
    chatTyping1: '<0></0> is typing…',
    chatTyping2: '<0></0> and <2></2> are typing…',
    chatTyping3: '<0></0>, <2></2>, and <4></4> are typing…',
    chatTypingSeveral: 'Several people are typing…',
    chooseDisplayName: 'Choose your display name',
    connecting: 'Connecting',
    continue: 'Continue',
    copy: 'Copy',
    copyLink: 'Copy link',
    debug: 'Debug',
    developerMode: 'Developer Mode',
    developerModeDesc: 'Show developer tools for inspecting web pages.',
    directIP: 'Direct IP',
    disallow: 'Disallow',
    disconnect: 'Disconnect',
    disconnected: 'Disconnected',
    displayName: 'Display Name',
    dj: 'DJ',
    donate: 'Donate',
    donators: 'Donators',
    duplicate: 'Duplicate',
    embedBlocked: 'To enable playback with <1>{{host}}</1>, Metastream must open the website in a popup.',
    endSessionTitle: 'End Session?',
    endSessionDescription: 'Changing to an offline session will disconnect all connected users.',
    endSessionModalDescription: 'All users will be disconnected from the session.',
    endSessionButton: 'End Session',
    enterJoinDest: 'Enter a Metastream session link.',
    findSession: 'Find Session',
    findSessionDescription: 'Join the <1>#sessions</1> channel on the Metastream Discord community to find other users’ sessions. Click the Discord logo below to join.',
    focusPopup: 'Focus popup',
    friendCode: 'Friend code',
    getStarted: 'Get Started',
    help: 'Help',
    info: 'Info',
    installToDesktop: 'Install to desktop',
    interact: 'Interact',
    invite: 'Invite',
    join: 'Join',
    joinSession: 'Join Session',
    kick: 'Kick',
    language: 'Language',
    lockQueue: 'Lock queue',
    moveToTop: 'Move to top',
    maxUsers: 'Max users',
    next: 'Next',
    nextUp: 'Next Up',
    networkDisconnectHostDisconnect: 'Host closed connection.',
    networkDisconnectError: 'Network error.',
    networkDisconnectInvalidClientInfo: 'Invalid client info.',
    networkDisconnectVersionMismatch: `Client version mismatch. Your client, or the host’s, requires an update.`,
    networkDisconnectFull: 'Session is full.',
    networkDisconnectKicked: 'Kicked from session.',
    networkDisconnectMultiTab: 'You are hosting a Metastream session from more than one browser or tab. Please close the other tabs or other browsers, then reload the page.',
    networkDisconnectSessionNotFound: 'Session not found.',
    networkTroubleshootingHelp: 'See <1>Network Troubleshooting guide</1> for help.',
    noticeAddedMedia: 'Added <Media id="{{mediaId}}">{{mediaTitle}}</Media>',
    noticeMediaError: 'There was an error requesting {{url}}',
    noticeNowPlaying: 'Now playing <Media id="{{mediaId}}">{{mediaTitle}}</Media> requested by <Username id="{{userId}}">{{username}}</Username>.',
    noticeUserRequestJoin: '<Username id="{{userId}}">{{username}}</Username> is requesting permission to join.',
    offline: 'Offline',
    ok: 'OK',
    once: 'Once',
    openInBrowser: 'Open in browser',
    openInPopup: 'Open in popup',
    openSecondPopup: '(once more)',
    patreonLogin: 'Login with Patreon',
    patreonLogout: 'Logout',
    patreonPledge: 'Become a patron',
    patreonPledgeRequired: 'Only available to patrons',
    patreonPledgeTierRequired: 'Only available to patrons pledged to the {{tier}} tier',
    playingInPopup: '<0>{{host}}</0> is playing in a popup.',
    popupBlocked: 'A popup was blocked from opening. Please allow popups on this website or press the button once more.',
    private: 'Private',
    profile: 'Profile',
    public: 'Public',
    reload: 'Reload',
    remove: 'Remove',
    repeat: 'Repeat',
    retry: 'Retry',
    requestUrl: 'Add to session',
    requestUrlPlaceholder: '',
    required: 'Required',
    requiresDJPermissions: 'Requires DJ permissions',
    requiresPortForward: 'Requires <1>setting up port forwarding</1> of <3>{{port}}</3> to accept direct connections.',
    safeBrowse: 'Safe browse',
    safeBrowseDesc: 'Prompt for approval prior to loading an unrecognized website.',
    safeBrowseLoadContent: 'Load content from <1>{{host}}</1>?',
    safeBrowseDisableForSession: 'Disable safe browse for this session',
    session: 'Session',
    sessionMode: 'Session mode',
    sessionModePublicDescription: 'Anyone is allowed to join.',
    sessionModePrivateDescription: 'Permission to join must be granted explicitly.',
    sessionModeOfflineDescription: 'No one is allowed to join.',
    settings: 'Settings',
    shareFriendCode: 'Share your friend code below to invite friends.',
    shareFriendCodeOther: 'Share {{name}}’s friend code below to invite friends.',
    sharePublicIP: 'Share <1>your public IP address</1> to allow friends to connect directly.',
    shareSessionUrl: 'Share the session link below to invite friends.',
    supportMetastream: 'Support Metastream to unlock rewards!',
    startSession: 'Start Session',
    theaterMode: 'Theater mode',
    theaterModeDesc: 'Hide all non-video content on the webpage. Note that this might also hide soft subtitles.',
    thirdPartyIntegrations: 'Third-party Integrations',
    toggleDJ: 'Toggle DJ',
    uiDockToRight: 'Dock to right side',
    uiUndock: 'Undock into floating overlays',
    unlimited: 'Unlimited',
    unlockQueue: 'Unlock queue',
    updateAvailable: 'An update for Metastream is available. Press the UPDATE button in the top-right to receive the update.',
    username: 'Username',
    users: 'Users',
    userJoined: '<Username id="{{userId}}">{{username}}</Username> has joined.',
    userLeft: '<Username id="{{userId}}">{{username}}</Username> has left.',
    userNameChanged: '<Username id="{{userId}}">{{prevName}}</Username> is now known as <Username id="{{userId}}">{{name}}</Username>.',
    viewOnGitHub: 'View on GitHub',
    waitingForHost: 'Waiting for host to allow connection',
    welcome: 'Welcome',
    welcomeToMetastream: 'Welcome to Metastream',
    welcomeMessage1: 'Hi, thanks for trying out Metastream!',
};
