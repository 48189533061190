import React, { useState } from 'react';
import { LoginButton, SignupButton } from './buttons';
import { AccountService } from 'account/account';
import { t } from 'locale';
import styles from './DonateBar.css';
import { MediumText } from 'components/common/typography';
import { usePatronTier } from 'account/hooks';
export const DonateBar = ({ className }) => {
    const [error, setError] = useState(null);
    const tier = usePatronTier();
    if (tier !== 0 /* None */)
        return null;
    return (React.createElement("div", { className: className },
        error && React.createElement("div", { className: styles.error }, error),
        React.createElement("div", { className: styles.container },
            React.createElement(MediumText, null,
                t('supportMetastream'),
                "\u00A0\uD83C\uDF89"),
            React.createElement("div", { className: styles.buttons },
                React.createElement(SignupButton, null),
                React.createElement("span", { className: styles.then }, "THEN"),
                React.createElement(LoginButton, { onClick: async () => {
                        if (error)
                            setError(null);
                        try {
                            await AccountService.get().promptLogin();
                        }
                        catch (e) {
                            console.log(e);
                            window.E = e;
                            setError(e.message);
                        }
                    } })))));
};
