import { Component } from 'react';
export class Fetch extends Component {
    constructor() {
        super(...arguments);
        this.state = {
            data: this.getCachedData()
        };
    }
    getCachedData() {
        const data = sessionStorage.getItem(this.props.cacheKey);
        if (!data)
            return;
        let res;
        try {
            res = JSON.parse(data);
        }
        catch { }
        return res;
    }
    async fetchData() {
        let data = null;
        try {
            const resp = await fetch(this.props.href);
            const contentType = resp.headers.get('Content-Type') || '';
            const content = await (contentType.indexOf('application/json') > -1
                ? resp.json()
                : resp.text());
            if (resp.ok) {
                data = content;
                sessionStorage.setItem(this.props.cacheKey, JSON.stringify(content));
            }
        }
        catch { }
        this.setState({ data });
    }
    componentDidMount() {
        if (!this.state.data) {
            this.fetchData();
        }
    }
    render() {
        const { children } = this.props;
        const { data } = this.state;
        if (typeof children === 'function') {
            return children(data);
        }
        return null;
    }
}
