import React, { Component } from 'react';
import cx from 'classnames';
import { EventEmitter } from 'events';
import { PopupWindow } from './Popup';
import { WebviewError } from './lobby/overlays/WebviewError';
import styles from './Webview.css';
import { dispatchExtensionMessage } from 'utils/extension';
const INITIALIZE_TIMEOUT_DURATION = 1000;
const NAVIGATION_TIMEOUT_DURATION = 5000;
let webviewId = 0;
export class Webview extends Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.id = webviewId++;
        this.tabId = -1;
        this.frameId = -1;
        this.emitter = new EventEmitter();
        this.iframe = null;
        this.url = 'about:blank';
        this.onInitializeTimeout = () => {
            this.clearInitializeTimeout();
            this.setState({ timeout: true });
        };
        this.onNavigateTimeout = () => {
            this.clearNavigateTimeout();
            this.setState({ timeout: true });
        };
        this.willNavigate = ({ url }) => {
            this.clearNavigateTimeout();
            this.startNavigateTimeout();
        };
        this.didNavigate = () => {
            this.clearNavigateTimeout();
            if (this.props.onNavigate) {
                this.props.onNavigate(this.url);
            }
        };
        this.emitter.setMaxListeners(32);
        this.onMessage = this.onMessage.bind(this);
        window.addEventListener('message', this.onMessage);
    }
    get initialUrl() {
        // prettier-ignore
        return `about:blank?webview=${this.id}&allowScripts=${!!this.props.allowScripts}&popup=${!!this.props.popup}`;
    }
    /** https://developer.mozilla.org/en-US/docs/Web/HTTP/Feature_Policy */
    get featurePolicy() {
        // prettier-ignore
        return [
            'midi',
            'fullscreen',
            'geolocation',
            'picture-in-picture',
            'encrypted-media',
            'autoplay'
        ].map(feature => `${feature} *`).join(', ');
    }
    get sandboxPolicy() {
        // prettier-ignore
        return [
            'forms',
            // 'allowScripts' is used for the media player view. Prevent popups while not interacting.
            this.props.allowScripts ? undefined : 'popups',
            'presentation',
            'same-origin',
            'scripts',
        ].filter(Boolean).map(feature => `allow-${feature}`).join(' ');
    }
    onMessage(event) {
        const { data } = event;
        if (typeof data !== 'object' || typeof data.type !== 'string')
            return;
        if (data.type === `metastream-webview-init${this.id}`) {
            const { tabId, frameId } = data.payload;
            this.tabId = tabId;
            this.frameId = frameId;
            this.onInitialized();
            return;
        }
        else if (this.frameId < 0) {
            return; // wait to initialize
        }
        // Filter out messages from non-subframe descendants
        const { framePath } = data;
        if (!Array.isArray(framePath) || (!this.props.popup && framePath[1] !== this.frameId)) {
            return;
        }
        if (data.type === 'metastream-webview-event') {
            this.onWebviewMessage(data.payload, framePath);
        }
    }
    onWebviewMessage(action, framePath) {
        if (typeof action.payload === 'object' && typeof action.payload.url === 'string') {
            this.url = action.payload.url;
        }
        if (action.type === 'activity') {
            this.onIFrameActivity();
            if (this.props.onActivity) {
                this.props.onActivity(action.payload);
            }
        }
        else if (action.type === 'load-script') {
            // 'load-script' runs during webview initialization so we know the page
            // has started loading at this point
            this.clearNavigateTimeout();
        }
        // Whether the message was sent from the top subframe
        const isTopSubFrame = framePath[framePath.length - 1] === this.frameId;
        this.emitter.emit(action.type, action.payload, isTopSubFrame);
    }
    clearInitializeTimeout() {
        if (this.initializeTimeoutId) {
            clearTimeout(this.initializeTimeoutId);
            this.initializeTimeoutId = undefined;
        }
        if (this.state.timeout)
            this.setState({ timeout: false });
    }
    startInitializeTimeout() {
        this.clearInitializeTimeout();
        this.initializeTimeoutId = setTimeout(this.onInitializeTimeout, INITIALIZE_TIMEOUT_DURATION);
    }
    onInitialized() {
        this.clearInitializeTimeout();
        if (this.props.src) {
            this.loadURL(this.props.src);
        }
        this.emitter.on('will-navigate', this.willNavigate);
        this.emitter.on('did-navigate', this.didNavigate);
        this.emitter.emit('ready');
    }
    /** Notifies top frame of iframe activity */
    onIFrameActivity() {
        const e = new Event('mousemove', { cancelable: false, bubbles: true });
        document.dispatchEvent(e);
    }
    clearNavigateTimeout() {
        if (this.navigateTimeoutId) {
            clearTimeout(this.navigateTimeoutId);
            this.navigateTimeoutId = undefined;
        }
        if (this.state.timeout)
            this.setState({ timeout: false });
    }
    startNavigateTimeout() {
        this.clearNavigateTimeout();
        this.navigateTimeoutId = setTimeout(this.onNavigateTimeout, NAVIGATION_TIMEOUT_DURATION);
    }
    componentWillReceiveProps(nextProps) {
        if (this.props.popup !== nextProps.popup) {
            this.id = webviewId++;
        }
    }
    componentWillUnmount() {
        window.removeEventListener('message', this.onMessage);
        this.emitter.removeAllListeners();
    }
    componentDidMount() {
        this.startInitializeTimeout();
    }
    render() {
        const { componentRef, src, allowScripts, className, popup, onClosePopup, backgroundImage, onActivity, mediaSrc, onNavigate, ...rest } = this.props;
        // TODO(samuelmaddock): Update React and types so these props can be passed in
        const untypedProps = {
            referrerPolicy: 'origin',
            allowtransparency: ''
        };
        if (FEATURE_POPUP_PLAYER && popup) {
            return (React.createElement(PopupWindow, { theRef: e => {
                    if (componentRef) {
                        componentRef(e ? this : null);
                    }
                }, id: this.id, src: this.initialUrl, mediaSrc: mediaSrc, onClose: onClosePopup, backgroundImage: backgroundImage }));
        }
        return (React.createElement("div", { className: cx(className, styles.container) },
            React.createElement("iframe", Object.assign({ ref: e => {
                    this.iframe = e;
                    if (componentRef) {
                        componentRef(e ? this : null);
                    }
                }, allow: this.featurePolicy, sandbox: this.sandboxPolicy, src: this.initialUrl, 
                // Required for Firefox until it supports allow attribute
                allowFullScreen: true, className: styles.webview }, untypedProps, rest)),
            this.state.timeout ? React.createElement(WebviewError, { className: styles.error, url: this.url }) : null));
    }
    addEventListener(eventName, listener) {
        this.emitter.addListener(eventName, listener);
    }
    removeEventListener(eventName, listener) {
        this.emitter.removeListener(eventName, listener);
    }
    dispatchRemoteEvent(type, payload, options = {}) {
        if (this.tabId === -1 || this.frameId === -1)
            return;
        dispatchExtensionMessage('metastream-webview-event', { type, payload }, { tabId: this.tabId, frameId: options.allFrames ? undefined : this.frameId });
    }
    loadURL(url, opts = {}) {
        this.url = url;
        if (this.iframe)
            this.iframe.src = url;
        else if (this.props.popup)
            PopupWindow.loadURL(url);
    }
    getURL() {
        return this.url;
    }
    goBack() {
        this.dispatchRemoteEvent('navigate', -1);
    }
    goForward() {
        this.dispatchRemoteEvent('navigate', 1);
    }
    stop() {
        this.dispatchRemoteEvent('stop');
        this.emitter.emit('did-stop-loading');
    }
    reload() {
        this.dispatchRemoteEvent('reload');
    }
    reloadIgnoringCache() {
        this.dispatchRemoteEvent('reload', true);
    }
    getIFrame() {
        return this.iframe;
    }
}
