import React, { useCallback } from 'react';
import { locales, t, setLocale } from 'locale';
import { Dropdown } from './controls';
import { useDispatch, useSelector } from 'react-redux';
import { setSetting as setSettingAction } from 'actions/settings';
export const LanguageSetting = props => {
    const dispatch = useDispatch();
    const setSetting = useCallback((...args) => dispatch(setSettingAction(...args)), [dispatch]);
    const settings = useSelector(state => state.settings);
    return (React.createElement(React.Fragment, null,
        React.createElement("label", { htmlFor: "appearance_language" }, t('language')),
        React.createElement(Dropdown, { id: "appearance_language", onChange: e => {
                const value = e.target.value;
                setSetting('language', value);
                setLocale(value);
                if (props.onChange)
                    props.onChange();
            } }, locales.map(locale => (React.createElement("option", { key: locale.code, value: locale.code, selected: locale.code === settings.language },
            locale.label,
            " ",
            locale.flag))))));
};
