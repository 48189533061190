import React, { Component } from 'react';
import { Slider } from 'components/media/Slider';
import { Icon } from 'components/Icon';
import { Ticker } from 'components/Ticker';
import styles from './VolumeSlider.css';
export class VolumeSlider extends Component {
    constructor() {
        super(...arguments);
        this.state = { level: this.calcLevel() };
        this.slider = null;
        this.tick = () => {
            this.setState({ level: this.calcLevel() });
        };
    }
    componentDidUpdate(prevProps) {
        if (this.props.mute !== prevProps.mute || this.props.volume !== prevProps.volume) {
            this.tick();
        }
    }
    calcLevel() {
        if (this.props.mute) {
            return -1 /* Mute */;
        }
        let volume;
        if (this.slider && this.slider.state.dragging) {
            const { cursorProgress } = this.slider.state;
            volume = cursorProgress;
        }
        else {
            volume = this.props.volume;
        }
        return Math.ceil(volume * 2 /* High */);
    }
    render() {
        const { level } = this.state;
        const icon = level === -1 /* Mute */ ? 'volume-x' : `volume-${level}`;
        return (React.createElement("div", { className: styles.container },
            React.createElement("button", { type: "button", className: styles.iconBtn, onClick: this.props.onMute },
                React.createElement(Icon, { name: icon })),
            React.createElement(Slider, { ref: el => {
                    this.slider = el;
                }, className: styles.slider, value: this.props.volume, scroll: true, changeOnStart: true, onChange: this.props.onChange, onDragStart: () => {
                    this.setState({ dragging: true });
                }, onDrag: this.props.onChange, onDragEnd: () => {
                    this.setState({ dragging: false });
                } }),
            React.createElement(Ticker, { onTick: this.tick, disabled: !this.state.dragging })));
    }
}
