import React from 'react';
import { Switch, Route } from 'react-router';
import App from './containers/App';
import { HomePage } from './containers/HomePage';
import { LobbyPage } from './containers/LobbyPage';
import { SessionJoinPage } from './containers/SessionJoinPage';
import { SettingsPage } from './containers/SettingsPage';
import WelcomePage from './containers/WelcomePage';
export default () => (React.createElement(App, null,
    React.createElement(Switch, null,
        React.createElement(WelcomeRoute, { exact: true, path: "/", component: HomePage }),
        React.createElement(WelcomeRoute, { exact: true, path: "/join", component: SessionJoinPage }),
        React.createElement(WelcomeRoute, { path: "/join/:lobbyId", component: LobbyPage }),
        React.createElement(WelcomeRoute, { path: "/settings", component: SettingsPage }))));
// prettier-ignore
const WelcomeRoute = ({ component: Component, ...rest }) => (React.createElement(Route, Object.assign({}, rest, { render: props => localStorage.getItem('welcomed') ? React.createElement(Component, Object.assign({}, props)) : React.createElement(WelcomePage, Object.assign({}, props)) })));
