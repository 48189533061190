import NetConnection from './connection';
import { PlatformService } from 'platform';
class LocalHostConnection extends NetConnection {
    constructor() {
        const id = PlatformService.get().getLocalId();
        super(id);
        this.authed = true;
    }
    send(data) {
        throw new Error('Attempted to send data to LocalHost');
    }
    getIP() {
        return '127.0.0.1';
    }
    getPort() {
        return '0';
    }
}
let client;
const localUser = () => {
    if (!client) {
        client = new LocalHostConnection();
    }
    return client;
};
export const localUserId = () => localUser().id.toString();
export default localUser;
