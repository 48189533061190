import { Component } from 'react';
import { clamp } from 'utils/math';
/**
 * Over-engineered ticker for high and low FPS animations.
 */
let Ticker = /** @class */ (() => {
    class Ticker extends Component {
        constructor() {
            super(...arguments);
            this.intervalId = null;
            this.rAFId = null;
            this.startTime = null;
            this.slowTick = () => {
                this.rAFId = requestAnimationFrame(this.props.onTick);
            };
            /** High performance ticker */
            this.fastTick = (now) => {
                let elapsed = now - this.lastFrameTime;
                if (elapsed > this.frameRate) {
                    this.props.onTick();
                    this.lastFrameTime = now;
                }
                this.rAFId = requestAnimationFrame(this.fastTick);
            };
        }
        get isRunning() {
            return this.startTime !== null;
        }
        componentDidMount() {
            this.checkTimer();
        }
        componentWillUnmount() {
            this.stopTimer();
        }
        componentDidUpdate(prevProps) {
            if (this.props.fps !== prevProps.fps) {
                this.stopTimer();
            }
            this.checkTimer();
        }
        /** Calculate target frame rate (ms) */
        calcframeRate() {
            const fps = clamp(this.props.fps, 1, 60);
            return 1000 / fps;
        }
        checkTimer() {
            const { disabled } = this.props;
            if (this.isRunning) {
                if (disabled) {
                    this.stopTimer();
                }
            }
            else {
                if (!disabled) {
                    this.startTimer();
                }
            }
        }
        startTimer() {
            this.startTime = this.lastFrameTime = performance.now();
            this.frameRate = this.calcframeRate();
            if (this.frameRate > 100) {
                this.slowTick();
                this.intervalId = setInterval(this.slowTick, this.frameRate);
            }
            else {
                this.rAFId = requestAnimationFrame(this.fastTick);
            }
        }
        stopTimer() {
            this.startTime = null;
            if (this.intervalId) {
                clearTimeout(this.intervalId);
                this.intervalId = null;
            }
            if (this.rAFId) {
                cancelAnimationFrame(this.rAFId);
                this.rAFId = null;
            }
        }
        render() {
            return null;
        }
    }
    Ticker.defaultProps = {
        fps: Infinity
    };
    return Ticker;
})();
export { Ticker };
