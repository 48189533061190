import React from 'react';
import cx from 'classnames';
import { connect } from 'react-redux';
import styles from './Chat.css';
import { getUserColor } from '../../lobby/reducers/users.helpers';
const _ChatUsername = props => {
    const style = {
        color: props.color
    };
    return (React.createElement("span", { className: cx(props.className, styles.username), title: props.userId, style: style }, props.children));
};
export const ChatUsername = connect((state, props) => ({
    color: getUserColor(state, props.userId)
}))(_ChatUsername);
