export default {
    add: 'Добавить',
    addMedia: 'Добавить видео',
    addMediaCTA: 'Начните с добавления видео в этот сеанс.',
    admin: 'Администратор',
    advanced: 'Дополнительно',
    appearance: 'Интерфейс',
    allow: 'Разрешить',
    allowTracking: 'Отправлять информацию об использовании приложения',
    allowTrackingDesc: 'Анонимная информация об использовании отправляется разработчику, чтобы лучше понять, как используется приложение.',
    always: 'Всегда',
    autoFullscreen: 'Авто-полноэкранный режим',
    autoFullscreenDesc: 'Масштабирует просматриваемое видео в пределах окна.',
    avatar: 'Аватар',
    avatarCredit: '<0>Избранные аватары</0> <1>{{artist}}</1>',
    cancel: 'Отменить',
    changelog: 'Изменения',
    chat: 'Чат',
    chatColor: 'Цвет чата',
    chatMessageFailed: 'Не удаётся отправить ваше сообщение. Пожалуйста, попробуйте ещё.',
    chatPlaceholder: 'Сообщение или URL',
    chatRevealHint: 'Нажмите <1></1>, чтобы открыть чат.',
    chatNewMessage: 'Новое сообщение!',
    chatTimestamp: 'Метки времени в чате',
    chatTimestampDesc: 'Показывать метки времени рядом с сообщениями в чате.',
    chatTyping1: '<0></0> пишет…',
    chatTyping2: '<0></0> и <2></2> пишут…',
    chatTyping3: '<0></0>, <2></2> и <4></4> пишут…',
    chatTypingSeveral: 'Несколько человек пишут…',
    chooseDisplayName: 'Выберите ваше имя пользователя',
    connecting: 'Подключение',
    continue: 'Продолжить',
    copy: 'Копировать',
    copyLink: 'Копировать ссылку',
    debug: 'Отладка',
    developerMode: 'Режим разработчика',
    developerModeDesc: 'Показать инструменты разработчика для проверки веб-страниц.',
    directIP: 'Прямой IP',
    disallow: 'Запретить',
    disconnect: 'Отключиться',
    disconnected: 'Отключился',
    displayName: 'Отображаемое имя',
    dj: 'DJ',
    donate: 'Поддержать',
    donators: 'Поддерживают',
    duplicate: 'Дублировать',
    embedBlocked: 'Чтобы включить воспроизведение с <1>{{host}}</1>, Metastream откроет веб-сайт во всплывающем окне.',
    endSessionTitle: 'Завершить сеанс?',
    endSessionDescription: 'Переход к закрытому сеансу отключит всех подключенных пользователей.',
    endSessionModalDescription: 'Все пользователи будут отключены от текущего сеанса.',
    endSessionButton: 'Завершить сеанс',
    enterJoinDest: 'Введите URL-адрес сеанса Metastream',
    findSession: 'Найти сеанс',
    findSessionDescription: 'Присоединяйся к каналу <1>#sessions</1> в Discord сообществе Metastream для поиска сеансов других пользователей. Нажмите на логотип Discord ниже, чтобы присоединиться.',
    friendCode: 'Код друга',
    getStarted: 'Начать',
    info: 'Информация',
    installToDesktop: 'Установить',
    interact: 'Взаимодействовать',
    invite: 'Приглаcить',
    join: 'Войти',
    joinSession: 'Присоединиться',
    kick: 'Выгнать',
    language: 'Язык',
    lockQueue: 'Блокировка очереди',
    moveToTop: 'Переместить наверх',
    maxUsers: 'Максимально пользователей',
    next: 'Следующий',
    nextUp: 'В очереди',
    networkDisconnectHostDisconnect: 'Хост закрыт.',
    networkDisconnectError: 'Тайм-аут сети.',
    networkDisconnectInvalidClientInfo: 'Неверная информация о клиенте.',
    networkDisconnectVersionMismatch: `Несоответствие версии клиента. Ваше клиентское или хост приложение необходимо обновить.`,
    networkDisconnectFull: 'Сеанс заполнен.',
    networkDisconnectKicked: 'Удалён из сеанса.',
    networkDisconnectMultiTab: 'Вы запустили сеанс Metastream более чем с одного браузера или же вкладки. Пожалуйста, закройте все лишние вкладки или браузеры и перезагрузите страницу.',
    networkDisconnectSessionNotFound: 'Сеанс не найден.',
    networkTroubleshootingHelp: 'См. <1>Руководство по устранению неполадок в сети</ 1> для получения справки.',
    noticeAddedMedia: 'Добавлено <Media id="{{mediaId}}">{{mediaTitle}}</Media>.',
    noticeMediaError: 'Произошла ошибка запроса {{url}}',
    noticeNowPlaying: 'Сейчас играет <Media id="{{mediaId}}">{{mediaTitle}}</Media> запрошено пользователем <Username id="{{userId}}">{{username}}</Username>.',
    noticeUserRequestJoin: '<Username id="{{userId}}">{{username}}</Username> запрашивает разрешение на присоединение.',
    offline: 'Не в сети',
    ok: 'OK',
    once: 'Один раз',
    openInBrowser: 'Открыть в браузере',
    openInPopup: 'Открыть во всплывающем окне',
    openSecondPopup: '(ещё раз)',
    patreonLogin: 'Войти через Patreon',
    patreonLogout: 'Выйти',
    patreonPledge: 'Станьте patron\'ом',
    patreonPledgeRequired: 'Доступно только patron\'ам',
    patreonPledgeTierRequired: 'Доступно только patron\'ам со званием {{tier}}',
    playingInPopup: '<0>{{host}}</0> теперь во всплывающем окне.',
    popupBlocked: 'Всплывающее окно было заблокировано. Пожалуйста, разрешите этому веб-сайту всегда показывать их или сделайте это единожды.',
    private: 'Частный',
    profile: 'Профиль',
    public: 'Открытый',
    reload: 'Перезагрузить',
    remove: 'Удалить',
    repeat: 'Повторять',
    retry: 'Ещё раз',
    requestUrl: 'Добавить в сеанс',
    requestUrlPlaceholder: '',
    requiresDJPermissions: 'Требуется разрешения DJ',
    requiresPortForward: 'Требуется <1>настроить переадресацию порта</1> из <3>{{port}}</3> для принятия прямых подключений.',
    safeBrowse: 'Безопасный просмотр',
    safeBrowseDesc: 'Запрашивать подтверждение перед загрузкой непроверенных веб-сайтов.',
    safeBrowseLoadContent: 'Загрузить контент с <1>{{host}}</1>?',
    safeBrowseDisableForSession: 'Отключить безопасный просмотр для этого',
    session: 'Сеанс',
    sessionMode: 'Режим сеанса',
    sessionModePublicDescription: 'Присоединиться может любой желающий.',
    sessionModePrivateDescription: 'Разрешение на присоединение должно быть одобрено.',
    sessionModeOfflineDescription: 'Никто не сможет присоединиться.',
    settings: 'Настройки',
    shareFriendCode: 'Поделитесь своим кодом ниже, чтобы пригласить друзей.',
    shareFriendCodeOther: 'Поделитесь кодом {{name}} ниже, чтобы пригласить друзей.',
    sharePublicIP: 'Поделитесь <1>ваш публичный IP-адрес</1>, чтобы позволить друзьям подключаться напрямую.',
    shareSessionUrl: 'Поделитесь ссылкой ниже на сенанс, чтобы пригласить друзей.',
    supportMetastream: 'Поддержите Metastream, чтобы разблокировать награды!',
    startSession: 'Начать сеанс',
    theaterMode: 'Режим кинотеатра',
    theaterModeDesc: 'Скрыть все элементы, не относящиеся к видео, на этом веб-сайте. Учтите, что это также может скрыть субтитры.',
    thirdPartyIntegrations: 'Сторонние интеграции',
    toggleDJ: 'Переключить DJ',
    uiDockToRight: 'Прикрепить к правой стороне',
    unlimited: 'Неограниченный',
    unlockQueue: 'Разблокировать очередь',
    updateAvailable: 'Доступно обновление для Metastream. Нажмите кнопку «Обновить» в правом верхнем углу, чтобы загрузить обновление.',
    username: 'Имя пользователя',
    users: 'Пользователи',
    userJoined: '<Username id="{{userId}}">{{username}}</Username> присоеденился',
    userLeft: '<Username id="{{userId}}">{{username}}</Username> вышел',
    viewOnGitHub: 'Посмотреть на GitHub',
    waitingForHost: 'Ожидание подтвержения от хоста',
    welcome: 'Добро пожаловать!',
    welcomeToMetastream: 'Добро пожаловать на Metastream'
};
