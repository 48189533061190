import React, { Component } from 'react';
import cx from 'classnames';
import { ExternalLink } from '../common/link';
import { Changelog } from './Changelog';
import { HOME_WEBSITE } from 'constants/http';
import styles from './MenuTabs.css';
import { t } from 'locale';
import { Fetch } from '../common/Fetch';
export class MenuTabs extends Component {
    constructor() {
        super(...arguments);
        this.state = { value: 0 };
    }
    render() {
        const tabs = [
            { label: t('welcome'), render: () => React.createElement(WelcomeMessage, null) },
            { label: t('changelog'), render: () => React.createElement(Changelog, null) },
            { label: t('donators'), render: () => React.createElement(Donators, null) }
        ];
        const selected = tabs[this.state.value];
        return (React.createElement("div", { className: styles.container },
            React.createElement("ul", { className: styles.tabList }, tabs.map((t, idx) => (React.createElement("li", { key: idx, className: cx(styles.tabItem, {
                    [styles.selected]: selected === t
                }) },
                React.createElement("button", { className: styles.tabButton, onClick: () => this.setState({ value: idx }) },
                    React.createElement("span", { className: styles.tabLabel }, t.label)))))),
            React.createElement("div", { className: styles.content }, selected && selected.render())));
    }
}
const WelcomeMessage = () => (
// TODO: l10n?
React.createElement(React.Fragment, null,
    React.createElement("p", null, t('welcomeMessage1')),
    React.createElement("p", null,
        "This release is still in beta, so expect there to be some issues. If you come across any, please",
        ' ',
        React.createElement(ExternalLink, { href: "https://github.com/samuelmaddock/metastream/issues" }, "submit a GitHub issue.")),
    React.createElement("p", null, "If you\u2019d like to join in on the discussion, feel free to join the Discord community using the link at the bottom of the screen."),
    React.createElement("p", null,
        "To learn more about Metastream,",
        ' ',
        React.createElement(ExternalLink, { href: "https://github.com/samuelmaddock/metastream/wiki/FAQ" }, "check out the FAQ.")),
    React.createElement("p", null, "\uD83D\uDC96 Sam")));
const Donators = () => (React.createElement(Fetch, { cacheKey: "donators", href: `${HOME_WEBSITE}/app/donators.txt` }, data => React.createElement("p", { style: { whiteSpace: 'pre-wrap' } }, typeof data === 'string' ? data : '')));
