import React, { Component } from 'react';
import { connect } from 'react-redux';
import { AppReplicatedState } from 'reducers';
import { GameLobby } from 'components/GameLobby';
import { PlatformService } from 'platform';
import { localUserId } from 'network';
import { NetActions } from 'network/actions';
import { push } from 'connected-react-router';
import { NetworkDisconnectMessages, NetworkDisconnectLabels } from 'constants/network';
import { Connect } from '../components/lobby/Connect';
import { Disconnect } from '../components/lobby/Disconnect';
import { getDisconnectReason } from '../lobby/reducers/session';
import { setDisconnectReason } from '../lobby/actions/session';
import { t } from 'locale';
import { resetLobby, initLobby } from '../lobby/actions/common';
import { addChat } from '../lobby/actions/chat';
import { MultiTabObserver } from '../utils/multitab';
import { SafeBrowse } from '../services/safeBrowse';
import { UpdateService } from 'services/updater';
function mapStateToProps(state) {
    return {
        disconnectReason: getDisconnectReason(state),
        clientAuthorized: state.session.authorized,
        sessionMode: state.settings.sessionMode,
        connectionStatus: state.session.connectionStatus
    };
}
/**
 * Component managing lobby connection state.
 */
export class _LobbyPage extends Component {
    constructor(props) {
        super(props);
        this.state = { connected: false };
        this.mounted = false;
        this.disconnect = (reason = 1 /* HostDisconnect */, immediate) => {
            this.setState({ connected: false });
            if (immediate) {
                this.props.dispatch(push('/'));
                return;
            }
            reason = this.props.disconnectReason || reason;
            this.setState({ disconnectReason: reason });
            {
                const reasonKey = NetworkDisconnectMessages[reason];
                let msg = t(reasonKey) || reasonKey;
                console.debug(`Disconnected [${reason}]: ${msg}`);
            }
            ga('event', { ec: 'session', ea: 'disconnect', el: NetworkDisconnectLabels[reason] });
            // Clear disconnect reason in Redux
            if (this.props.disconnectReason) {
                this.props.dispatch(setDisconnectReason());
            }
        };
        this.disconnectImmediate = (reason = 1 /* HostDisconnect */) => {
            this.disconnect(reason, true);
        };
        this.reconnect = () => {
            if (this.state.disconnectReason) {
                this.setState({ disconnectReason: undefined });
            }
            this.onLeaveScreen();
            this.onLoadScreen();
        };
        this.onServerError = (err) => {
            let content;
            switch (err.errorCode) {
                case 2 /* SignalServerDisconnect */: {
                    content = '❌ Disconnected from session server. Reconnecting…';
                    break;
                }
                case 3 /* SignalServerReconnect */: {
                    content = '✅ Reconnected to session server.';
                    break;
                }
                default:
                    console.error('Server error:', err);
                    break;
            }
            if (content) {
                this.props.dispatch(addChat({ content, timestamp: Date.now() }));
            }
        };
        this.beforeUnload = () => {
            this.disconnectImmediate();
        };
        this.host = props.match.params.lobbyId === localUserId();
    }
    get supportsNetworking() {
        return this.props.sessionMode !== 1 /* Offline */;
    }
    get lobbyId() {
        const { match } = this.props;
        return match.params.lobbyId;
    }
    async checkIsMultiTab() {
        if (!this.tabObserver) {
            this.tabObserver = new MultiTabObserver();
        }
        const isMultiTab = await this.tabObserver.getIsMultiTab();
        if (isMultiTab) {
            // Destroy while on disconnected screen
            this.tabObserver.destroy();
            this.tabObserver = undefined;
            this.disconnect(7 /* MultiTab */);
            return true;
        }
        return false;
    }
    async setupLobby() {
        let setupPromise;
        if (this.host) {
            const isMultiTab = await this.checkIsMultiTab();
            if (isMultiTab)
                return;
            setupPromise = PlatformService.get().createLobby({
                p2p: true,
                websocket: true
            });
        }
        else {
            setupPromise = PlatformService.get().joinLobby(this.lobbyId);
        }
        try {
            await setupPromise;
        }
        catch (e) {
            console.error(e);
            if (!this.mounted)
                return;
            this.onConnectionFailed(e);
            return;
        }
        if (!this.mounted)
            return;
        this.onJoinLobby(PlatformService.get().getServer());
    }
    closeLobby() {
        this.setState({ connected: false });
        if (this.server) {
            this.server.removeListener('close', this.disconnect);
            this.server = undefined;
        }
        if (this.tabObserver) {
            this.tabObserver.destroy();
            this.tabObserver = undefined;
        }
        PlatformService.get().leaveLobby(this.lobbyId);
        this.props.dispatch(NetActions.disconnect({ host: this.host }));
    }
    onJoinLobby(server) {
        this.server = server;
        this.server.once('close', this.disconnect);
        this.server.on('error', this.onServerError);
        if (this.host || this.server.connected) {
            // Server is ready
            this.onConnection();
        }
        else {
            this.onConnectionFailed();
        }
    }
    onConnection() {
        this.props.dispatch(NetActions.connect({
            server: this.server,
            host: this.host,
            replicated: AppReplicatedState
        }));
        this.setState({ connected: true });
    }
    onConnectionFailed(err) {
        let reason;
        const errorCode = err ? err.errorCode : null;
        switch (errorCode) {
            case 0 /* UnknownSession */:
            case 4 /* SignalServerSessionNotFound */:
                reason = 8 /* SessionNotFound */;
                break;
            default:
                reason = 2 /* Error */;
        }
        this.disconnect(reason);
    }
    onLoadScreen() {
        this.host = this.props.match.params.lobbyId === localUserId();
        this.props.dispatch(initLobby({ host: this.host }));
        if (!this.host || this.supportsNetworking) {
            this.setupLobby();
        }
        if (UpdateService.getInstance().isUpdateAvailable) {
            this.props.dispatch(addChat({ content: `🆕 ${t('updateAvailable')}`, timestamp: Date.now() }));
        }
    }
    onLeaveScreen() {
        this.closeLobby();
        this.props.dispatch(resetLobby({ host: this.host }));
        // always re-enable upon leaving session
        SafeBrowse.getInstance().enable();
    }
    componentWillMount() {
        this.onLoadScreen();
    }
    componentDidMount() {
        this.mounted = true;
        window.addEventListener('beforeunload', this.beforeUnload, false);
    }
    componentWillUnmount() {
        window.removeEventListener('beforeunload', this.beforeUnload, false);
        this.mounted = false;
        this.onLeaveScreen();
    }
    componentDidUpdate(prevProps) {
        if (this.lobbyId !== prevProps.match.params.lobbyId) {
            // Accepted Discord invites can join a session while currently hosting a session
            this.reconnect();
        }
        else if (this.props.sessionMode !== prevProps.sessionMode) {
            this.onSessionModeChange();
        }
    }
    onSessionModeChange() {
        if (this.supportsNetworking && !this.state.connected) {
            this.setupLobby();
        }
        else if (!this.supportsNetworking && this.state.connected) {
            this.closeLobby();
        }
    }
    render() {
        if (this.state.disconnectReason) {
            return React.createElement(Disconnect, { reason: this.state.disconnectReason, reconnect: this.reconnect });
        }
        if (!this.host && !(this.state.connected && this.props.clientAuthorized)) {
            const status = this.props.connectionStatus === "Pending" /* Pending */ ? t('waitingForHost') : undefined;
            return React.createElement(Connect, { onCancel: this.disconnectImmediate, status: status });
        }
        return React.createElement(GameLobby, { host: this.host });
    }
}
export const LobbyPage = connect(mapStateToProps)(_LobbyPage);
