import { actionCreator } from 'utils/redux';
import { getUserName, isAdmin, getUser, hasRole, getUniqueName } from 'lobby/reducers/users.helpers';
import { rpc } from 'network/middleware/rpc';
import { localUserId } from 'network';
import { setDisconnectReason } from './session';
import { translateEscaped } from 'locale';
import { validateDisplayName, validateColor, validateAvatar } from './user-validation';
import { cleanObject } from '../../utils/object';
import { addChat } from './chat';
export const addUserInvite = actionCreator('ADD_USER_INVITE');
export const answerUserInvite = actionCreator('ANSWER_USER_INVITE');
export const setUserRole = actionCreator('SET_USER_ROLE');
export const updateUser = actionCreator('UPDATE_USER');
const userJoined = (userId) => (dispatch, getState, context) => {
    if (localUserId() === userId) {
        return;
    }
    const username = getUserName(getState(), userId);
    const content = translateEscaped('userJoined', { userId, username });
    dispatch(addChat({ content, html: true, timestamp: Date.now() }));
};
export const multi_userJoined = rpc('userJoined', "multicast" /* Multicast */, userJoined);
const userLeft = (userId) => (dispatch, getState, context) => {
    const username = getUserName(getState(), userId);
    const content = translateEscaped('userLeft', { userId, username });
    dispatch(addChat({ content, html: true, timestamp: Date.now() }));
};
export const multi_userLeft = rpc('userLeft', "multicast" /* Multicast */, userLeft);
const userNameChanged = (userId, prevName) => (dispatch, getState, context) => {
    const name = getUserName(getState(), userId);
    const content = translateEscaped('userNameChanged', { userId, name, prevName });
    dispatch(addChat({ content, html: true, timestamp: Date.now() }));
};
export const multi_userNameChanged = rpc('userNameChanged', "multicast" /* Multicast */, userNameChanged);
const kickClient = (reason) => (dispatch, getState, { server }) => {
    console.debug(`Received kick with reason: '${reason}'`);
    dispatch(setDisconnectReason(reason));
    server.close();
};
export const client_kick = rpc('kickClient', "client" /* Client */, kickClient);
const kickUser = (targetId) => (dispatch, getState, context) => {
    const state = getState();
    const requesterId = context.client.id.toString();
    if (requesterId === targetId)
        return;
    if (!isAdmin(state, requesterId))
        return;
    const target = getUser(state, targetId);
    if (target) {
        dispatch(client_kick(6 /* Kicked */)(targetId));
        const conn = context.server.getClientById(targetId);
        if (conn) {
            conn.close();
        }
    }
};
export const server_kickUser = rpc('kickUser', "server" /* Server */, kickUser);
const toggleUserRole = (targetId, role) => (dispatch, getState, context) => {
    const state = getState();
    const requesterId = context.client.id.toString();
    if (requesterId === targetId)
        return;
    if (!isAdmin(state, requesterId))
        return;
    dispatch(setUserRole({
        userId: targetId,
        role,
        enabled: !hasRole(state, targetId, role)
    }));
};
export const server_toggleUserRole = rpc('toggleUserRole', "server" /* Server */, toggleUserRole);
const requestUpdateUser = (newProfile) => (dispatch, getState, context) => {
    const state = getState();
    const userId = context.client.id.toString();
    const user = getUser(state, userId);
    if (!user)
        return;
    let nextName, nextColor, nextAvatar;
    const { name, color, avatar } = newProfile;
    if (name && validateDisplayName(name) && name !== user.name) {
        nextName = getUniqueName(state, name);
    }
    if (color && validateColor(color) && color !== user.color) {
        nextColor = color;
    }
    if (avatar && validateAvatar(avatar) && avatar !== user.avatar) {
        nextAvatar = avatar;
    }
    if (nextName || nextColor || nextAvatar) {
        const nextProfile = cleanObject({ name: nextName, color: nextColor, avatar: nextAvatar });
        dispatch(updateUser({ userId, ...nextProfile }));
    }
    if (nextName) {
        dispatch(multi_userNameChanged(userId, user.name));
    }
};
export const server_updateUser = rpc('updateUser', "server" /* Server */, requestUpdateUser);
