import { CACHE_KEY_NAME } from './consts';
export const getAppName = () => '';
export const getAppVersion = () => '';
export const getClientId = () => '';
export const getLanguage = () => window.navigator.language;
export const getUserAgent = () => window.navigator.userAgent;
export const getViewport = () => `${window.innerWidth}x${window.innerHeight}`;
export const getScreenResolution = () => {
    return '';
};
export const getNow = () => Date.now();
export const getCache = () => {
    const cache = window.localStorage.getItem(CACHE_KEY_NAME);
    return cache ? JSON.parse(cache) : [];
};
export const setCache = (cache) => {
    window.localStorage.setItem(CACHE_KEY_NAME, JSON.stringify(cache));
};
export const retry = (cb, schedule) => setInterval(cb, schedule);
export const fetch = (url, options) => window.fetch(url, options);
