import { server_requestPlayPause, server_requestSeekRelative } from 'lobby/actions/mediaPlayer';
import { addVolume } from 'actions/settings';
import { throttle } from 'lodash-es';
const ActionMap = {
    Space: 0 /* PlayPause */,
    ArrowDown: 1 /* DecreaseVolume */,
    ArrowUp: 2 /* IncreaseVolume */,
    ArrowLeft: 3 /* SeekBackward */,
    ArrowRight: 4 /* SeekForward */
};
// Increase rate as key is held down longer
const getRateScalar = (durationMs) => {
    if (durationMs > 5e3)
        return 5;
    else if (durationMs > 2e3)
        return 2;
    else
        return 1;
};
const ActionHandlers = {
    [0 /* PlayPause */]: throttle(dispatch => dispatch(server_requestPlayPause()), 100, {
        trailing: false
    }),
    [1 /* DecreaseVolume */]: dispatch => dispatch(addVolume(-0.05)),
    [2 /* IncreaseVolume */]: dispatch => dispatch(addVolume(0.05)),
    [3 /* SeekBackward */]: throttle((dispatch, repeatDuration) => dispatch(server_requestSeekRelative(-5e3 * getRateScalar(repeatDuration))), 200, { trailing: false }),
    [4 /* SeekForward */]: throttle((dispatch, repeatDuration) => dispatch(server_requestSeekRelative(5e3 * getRateScalar(repeatDuration))), 200, { trailing: false })
};
const keyRepeatDuration = {};
function onDocumentKeyDown(dispatch, event) {
    // Ignore inputs entered while typing (ie. in the chat box)
    const { activeElement } = document;
    if (activeElement instanceof HTMLInputElement || activeElement instanceof HTMLTextAreaElement) {
        // Continue if we haven't typed anything yet
        if (activeElement.value.length > 0)
            return;
    }
    let hotkey = event.code;
    if (event.shiftKey)
        hotkey = `Shift+${hotkey}`;
    if (event.ctrlKey || event.metaKey)
        hotkey = `Ctrl+${hotkey}`;
    const action = ActionMap[hotkey];
    if (typeof action === 'undefined')
        return;
    event.preventDefault();
    event.stopImmediatePropagation();
    if (!event.repeat || !keyRepeatDuration[action]) {
        keyRepeatDuration[action] = Date.now();
    }
    const repeatDuration = Date.now() - (keyRepeatDuration[action] || Date.now());
    const handler = ActionHandlers[action];
    handler(dispatch, repeatDuration);
}
let keyDownHandler;
export const registerMediaShortcuts = () => {
    return (dispatch, getState) => {
        keyDownHandler = onDocumentKeyDown.bind(null, dispatch);
        document.addEventListener('keydown', keyDownHandler, false);
    };
};
export const unregisterMediaShortcuts = () => {
    return () => {
        document.removeEventListener('keydown', keyDownHandler, false);
        keyDownHandler = undefined;
    };
};
