import React, { Component } from 'react';
import { connect } from 'react-redux';
import styles from './Connect.css';
import { TitleBar } from '../TitleBar';
import { MenuButton } from '../menu/MenuButton';
import { Spinner } from '../common/spinner';
import { t } from 'locale';
class _Connect extends Component {
    render() {
        return (React.createElement("div", { className: styles.container },
            React.createElement(TitleBar, { className: styles.titlebar }),
            React.createElement("p", { className: styles.info },
                React.createElement(Spinner, null),
                `${this.props.status || t('connecting')}…`),
            React.createElement(MenuButton, { icon: "x", size: "medium", onClick: () => this.props.onCancel() }, t('cancel'))));
    }
}
export const Connect = connect()(_Connect);
