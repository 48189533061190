import * as React from 'react';
import cx from 'classnames';
import { Chat } from 'components/chat';
import styles from './Sidebar.css';
import { UserList } from 'components/lobby/UserList';
import { dispatchExtensionMessage } from 'utils/extension';
import { useDispatch } from 'react-redux';
import { setLobbyModal } from 'actions/ui';
import { MediaList } from 'components/lobby/MediaList';
import { PanelHeader } from 'components/lobby/PanelHeader';
import { ChatLayoutButton } from 'components/chat/ChatLayoutButton';
import { t } from 'locale';
export const Sidebar = ({ className, popup }) => {
    const dispatch = useDispatch();
    return (React.createElement("div", { className: cx(className, styles.container) },
        React.createElement(UserList, { className: styles.list, onInvite: () => {
                dispatchExtensionMessage('metastream-focus');
                dispatch(setLobbyModal("session-settings" /* SessionSettings */));
            }, collapsible: true }),
        React.createElement(MediaList, { className: styles.list, onOpenMediaBrowser: () => {
                dispatchExtensionMessage('metastream-focus');
                dispatch(setLobbyModal("browser" /* Browser */));
            }, onShowInfo: () => {
                dispatchExtensionMessage('metastream-focus');
                dispatch(setLobbyModal("media-info" /* MediaInfo */));
            }, collapsible: true }),
        React.createElement(PanelHeader, { title: t('chat'), action: popup ? null : React.createElement(ChatLayoutButton, null) }),
        React.createElement(Chat, { className: styles.chat, showDockOption: false })));
};
