import { localUser, localUserId } from 'network';
import { NetActions } from 'network/actions';
import { isType } from 'utils/redux';
import { initLobby } from '../../lobby/actions/common';
let RPC_UID = 1;
const remoteResultListeners = {};
const addResultListener = (id, cb) => {
    const set = remoteResultListeners[id] || (remoteResultListeners[id] = new Set());
    set.add(cb);
};
const removeResultListener = (id, cb) => {
    const set = remoteResultListeners[id];
    if (set && set.has(cb)) {
        set.delete(cb);
    }
};
const dispatchResultListeners = (id, result) => {
    console.debug(`[RPC][${id}] Received result`, result);
    const set = remoteResultListeners[id];
    if (set) {
        Array.from(set).forEach(cb => cb(result));
        remoteResultListeners[id] = undefined;
    }
};
const RpcReduxActionTypes = {
    DISPATCH: '@@rpc/DISPATCH'
};
const isRpcThunk = (arg) => typeof arg === 'function';
const RPC_HEADER = new Buffer('RPC');
export const netRpcMiddleware = (rpcOpts) => {
    return store => {
        const { dispatch, getState } = store;
        const { extra } = rpcOpts;
        let server, host;
        const init = (options) => {
            console.debug('[RPC] Init middleware', options);
            server = options.server || null;
            host = options.host;
            if (server) {
                // Listen for RPCs and dispatch them
                server.on('data', receive);
            }
        };
        const destroy = () => {
            server = null;
        };
        const receive = async (client, data) => {
            if (!data.slice(0, RPC_HEADER.length).equals(RPC_HEADER)) {
                return;
            }
            const jsonStr = data.toString('utf-8', RPC_HEADER.length);
            const json = JSON.parse(jsonStr);
            if (json.type === 0 /* Exec */) {
                const payload = {
                    id: json.id,
                    name: json.name,
                    args: json.args
                };
                const action = {
                    type: RpcReduxActionTypes.DISPATCH,
                    payload
                };
                console.debug(`[RPC][${client.shortId}][${json.id}] ${json.name}`, ...json.args);
                let returnValue;
                try {
                    returnValue = await dispatchRpc(action, client);
                }
                catch (e) {
                    console.error(e);
                    return;
                }
                if (typeof returnValue !== 'undefined') {
                    sendRpcResult(action.payload.id, returnValue, client);
                }
            }
            else if (json.type === 1 /* Result */) {
                dispatchResultListeners(json.id, json.result);
            }
        };
        /** Send RPC to recipients. */
        const sendRpc = (action) => {
            if (!server)
                return;
            const { payload, clients } = action;
            const rpc = getRpc(payload.name);
            const msg = { type: 0 /* Exec */, ...payload };
            const json = JSON.stringify(msg);
            const buf = new Buffer(RPC_HEADER + json);
            switch (rpc.realm) {
                case "server" /* Server */:
                    server.sendToHost(buf);
                    break;
                case "multicast" /* Multicast */:
                    server.send(buf);
                    break;
                case "client" /* Client */:
                    clients.forEach(clientId => {
                        if (clientId === localUserId()) {
                            dispatchRpc(action, localUser());
                        }
                        else {
                            server.sendTo(clientId, buf);
                        }
                    });
                    break;
            }
        };
        const sendRpcResult = (id, result, client) => {
            const payload = { type: 1 /* Result */, id, result };
            const json = JSON.stringify(payload);
            const buf = new Buffer(RPC_HEADER + json);
            if (client.connected)
                client.send(buf);
        };
        /** Dispatch RPC on local Redux store. */
        const dispatchRpc = (action, client) => {
            const result = execRpc(action, client);
            if (isRpcThunk(result)) {
                // TODO: update IRpcThunkContext to reflect possibly undefined server
                // when user is in offline session
                const context = { client, host, server: server, ...extra };
                return result(dispatch, getState, context);
            }
            else if (typeof result === 'object') {
                dispatch(result);
            }
            else if (typeof result === 'string') {
                console.error(result);
            }
        };
        return next => (action) => {
            if (isType(action, initLobby)) {
                host = action.payload.host;
            }
            else if (isType(action, NetActions.connect)) {
                init(action.payload);
                return next(action);
            }
            else if (isType(action, NetActions.disconnect)) {
                destroy();
                return next(action);
            }
            // TODO: check for RPC special prop
            if (action.type !== RpcReduxActionTypes.DISPATCH) {
                return next(action);
            }
            const rpcName = action.payload.name;
            const rpc = getRpc(rpcName);
            if (!rpc) {
                throw new Error(`[RPC] Attempted to dispatch unknown RPC '${rpcName}'`);
            }
            // TODO: send result back if received from peer
            // TODO: return proxy promise when remote dispatched
            let asyncResult = false;
            // https://docs.unrealengine.com/latest/INT/Gameplay/Networking/Actors/RPCs/#rpcinvokedfromtheserver
            switch (rpc.realm) {
                case "server" /* Server */:
                    // SERVER: dispatch
                    // CLIENT: send to server
                    if (host) {
                        return dispatchRpc(action, localUser());
                    }
                    else {
                        sendRpc(action);
                        asyncResult = true;
                    }
                    break;
                case "client" /* Client */:
                    // SERVER: dispatch
                    // CLIENT: throw
                    if (host) {
                        sendRpc(action);
                        asyncResult = true;
                    }
                    else {
                        throw new Error(`Client RPC '${action.type}' dispatched on client`);
                    }
                    break;
                case "multicast" /* Multicast */:
                    // SERVER: broadcast and dispatch
                    // CLIENT: dispatch
                    if (host) {
                        sendRpc(action);
                    }
                    dispatchRpc(action, localUser());
                    break;
            }
            if (asyncResult) {
                return {
                    then(resolve, reject) {
                        let timeoutId = -1;
                        const cb = (...args) => {
                            clearTimeout(timeoutId);
                            resolve(...args);
                        };
                        addResultListener(action.payload.id, cb);
                        timeoutId = setTimeout(() => {
                            removeResultListener(action.payload.id, cb);
                            reject('Timeout');
                        }, 5000);
                    }
                };
            }
            return true;
        };
    };
};
const rpcMap = {};
const getRpc = (name) => rpcMap[name];
const execRpc = ({ payload }, client) => {
    const { name, args } = payload;
    if (!rpcMap.hasOwnProperty(name)) {
        throw new Error(`Exec unknown RPC "${name}"`);
    }
    const opts = rpcMap[name];
    if (!client.isAuthed() && !opts.allowUnauthed) {
        return `Client not authorized to dispatch RPC "${name}"`;
    }
    if (opts.validate && !opts.validate(args)) {
        return `Invalidate arguments for RPC "${name}"`;
    }
    return opts.action.apply(null, args);
};
// prettier-ignore
export function rpc(name, realm, action, opts) {
    if (name === 'action') {
        throw new Error('Invalid RPC action name, must define function name.');
    }
    // Register global RPC handler
    if (rpcMap.hasOwnProperty(name)) {
        console.warn(`RPC action name ("${name}") collides with existing action, overriding...`);
    }
    rpcMap[name] = { ...opts, realm, action };
    // Return Redux action creator;
    // intercepted by redux-rpc middleware
    let proxy;
    if (realm === "client" /* Client */) {
        proxy = (...args) => (...clients) => ({
            type: RpcReduxActionTypes.DISPATCH,
            clients,
            payload: {
                id: RPC_UID++,
                name: name,
                args: args
            },
        });
    }
    else {
        proxy = (...args) => ({
            type: RpcReduxActionTypes.DISPATCH,
            payload: {
                id: RPC_UID++,
                name: name,
                args: args
            }
        });
    }
    return proxy;
}
