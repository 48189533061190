import React, { PureComponent } from 'react';
import cx from 'classnames';
import { Messages } from './Messages';
import { ChatForm } from './ChatForm';
import styles from './Chat.css';
import { connect } from 'react-redux';
import { sendChat, notifyTyping } from 'lobby/actions/chat';
import { UserTyping } from './UserTyping';
import { throttle } from 'lodash-es';
import { TYPING_DURATION } from '../../lobby/reducers/chat.helpers';
import { ChatLayoutButton } from './ChatLayoutButton';
const CSS_PROP_CHAT_FADE_DELAY = '--chat-fade-delay';
let ChatComponent = /** @class */ (() => {
    class ChatComponent extends PureComponent {
        constructor() {
            super(...arguments);
            this.form = null;
            this.containerElement = null;
            this.messagesRef = null;
            this.state = {
                filteredMessages: []
            };
            this.onFocus = () => {
                this.setState({ focused: true }, () => {
                    this.filterMessages(this.props.messages);
                });
            };
            this.onBlur = () => {
                this.setState({ focused: false }, () => {
                    this.filterMessages(this.props.messages);
                    this.scrollToBottom();
                });
            };
            this.onSend = (message) => {
                this.props.sendMessage(message);
                this.scrollToBottom();
                // allow immediately sending typing notification after sending
                const notify = this.props.notifyTyping;
                notify.cancel();
            };
            this.onKeyPress = (event) => {
                switch (event.key) {
                    case 'Enter':
                        if (!this.state.focused && this.form) {
                            event.preventDefault();
                            this.form.focus();
                        }
                        break;
                }
            };
            this.onKeyDown = (event) => {
                switch (event.key) {
                    case 'Enter':
                        if (!this.state.focused) {
                            // Prevent activity monitor from revealing UI
                            event.stopImmediatePropagation();
                        }
                        break;
                    case 'Escape':
                        if (this.state.focused && this.form) {
                            event.stopImmediatePropagation();
                            event.preventDefault();
                            this.form.dismiss();
                        }
                        break;
                }
            };
        }
        componentDidMount() {
            this.filterMessages(this.props.messages);
            this.setupListeners(!this.props.disabled);
            this.scrollToBottom();
            if (this.containerElement) {
                this.containerElement.style.setProperty(CSS_PROP_CHAT_FADE_DELAY, `${this.props.messageFadeDelay}ms`);
            }
            if (this.props.theRef) {
                this.props.theRef(this);
            }
        }
        componentWillUnmount() {
            if (this.props.theRef) {
                this.props.theRef(null);
            }
            this.setupListeners(false);
        }
        componentWillReceiveProps(nextProps) {
            if (this.props.messages !== nextProps.messages) {
                this.filterMessages(nextProps.messages);
            }
        }
        componentDidUpdate(prevProps) {
            if (this.props.disabled !== prevProps.disabled) {
                this.setupListeners(!this.props.disabled);
                if (this.state.focused && this.props.disabled) {
                    this.onBlur();
                }
            }
        }
        filterMessages(messages) {
            // Minimize number of chat messages to render while chat isn't focused.
            const numMessages = messages.length;
            const minMessages = 10;
            const cutoff = numMessages - minMessages - 1;
            const chatFadeDelay = this.props.messageFadeDelay;
            const filteredMessages = this.state.focused || !this.props.fade
                ? messages
                : messages.filter((msg, idx) => {
                    if (idx > cutoff)
                        return true;
                    const dt = Date.now() - msg.timestamp;
                    return dt <= chatFadeDelay;
                });
            this.setState({ filteredMessages });
        }
        setupListeners(enabled) {
            if (enabled) {
                document.addEventListener('keypress', this.onKeyPress, true);
                document.addEventListener('keydown', this.onKeyDown, true);
            }
            else {
                document.removeEventListener('keypress', this.onKeyPress, true);
                document.removeEventListener('keydown', this.onKeyDown, true);
            }
        }
        render() {
            const fade = !!this.props.fade;
            return (React.createElement("div", { ref: e => (this.containerElement = e), className: cx(this.props.className, styles.container, {
                    [styles.focused]: this.state.focused,
                    [styles.fade]: fade
                }) },
                React.createElement("div", { className: styles.wrapper },
                    React.createElement("div", { className: fade ? styles.fadeBackground : styles.staticBackground }),
                    React.createElement("div", { className: styles.foreground },
                        React.createElement(Messages, { ref: e => {
                                this.messagesRef = e;
                            }, messages: this.state.filteredMessages, showTimestamp: this.props.showTimestamp }),
                        React.createElement(ChatForm, { ref: e => {
                                this.form = e;
                            }, send: this.onSend, onFocus: this.onFocus, onBlur: this.onBlur, onTyping: this.props.notifyTyping, showHint: !!this.props.showHint, blurOnSubmit: !!this.props.fade },
                            React.createElement(UserTyping, null)),
                        this.props.showDockOption && React.createElement(ChatLayoutButton, { className: styles.btnLayout })))));
        }
        scrollToBottom() {
            if (this.messagesRef) {
                this.messagesRef.scrollToBottom();
            }
        }
        toggle() {
            if (!this.form)
                return;
            if (this.state.focused) {
                this.form.dismiss();
            }
            else {
                this.form.focus();
            }
        }
    }
    ChatComponent.defaultProps = {
        messageFadeDelay: 10000,
        showDockOption: true
    };
    return ChatComponent;
})();
export { ChatComponent };
export const Chat = connect((state) => {
    return {
        messages: state.chat.messages,
        showTimestamp: state.settings.chatTimestamp
    };
}, (dispatch) => ({
    sendMessage: (text) => dispatch(sendChat(text)),
    notifyTyping: throttle(() => dispatch(notifyTyping()), TYPING_DURATION - 500, {
        trailing: false
    })
}))(ChatComponent);
