import React from 'react';
import cx from 'classnames';
import { Icon } from 'components/Icon';
import styles from './MenuButton.css';
import { Link } from 'react-router-dom';
export const MenuButton = ({ children, className, component, icon, size, to, ...rest }) => {
    const isLink = Boolean(to);
    const componentType = component || (isLink && Link) || 'button';
    return React.createElement(componentType, {
        className: cx(className, styles[size || 'large']),
        type: isLink ? undefined : 'button',
        to,
        ...rest
    }, React.createElement(React.Fragment, null,
        icon && React.createElement(Icon, { name: icon }),
        React.createElement("span", null, children)));
};
