import shortid from 'shortid';
import { actionCreator } from 'utils/redux';
import { localUserId } from '../../network';
import { cleanObject } from 'utils/object';
export const setSessionData = actionCreator('SET_SESSION_DATA');
export const setDisconnectReason = actionCreator('SET_DISCONNECT_REASON');
export const setAuthorized = actionCreator('SET_AUTHORIZED');
export const setConnectionStatus = actionCreator('SET_CONNECTION_STATUS');
export const initHostSession = () => {
    return (dispatch, getState) => {
        const state = getState();
        const sessionData = cleanObject({
            id: localUserId(),
            secret: btoa(shortid()),
            maxUsers: state.settings.maxUsers
        });
        dispatch(setSessionData(sessionData));
    };
};
