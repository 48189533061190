import { EventEmitter } from 'events';
let instance;
export class AccountService extends EventEmitter {
    static get() {
        return instance || (instance = new AccountService());
    }
    get firebase() {
        if (!this._firebase) {
            throw new Error('Firebase is not initialized');
        }
        return this._firebase;
    }
    get tier() {
        return (this._userData && this._userData.tier) || 0 /* None */;
    }
    async initFirebase() {
        if (!process.env.FIREBASE_CONFIG) {
            throw new Error('Firebase not configured');
        }
        const firebase = await import(/* webpackChunkName: "firebase-app" */ 'firebase/app');
        await Promise.all([
            import(/* webpackChunkName: "firebase-auth" */ 'firebase/auth'),
            import(/* webpackChunkName: "firebase-firestore" */ 'firebase/firestore')
        ]);
        if (!this._firebase) {
            const firebaseConfig = JSON.parse(atob(process.env.FIREBASE_CONFIG));
            firebase.initializeApp(firebaseConfig);
            this._firebase = firebase;
        }
        if (process.env.NODE_ENV === 'development') {
            ;
            window.app.firebase = firebase;
        }
        return firebase;
    }
    async fetchUserDocument() {
        const userRecord = this.firebase.auth().currentUser;
        const userDocRef = await this.firebase
            .firestore()
            .collection('users')
            .doc(userRecord.uid)
            .get();
        const userData = userDocRef.data();
        this._userData = userData;
        this.emit('change');
        return userData;
    }
    async checkLogin() {
        const didLogin = Boolean(localStorage.getItem("login" /* Login */));
        if (didLogin) {
            const firebase = await this.initFirebase(); // init
            const user = await new Promise(resolve => {
                const unsubscribe = firebase.auth().onAuthStateChanged(user => {
                    unsubscribe();
                    resolve(user);
                });
            });
            if (process.env.NODE_ENV === 'development') {
                console.debug('Firebase user', user);
            }
            if (user) {
                await this.fetchUserDocument();
            }
        }
    }
    async promptLogin() {
        const customToken = await new Promise((resolve, reject) => {
            const params = {
                response_type: 'code',
                client_id: process.env.PATREON_CLIENT_ID,
                redirect_uri: process.env.PATREON_REDIRECT_URI,
                scope: ['identity', 'identity[email]'].join(' ')
            };
            const url = new URL('https://www.patreon.com/oauth2/authorize');
            for (const key of Object.keys(params)) {
                url.searchParams.set(key, params[key] + '');
            }
            const win = window.open(url.href, 'MetastreamAuth');
            if (!win) {
                reject();
                return;
            }
            window.addEventListener('message', function onAuthMessage(event) {
                if (event.origin !== location.origin)
                    return;
                if (win.closed) {
                    window.removeEventListener('message', onAuthMessage);
                    reject();
                    return;
                }
                const action = typeof event.data === 'object' && event.data;
                if (action && action.type === 'auth-token') {
                    win.close();
                    window.removeEventListener('message', onAuthMessage);
                    if (action.error) {
                        reject(new Error(action.error));
                    }
                    else {
                        resolve(action.token);
                    }
                }
            });
        });
        await this.initFirebase();
        const userCred = await this.firebase.auth().signInWithCustomToken(customToken);
        if (process.env.NODE_ENV === 'development') {
            console.log(userCred);
        }
        // Remember that the user logged in so we can restore on refresh
        try {
            localStorage.setItem("login" /* Login */, '1');
        }
        catch { }
        await this.fetchUserDocument();
    }
    async logout() {
        if (!this._firebase)
            return;
        await this.firebase.auth().signOut();
        try {
            localStorage.removeItem("login" /* Login */);
        }
        catch { }
        this._userData = undefined;
        this.emit('change');
    }
}
