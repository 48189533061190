import React, { PureComponent } from 'react';
import { formatMs, formatShortMs } from 'utils/time';
import { Ticker } from 'components/Ticker';
const HHMMSS_PLACEHOLDER = '0:00:00';
const MMSS_PLACEHOLDER = '00:00';
export class Time extends PureComponent {
    constructor() {
        super(...arguments);
        this.state = { time: 0 };
        this.tick = () => {
            const time = Date.now() - this.props.time;
            this.setState({ time });
        };
    }
    render() {
        const time = this.props.realTime ? this.state.time : this.props.time;
        const timeStr = this.props.leading ? formatShortMs(time) : formatMs(time);
        let lead;
        if (this.props.leading) {
            const placeholder = this.props.leadingHours ? HHMMSS_PLACEHOLDER : MMSS_PLACEHOLDER;
            const numLeadingChars = Math.max(0, placeholder.length - timeStr.length);
            lead = placeholder.substr(0, numLeadingChars);
        }
        return (React.createElement("span", { className: this.props.className },
            lead && React.createElement("span", { className: "lead" }, lead),
            timeStr,
            this.props.realTime && React.createElement(Ticker, { fps: 1, onTick: this.tick })));
    }
}
