import { getDefaultInitParams, prepareItems, getBatches, sendBatches, resolveParam } from './helpers';
import { getNow, getCache, setCache, retry } from './side-effects';
import { BATCH_SIZE, RETRY } from './consts';
export class Analytics {
    constructor(trackId, params = {}) {
        this.protocolVersion = '';
        this.clientId = '';
        this.userId = '';
        this.appName = '';
        this.appVersion = '';
        this.language = '';
        this.userAgent = '';
        this.viewport = '';
        this.screenResolution = '';
        this.send = async (hitType, additionalParams = {}) => {
            const now = getNow();
            const params = hitType ? this.getParams(hitType, additionalParams, now) : null;
            const cache = getCache();
            const items = prepareItems([...cache, params].filter(Boolean), this.trackId, now);
            if (items.length === 0)
                return;
            const batches = getBatches(items, BATCH_SIZE);
            const failedItems = await sendBatches(batches);
            setCache(failedItems);
        };
        this.trackId = trackId;
        const initParams = { ...getDefaultInitParams(), ...params };
        Object.keys(initParams).forEach(key => (this[key] = initParams[key]));
        retry(this.send, RETRY);
    }
    getParams(hitType, additionalParams = {}, time) {
        return {
            __timestamp: time,
            t: hitType,
            v: resolveParam(this.protocolVersion),
            tid: resolveParam(this.trackId),
            cid: resolveParam(this.clientId),
            an: resolveParam(this.appName),
            av: resolveParam(this.appVersion),
            ul: resolveParam(this.language),
            ua: resolveParam(this.userAgent),
            vp: resolveParam(this.viewport),
            sr: resolveParam(this.screenResolution),
            ...additionalParams
        };
    }
}
export default Analytics;
