import { createStructuredSelector } from 'reselect';
import { isType } from 'utils/redux';
import { clamp } from 'utils/math';
import { setVolume, setMute, setUsername, setColor, setSetting, addVolume } from 'actions/settings';
import { USERNAME_MAX_LEN, COLOR_LEN, DEFAULT_COLOR, DEFAULT_USERNAME, USERNAME_MIN_LEN } from 'constants/settings';
import { stripEmoji } from 'utils/string';
import { DEFAULT_LANGUAGE } from 'locale';
import { AccountService } from 'account/account';
import { localUserId } from 'network';
const initialState = {
    mute: false,
    volume: 0.75,
    allowTracking: false,
    sessionMode: 2 /* Private */,
    language: DEFAULT_LANGUAGE,
    chatLocation: 1 /* DockRight */,
    chatTimestamp: false,
    autoFullscreen: true,
    theaterMode: false,
    safeBrowse: true
};
export const settings = (state = initialState, action) => {
    if (isType(action, setSetting)) {
        const { key, value } = action.payload;
        const currentValue = state[key];
        const newValue = typeof value === 'function' ? value(currentValue) : value;
        return { ...state, [key]: newValue };
    }
    if (isType(action, setVolume)) {
        return {
            ...state,
            mute: false,
            volume: clamp(action.payload, 0, 1)
        };
    }
    else if (isType(action, addVolume)) {
        return {
            ...state,
            mute: false,
            volume: clamp(state.volume + action.payload, 0, 1)
        };
    }
    else if (isType(action, setMute)) {
        return {
            ...state,
            mute: action.payload
        };
    }
    if (isType(action, setUsername)) {
        let username = action.payload && stripEmoji(action.payload.trim()).substr(0, USERNAME_MAX_LEN);
        if (typeof username === 'undefined' || username.length >= USERNAME_MIN_LEN) {
            return { ...state, username };
        }
    }
    else if (isType(action, setColor)) {
        const color = action.payload.substr(0, COLOR_LEN);
        return { ...state, color };
    }
    return state;
};
export const getLocalColor = (state) => (AccountService.get().tier > 0 /* None */ && state.settings.color) || DEFAULT_COLOR;
export const getLocalUsername = (state) => state.settings.username || DEFAULT_USERNAME;
export const getLocalSessionMode = (state) => state.settings.sessionMode || DEFAULT_COLOR;
export const getLocalAvatar = (state) => {
    const { avatar } = state.settings;
    return avatar || `uid:${localUserId()}`;
};
/** Gets a subset of settings to pass to player extension */
export const getPlayerSettings = createStructuredSelector({
    autoFullscreen: state => state.settings.autoFullscreen,
    theaterMode: state => state.settings.theaterMode
});
