import React, { Component } from 'react';
import cx from 'classnames';
import styles from './CuePoint.css';
export class CuePoint extends Component {
    render() {
        // TODO: Reveal tooltip on hover
        return (React.createElement("span", { className: cx(styles.container, {
                active: this.props.active
            }), style: this.props.style, "data-title": this.props.value.label }));
    }
}
