import React, { Component } from 'react';
import styles from './Disconnect.css';
import { TitleBar } from '../TitleBar';
import { MenuButton } from '../menu/MenuButton';
import { Icon } from '../Icon';
import { NetworkDisconnectMessages } from 'constants/network';
import { ExternalLink } from '../common/link';
import { Trans, withNamespaces } from 'react-i18next';
import { UpdateService } from 'services/updater';
class _Disconnect extends Component {
    componentDidMount() {
        if (this.props.reason === 4 /* VersionMismatch */) {
            UpdateService.getInstance().checkForUpdate();
        }
    }
    render() {
        const { t, reason, reconnect } = this.props;
        const reasonKey = NetworkDisconnectMessages[reason];
        const msg = t(reasonKey) || reasonKey;
        // prettier-ignore
        return (React.createElement("div", { className: styles.container },
            React.createElement(TitleBar, { className: styles.titlebar }),
            React.createElement("h1", { className: styles.header }, t('disconnected')),
            React.createElement("p", { className: styles.info },
                React.createElement(Icon, { name: "info" }),
                React.createElement("span", { id: "disconnect_reason" },
                    msg,
                    reason === 2 /* Error */ && React.createElement(React.Fragment, null, "\u00A0"),
                    reason === 2 /* Error */ && (React.createElement(Trans, { i18nKey: "networkTroubleshootingHelp" },
                        "See ",
                        React.createElement(ExternalLink, { href: "https://github.com/samuelmaddock/metastream/wiki/Network-Troubleshooting" }, "Network Troubleshooting guide"),
                        " for help.")))),
            React.createElement("div", { className: styles.buttonrow },
                React.createElement(MenuButton, { to: "/", size: "medium" }, t('ok')),
                React.createElement(MenuButton, { size: "medium", onClick: reconnect }, t('retry')))));
    }
}
export const Disconnect = withNamespaces()(_Disconnect);
