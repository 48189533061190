import { waitEvent } from '@metastream/signal-server/lib/util';
import { NetServer, NetUniqueId } from 'network';
import { isP2PHash } from 'utils/network';
import { initIdentity } from './identity';
import { WebRTCPeerCoordinator } from './rtc-coordinator';
import { NETWORK_TIMEOUT } from 'constants/network';
import { NetworkError } from '../../network/error';
export class WebPlatform {
    constructor() {
        this.ready = initIdentity().then(keyPair => {
            this.id = new NetUniqueId(keyPair.publicKey, keyPair.privateKey);
        });
    }
    getServer() {
        return this.server;
    }
    async createLobby(opts) {
        const coordinators = [];
        if (opts.p2p) {
            coordinators.push(new WebRTCPeerCoordinator({ host: true }));
        }
        this.server = new NetServer({ isHost: true, coordinators });
    }
    async joinP2PLobby(hash) {
        ga('event', { ec: 'session', ea: 'connect', el: 'p2p' });
        const coordinator = new WebRTCPeerCoordinator({ host: false, hostId: hash });
        this.server = new NetServer({
            isHost: false,
            coordinators: [coordinator]
        });
        const promises = [
            waitEvent(this.server, 'connect', NETWORK_TIMEOUT),
            waitEvent(this.server, 'error', NETWORK_TIMEOUT)
        ];
        try {
            const [result] = await Promise.race(promises);
            if (result instanceof Error)
                throw result;
        }
        catch (e) {
            promises.forEach(p => p.cancel());
            if (this.server) {
                this.server.close();
                this.server = undefined;
            }
            throw e;
        }
    }
    async joinLobby(lobbyId) {
        if (isP2PHash(lobbyId)) {
            await this.joinP2PLobby(lobbyId);
        }
        else {
            throw new NetworkError(0 /* UnknownSession */);
        }
    }
    leaveLobby(id) {
        if (this.server) {
            this.server.close();
            this.server = undefined;
        }
        return true;
    }
    getLocalId() {
        return this.id;
    }
}
