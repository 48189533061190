import React, { Component } from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';
import styles from './SessionSettings.css';
import { getHostId, isHost, getHost, getNumUsers } from 'lobby/reducers/users.helpers';
import { USERS_MAX, MAX_USERS_INFINITE } from 'constants/settings';
import { t } from 'locale';
import { HighlightButton } from '../../common/button';
import { setSetting } from 'actions/settings';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Dropdown } from 'components/settings/controls';
import { setSessionData } from 'lobby/actions/session';
import { ClipboardTextInput } from 'components/common/input';
class SessionSettings extends Component {
    constructor() {
        super(...arguments);
        this.state = {};
    }
    render() {
        return (React.createElement("div", { className: cx(styles.container, this.props.className) },
            this.renderSessionLink(),
            this.props.isHost && (React.createElement(React.Fragment, null,
                this.renderSessionMode(),
                this.renderSessionModeDialog(),
                this.renderUserOpts()))));
    }
    renderSessionLink() {
        return (React.createElement(React.Fragment, null,
            React.createElement("p", null, t('shareSessionUrl')),
            React.createElement(ClipboardTextInput, { className: styles.idContainer, inputClassName: styles.idText, defaultValue: location.href, disabled: true, style: { marginBottom: '1rem' } })));
    }
    renderSessionMode() {
        const modes = [
            {
                mode: 0 /* Public */,
                label: t('public'),
                desc: t('sessionModePublicDescription'),
                icon: 'users',
                onClick: (mode) => dispatch(setSetting('sessionMode', mode))
            },
            {
                mode: 2 /* Private */,
                label: t('private'),
                desc: t('sessionModePrivateDescription'),
                icon: 'user-check',
                onClick: (mode) => dispatch(setSetting('sessionMode', mode))
            },
            {
                mode: 1 /* Offline */,
                label: t('offline'),
                desc: t('sessionModeOfflineDescription'),
                icon: 'user',
                onClick: (mode) => {
                    if (this.props.numUsers > 1) {
                        this.setState({ sessionDialogOpen: true, selectedMode: mode });
                    }
                    else {
                        dispatch(setSetting('sessionMode', mode));
                    }
                }
            }
        ];
        const dispatch = this.props.dispatch;
        const { sessionMode } = this.props.settings;
        const previewMode = typeof this.state.previewMode === 'number' ? this.state.previewMode : sessionMode;
        const selectedMode = modes.find(mode => mode.mode === previewMode);
        return (React.createElement("p", { className: styles.sessionMode },
            React.createElement("label", { className: styles.label }, t('sessionMode')),
            React.createElement("div", { className: styles.sessionModes }, modes.map(mode => (React.createElement(HighlightButton, { key: mode.label, icon: mode.icon, size: "large", highlight: sessionMode === mode.mode, onClick: () => mode.onClick(mode.mode), onMouseEnter: () => this.setState({ previewMode: mode.mode }), onMouseLeave: () => this.setState({ previewMode: undefined }) }, mode.label)))),
            selectedMode && React.createElement("label", { className: styles.descLabel }, selectedMode.desc)));
    }
    renderSessionModeDialog() {
        const onClose = (accept) => {
            if (accept) {
                this.props.dispatch(setSetting('sessionMode', this.state.selectedMode));
            }
            this.setState({ sessionDialogOpen: false, selectedMode: undefined });
        };
        return (React.createElement(Dialog, { open: !!this.state.sessionDialogOpen, onClose: () => onClose(), "aria-labelledby": "alert-dialog-title", "aria-describedby": "alert-dialog-description" },
            React.createElement(DialogTitle, null, t('endSessionTitle')),
            React.createElement(DialogContent, null,
                React.createElement(DialogContentText, null, t('endSessionDescription'))),
            React.createElement(DialogActions, null,
                React.createElement(Button, { onClick: () => onClose(false) }, t('cancel')),
                React.createElement(Button, { onClick: () => onClose(true), color: "primary", autoFocus: true }, t('ok')))));
    }
    renderUserOpts() {
        const dispatch = this.props.dispatch;
        const userOpts = [];
        const updateMaxUsers = (event) => {
            const value = parseInt(event.currentTarget.value, 10);
            dispatch(setSessionData({ maxUsers: value }));
            dispatch(setSetting('maxUsers', value));
        };
        const addOption = (value, label) => {
            const opt = (React.createElement("option", { key: value, value: value, selected: value === this.props.maxUsers }, label || `${value}`));
            userOpts.push(opt);
        };
        for (let i = 2; i <= USERS_MAX; i = i << 1) {
            addOption(i);
        }
        addOption(MAX_USERS_INFINITE, t('unlimited'));
        return (React.createElement("p", null,
            React.createElement("label", { htmlFor: "maxusers", className: styles.label }, t('maxUsers')),
            React.createElement(Dropdown, { id: "maxusers", theme: "secondary", onChange: updateMaxUsers, disabled: this.props.settings.sessionMode === 1 /* Offline */ }, userOpts)));
    }
}
export default connect((state) => {
    return {
        isHost: isHost(state),
        hostId: getHostId(state),
        hostName: getHost(state).name,
        numUsers: getNumUsers(state),
        maxUsers: state.session.maxUsers,
        settings: state.settings
    };
})(SessionSettings);
