export default {
    add: '추가',
    addMedia: '미디어 추가',
    admin: '관리자',
    advanced: '고급',
    allow: '허용',
    allowTracking: '앱 사용 정보 보내기',
    allowTrackingDesc: '익명 사용 정보는 개발자가 앱이 어떻게 사용되는지 알기 위해 전송됩니다.',
    appearance: '외관',
    autoFullscreen: '자동 전체화면',
    autoFullscreenDesc: '영상을 창 크기에 맞춰 확대/축소 합니다.',
    avatar: '아바타',
    avatarCredit: '<0>선택한 아바타 아트는</0> <1>{{artist}}</1> <0>가 만들었습니다.</0>',
    cancel: '취소',
    changelog: '변경 내역',
    chat: '채팅',
    chatColor: '닉네임 색상',
    chatPlaceholder: '메시지 또는 주소',
    chatRevealHint: '<1></1>를 눌러 대화를 표시합니다.',
    chatNewMessage: '새로운 메시지가 등록되었습니다!',
    chooseDisplayName: '사용자 닉네임을 선택하세요.',
    connecting: '연결 중',
    continue: '계속',
    copy: '복사',
    copyLink: '링크 복사',
    debug: '디버그',
    developerMode: '개발자 모드',
    developerModeDesc: '웹 페이지 검사를 위해 개발자 도구를 표시합니다.',
    directIP: 'IP 직접 연결',
    disallow: '허용하지 않음',
    disconnect: '연결 해제',
    disconnected: '연결 해제됨',
    displayName: '닉네임',
    dj: 'DJ',
    donate: '후원하기',
    donators: '후원자들',
    duplicate: '복제',
    endSessionTitle: '공개 세션을 종료할까요?',
    endSessionDescription: '비공개 세션으로 변경하면 모든 참가자의 연결이 끊깁니다.',
    endSessionModalDescription: '모든 참가자의 연결이 끊길 것 입니다.',
    endSessionButton: '세션 끝내기',
    enterJoinDest: 'Metastream 세션 URL을 입력하세요.',
    findSession: '세션 찾기',
    findSessionDescription: 'Metastream Discord 커뮤니티에서 <1>#sessions</1> 채널에 참여하여 다른 유저의 세션을 찾으세요. 아래의 Discord 로고를 클릭하여 참여합니다.',
    friendCode: '친구 코드',
    getStarted: '시작하기',
    info: '정보',
    help: '도움말',
    installToDesktop: '데스크탑에 설치',
    interact: '인터랙트',
    invite: '초대',
    join: '참가',
    joinSession: '세션 참가',
    kick: '추방',
    language: '언어',
    lockQueue: '큐 잠금',
    moveToTop: '맨 위로 이동',
    maxUsers: '최대 유저 수',
    next: '다음',
    nextUp: '이어서',
    networkDisconnectHostDisconnect: '호스트가 연결을 종료했습니다.',
    networkDisconnectError: '네트워크 시간 초과입니다.',
    networkDisconnectInvalidClientInfo: '유효하지 않은 클라이언트 정보입니다.',
    networkDisconnectVersionMismatch: `클라이언트 버전이 일치하지 않습니다.`,
    networkDisconnectFull: '세션이 꽉 찼습니다.',
    networkDisconnectKicked: '세션에서 추방되었습니다.',
    networkDisconnectMultiTab: '둘 이상의 브라우저에서 Metastream 세션을 호스팅 하는 경우, 다른 탭이나 브라우저를 닫은 후 페이지를 새로고침 하세요.',
    networkDisconnectSessionNotFound: '세션을 찾을 수 없습니다.',
    networkTroubleshootingHelp: '도움을 위해 <1>네트워크 문제 해결 가이드</1>를 참고하세요.',
    noticeAddedMedia: '<Media id="{{mediaId}}">{{mediaTitle}}</Media>가 추가됨',
    noticeMediaError: '{{url}}를 요청하는데 문제가 발생했습니다.',
    noticeNowPlaying: '<Username id="{{userId}}">{{username}}</Username>가 요청한 <Media id="{{mediaId}}">{{mediaTitle}}</Media>를 재생합니다.',
    noticeUserRequestJoin: '<Username id="{{userId}}">{{username}}</Username>가 입장 허가를 요청했습니다.',
    offline: '오프라인',
    ok: '확인',
    openInBrowser: '브라우저에서 열기',
    private: '비공개',
    profile: '프로필',
    public: '공개',
    reload: '다시 불러오기',
    remove: '제거',
    repeat: '반복',
    retry: '재시도',
    requestUrl: '세션에 추가',
    requestUrlPlaceholder: '',
    requiresDJPermissions: 'DJ 권한이 필요합니다.',
    requiresPortForward: '직접 연결을 위해 <3>{{port}}</3>번 포트의 <1>포트 포워드 설정</1>이 필요합니다.',
    session: '세션',
    sessionMode: '세션 모드',
    sessionModePublicDescription: '누구나 입장할 수 있습니다.',
    sessionModePrivateDescription: '허락받은 사람만 입장할 수 있습니다.',
    sessionModeOfflineDescription: '누구도 입장할 수 없습니다.',
    settings: '설정',
    shareFriendCode: '아래에 표시된 당신의 친구 코드를 공유하여 친구를 초대하세요.',
    shareFriendCodeOther: '아래에 표시된 {{name}}의 친구 코드를 공유하여 친구를 초대하세요.',
    sharePublicIP: '<1>당신의 공인 IP 주소</1>를 공유하여 친구들이 직접 연결할 수 있게 하세요.',
    shareSessionUrl: '아래의 세션 URL을 공유하여 친구를 초대하세요.',
    startSession: '세션 시작',
    theaterMode: '영화관 모드',
    theaterModeDesc: '비디오를 제외한 모든 것을 숨깁니다. 자막도 숨겨질 수 있습니다.',
    thirdPartyIntegrations: '제 3자 통합 기능',
    toggleDJ: 'DJ 전환',
    unlimited: '무제한',
    unlockQueue: '큐 잠금해제',
    username: '사용자 이름',
    users: '참가자',
    userJoined: '<Username id="{{userId}}">{{username}}</Username>가 입장하였습니다.',
    userLeft: '<Username id="{{userId}}">{{username}}</Username>가 퇴장하였습니다.',
    userNameChanged: '<Username id="{{userId}}">{{prevName}}</Username>가 <Username id="{{userId}}">{{name}}</Username>로 닉네임을 변경했습니다.',
    viewOnGitHub: 'GitHub에서 보기',
    waitingForHost: '호스트의 연결 수락을 기다리는 중',
    welcome: '환영합니다'
};
