import React, { Component } from 'react';
import styles from './MediaInfo.css';
import { parseDescription } from '../../../media/description';
import { LinkText } from '../../common/typography';
import { TimestampButton } from '../../media/TimestampButton';
export default class MediaInfo extends Component {
    componentDidUpdate() {
        this.checkValid();
    }
    checkValid() {
        if (!this.props.media) {
            this.props.onClose && this.props.onClose();
        }
    }
    render() {
        const { media } = this.props;
        if (!media)
            return null;
        const { description } = media;
        if (!description)
            return null;
        const fragments = parseDescription(description);
        const children = fragments.map((fragment, idx) => {
            switch (fragment.type) {
                case "text" /* TEXT */:
                    return fragment.content;
                case "timestamp" /* TIMESTAMP */:
                    return React.createElement(TimestampButton, { key: idx, timestamp: fragment.content });
                case "link" /* LINK */:
                    return (React.createElement(LinkText, { key: idx, href: fragment.content, target: "_blank", rel: "nofollow noopener" }, fragment.content));
            }
        });
        return React.createElement("div", { className: styles.container }, children);
    }
}
