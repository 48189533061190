import React from 'react';
import cx from 'classnames';
import styles from './Switch.css';
export const Switch = props => (React.createElement("div", { className: props.className },
    React.createElement("input", { id: props.id, type: "checkbox", className: cx(styles.tgl, styles['tgl-light']), checked: props.checked, defaultChecked: props.defaultChecked, onChange: e => {
            if (e.target instanceof HTMLInputElement) {
                props.onChange(e.target.checked);
            }
        } }),
    React.createElement("label", { className: styles['tgl-btn'], htmlFor: props.id })));
