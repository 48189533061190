export default {
    add: 'Dodaj',
    addMedia: 'Dodaj film',
    addMediaCTA: 'Zacznij od dodania filmów do sesji.',
    admin: 'Admin',
    advanced: 'Zaawansowane',
    allow: 'Zezwól',
    allowTracking: 'Wyślij informacje o użyciu aplikacji',
    allowTrackingDesc: 'Anonimowe informacje o użytkowaniu są wysyłane do programisty, aby lepiej zrozumieć, w jaki sposób aplikacja jest używana.',
    always: 'Zawsze',
    appearance: 'Wygląd',
    autoFullscreen: 'Auto-fullscreen',
    autoFullscreenDesc: 'Powiększ, aby dopasować aktywne wideo do ramy okna.',
    avatar: 'Awatar',
    avatarCredit: '<0>Wybrany awatar zrobiony przez</0> <1>{{artist}}</1>',
    cancel: 'Anuluj',
    changelog: 'Dziennik zmian',
    chat: 'Czat',
    chatColor: 'Kolor czatu',
    chatMessageFailed: 'Nie można wysłać wiadomości. Proszę spróbuj ponownie.',
    chatPlaceholder: 'Wiadomość lub URL',
    chatRevealHint: 'Naciśnij <1></1> aby pokazać czat',
    chatNewMessage: 'Nowa wiadomość została opublikowana!',
    chatTimestamp: 'Timestampy czatu',
    chatTimestampDesc: 'Pokaż timestampy obok wiadomości.',
    chatTyping1: '<0></0> pisze...',
    chatTyping2: '<0></0> i <2></2> piszą',
    chatTyping3: '<0></0>, <2></2>, i <4></4> piszą',
    chatTypingSeveral: 'Kilka osób pisze',
    chooseDisplayName: 'Wybierz swój nick',
    connecting: 'Łączenie',
    continue: 'Kontynuuj',
    copy: 'Kopiuj',
    copyLink: 'Kopiuj link',
    debug: 'Debuguj',
    developerMode: 'Tryb deweloperski',
    developerModeDesc: 'Pokaż narzędzia deweloperskie do badania stron internetowych.',
    directIP: 'Bezpośrednie IP',
    disallow: 'Odrzuć',
    disconnect: 'Rozłącz',
    disconnected: 'Rozłączony',
    displayName: 'Nick',
    dj: 'DJ',
    donate: 'Wesprzyj',
    donators: 'Wspierający',
    duplicate: 'Duplikuj',
    endSessionTitle: 'Czy chcesz zakończyć sesje?',
    endSessionDescription: 'Przejście na sesję offline spowoduje rozłączenie wszystkich użytkowników',
    endSessionModalDescription: 'Wszyscy użytkownicy zostaną rozłączeni z sesji.',
    endSessionButton: 'Zakończ sesję',
    enterJoinDest: 'Wpisz link sesji Metastream.',
    findSession: 'Znajdź sesje',
    findSessionDescription: 'Dołącz do <1>#sessions</1> kanału społeczności Metastream na Discordzie, aby znależć sesje innych użytkowników. Kliknij w logo poniższe logo Discorda, +aby dołączyć.',
    friendCode: 'Kod znajomego',
    getStarted: 'Zaczynaj',
    help: 'Pomoc',
    info: 'Info',
    installToDesktop: 'Zainstaluj',
    interact: 'Interakcja',
    invite: 'Zaproś',
    join: 'Dołącz',
    joinSession: 'Dołącz do sesji',
    kick: 'Wyrzuc',
    language: 'Język',
    lockQueue: 'Zablokuj kolejkę',
    moveToTop: 'Przenieś na górę',
    maxUsers: 'Maksymalna ilość użytkowników',
    next: 'Następny',
    nextUp: 'Dalej',
    networkDisconnectHostDisconnect: 'Host zakończył połączenie.',
    networkDisconnectError: 'Błąd połączenia.',
    networkDisconnectInvalidClientInfo: 'Nieprawidłowe informacje o kliencie.',
    networkDisconnectVersionMismatch: `Niezgodność wersji klienta. Twój klient lub host wymaga aktualizacji.`,
    networkDisconnectFull: 'Sesja jest pełna.',
    networkDisconnectKicked: 'Wyrzucony z sesji.',
    networkDisconnectMultiTab: 'Jesteś hostem sesji Metastream w więcej niż jednej karcie przeglądarki. Proszę zamknij inne karty lub przeglądarki, następnie odśwież stronę.',
    networkDisconnectSessionNotFound: 'Sesja nie znaleziona.',
    networkTroubleshootingHelp: 'Zobacz <1>Network Troubleshooting guide</1> po pomoc.',
    noticeAddedMedia: 'Dodaj <Media id="{{mediaId}}">{{mediaTitle}}</Media>',
    noticeMediaError: 'Wystąpił błąd podczas żądania {{url}}',
    noticeNowPlaying: 'Teraz leci <Media id="{{mediaId}}">{{mediaTitle}}</Media> na prośbę <Username id="{{userId}}">{{username}}</Username>.',
    noticeUserRequestJoin: '<Username id="{{userId}}">{{username}}</Username> prosi o dołączenie.',
    offline: 'Offline',
    ok: 'OK',
    once: 'Raz',
    openInBrowser: 'Otwórz w przeglądarce',
    openInPopup: 'Otwórz w nowym oknie',
    patreonLogin: 'Zaloguj się do Patreona',
    patreonLogout: 'Wyloguj się',
    patreonPledge: 'Zostań patronem',
    patreonPledgeRequired: 'Dostępne tylko dla patronów',
    patreonPledgeTierRequired: 'Tylko dla patronów na {{tier}} poziomie',
    private: 'Prywatne',
    profile: 'Profil',
    public: 'Publiczne',
    reload: 'Odśwież',
    remove: 'Usuń',
    repeat: 'Powtarzaj',
    retry: 'Spróbuj ponownie',
    requestUrl: 'Dodaj do sesji',
    requestUrlPlaceholder: '',
    required: 'Wymagane',
    requiresDJPermissions: 'Poproś o uprawienia DJ-a',
    requiresPortForward: 'Wymaga <1>ustawienia przekierowania portów</1> <3>{{port}}</3>, aby akceptować bezpośrednie połączenia.',
    safeBrowse: 'Bezpieczne przeglądanie',
    safeBrowseDesc: 'Zwróć się o zatwierdzenie przed załadowaniem nierozpoznanej strony internetowej.',
    safeBrowseLoadContent: 'Załaduj treść z <1>{{host}}</1>?',
    safeBrowseDisableForSession: 'Wyłącz bezpieczne przeglądanie dla tej sesji',
    session: 'Sesja',
    sessionMode: 'Tryb sesji',
    sessionModePublicDescription: 'Każdy może dołączyć.',
    sessionModePrivateDescription: 'Pozwolenie na dołączenie musi być udzielone.',
    sessionModeOfflineDescription: 'Nikt nie może się przyłączyć.',
    settings: 'Ustawienia',
    shareFriendCode: 'Udostępnij znajomym poniższy kod, aby ich zaprosić.',
    shareFriendCodeOther: 'Udostępnij {{name}} poniższy kod, aby ją/go zaprosić',
    sharePublicIP: 'Udostępnij <1>twój publiczny adres ip</1>, aby pozwolić przyjaciołom na bezpośrednie połączenie.',
    shareSessionUrl: 'Udostępnij poniższy link sesji, aby zaprosić znajomych',
    supportMetastream: 'Wspomóż Metastream, aby odblokować nagrody!',
    startSession: 'Rozpocznij sesję',
    theaterMode: 'Tryb kinowy',
    theaterModeDesc: 'Ukryj wszystkie treści nie-video na stronie. Należy pamiętać, że może to również ukrywać napisy.',
    thirdPartyIntegrations: 'Third-party Integrations',
    toggleDJ: 'Przełącz DJ-a',
    unlimited: 'Nielimitowany',
    unlockQueue: 'Odblokuj kolejkę',
    updateAvailable: 'Jest dostępna aktualizacja Metastream. Kliknij UPDATE w prawym górnym rogu, aby zaktualizować',
    username: 'Nazwa użytkownika',
    users: 'Użytkownicy',
    userJoined: '<Username id="{{userId}}">{{username}}</Username> dołączył.',
    userLeft: '<Username id="{{userId}}">{{username}}</Username> wyszedł.',
    userNameChanged: '<Username id="{{userId}}">{{prevName}}</Username> zmienił nazwę na <Username id="{{userId}}">{{name}}</Username>.',
    viewOnGitHub: 'Zobacz na GitHub-ie',
    waitingForHost: 'Poczekaj, aż host zaakcpetuje twoje dołączenie.',
    welcome: 'Witaj'
};
