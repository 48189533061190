export default {
    add: '添加',
    addMedia: '添加媒体',
    admin: '管理员',
    advanced: '高级',
    allow: '允许',
    allowTracking: '发送使用信息',
    allowTrackingDesc: '您的使用信息将会被不留名的发送给开发者以便更好的了解此程序如何使用。',
    avatar: '头像',
    avatarCredit: '<0>选中的头像由</0> <1>{{artist}}</1> 设计',
    cancel: '取消',
    changelog: '更新日志',
    chat: '聊天',
    chatColor: '聊天颜色',
    chatPlaceholder: '消息或网址',
    chatRevealHint: '按 <1></1> 来显示聊天.',
    chatNewMessage: '新消息已经发送!',
    chooseDisplayName: '填入您的昵称',
    connecting: '正在连接',
    continue: '继续',
    copy: '复制',
    copyLink: '复制链接',
    debug: '调试',
    developerMode: '开发者模式',
    developerModeDesc: '显示开发者工具来检查页面',
    directIP: 'IP地址',
    disallow: '不允许',
    disconnect: '断开连接',
    disconnected: '已经断开连接',
    displayName: '昵称',
    dj: 'DJ',
    donate: '打赏',
    donators: '赞助过的人',
    duplicate: '重复',
    endSessionTitle: '结束会话吗?',
    endSessionDescription: '变为私有会话会切断所有当前连接的会话.',
    endSessionModalDescription: '所有用户将与此会话切断连接.',
    endSessionButton: '结束',
    enterJoinDest: '输入一个 Metastream 会话链接.',
    findSession: '查找会话',
    findSessionDescription: '加入在 Metastream Discord 社区上的 <1>#sessions</1> 频道来寻找其他用户的会话. 点击 Discord 的 LOGO 进行登录.',
    friendCode: '伙伴代码',
    getStarted: '开始',
    help: '帮助',
    info: '信息',
    installToDesktop: '安装到桌面',
    interact: '互相作用',
    invite: '邀请',
    join: '加入',
    joinSession: '加入会话',
    kick: '踢掉',
    language: '语言',
    lockQueue: '锁住队列',
    moveToTop: '移至顶部',
    maxUsers: '最大用户数',
    next: '下一个',
    nextUp: '接下来',
    networkDisconnectHostDisconnect: '连接已经关闭.',
    networkDisconnectError: '网络错误.',
    networkDisconnectInvalidClientInfo: '错误的连接信息.',
    networkDisconnectVersionMismatch: '未知的客户端版本.',
    networkDisconnectFull: '会话已满.',
    networkDisconnectKicked: '已经从会话中踢掉.',
    networkDisconnectMultiTab: '您已经在多个标签或浏览器中创建了会话. 请关闭其他浏览器或标签, 然后刷新页面.',
    networkDisconnectSessionNotFound: '会话未找到.',
    networkTroubleshootingHelp: '从 <1>网络问题指导册</1> 中寻找帮助.',
    noticeAddedMedia: '添加 <Media id="{{mediaId}}">{{mediaTitle}}</Media>',
    noticeMediaError: '请求发生了错误 {{url}}',
    noticeNowPlaying: '现在正在播放 <Username id="{{userId}}">{{username}}</Username> 点播的 <Media id="{{mediaId}}">{{mediaTitle}}</Media>.',
    noticeUserRequestJoin: '<Username id="{{userId}}">{{username}}</Username> 请求加入会话.',
    offline: '离线',
    ok: 'OK',
    openInBrowser: '在浏览器中打开',
    private: '私有的',
    profile: '资料',
    public: '公有的',
    reload: '刷新',
    remove: '移除',
    repeat: '重复',
    retry: '重试',
    requestUrl: '添加到会话',
    requestUrlPlaceholder: '',
    requiresDJPermissions: '需要 DJ 权限',
    requiresPortForward: '需要将 <3>{{port}}</3> 端口进行 <1>转发</1> 来接受直接连接.',
    sessionMode: '会话模式',
    sessionModePublicDescription: '任何人都允许加入.',
    sessionModePrivateDescription: '需授予权限后才能加入.',
    sessionModeOfflineDescription: '任何人都不能加入.',
    settings: '设置',
    shareFriendCode: '分享你的伙伴代码来邀请别人.',
    shareFriendCodeOther: '分享 {{name}} 的伙伴代码来邀请别人.',
    sharePublicIP: '分享 <1>你的公网IP地址</1> 来允许他人直接连接.',
    shareSessionUrl: '分享会话链接来邀请别人.',
    startSession: '开始会话',
    thirdPartyIntegrations: '第三方集成',
    toggleDJ: '切换 DJ',
    unlimited: '无限制的',
    unlockQueue: '解锁队列',
    username: '用户名',
    users: '用户',
    userJoined: '<Username id="{{userId}}">{{username}}</Username> 加入了.',
    userLeft: '<Username id="{{userId}}">{{username}}</Username> 离开了.',
    viewOnGitHub: '在 GitHub 上查看',
    waitingForHost: '等待管理员允许加入',
    welcome: '欢迎'
};
