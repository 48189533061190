import React, { Component } from 'react';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import Routes from '../routes';
import { PersistGate } from 'redux-persist/integration/react';
import { ErrorBoundary } from 'components/ErrorBoundary';
export default class Root extends Component {
    render() {
        const { store, history, persistor } = this.props;
        return (React.createElement(ErrorBoundary, null,
            React.createElement(Provider, { store: store },
                React.createElement(PersistGate, { persistor: persistor },
                    React.createElement(ConnectedRouter, { history: history },
                        React.createElement(Routes, null))))));
    }
}
