import React from 'react';
import cx from 'classnames';
import styles from './input.css';
import { HighlightButton } from 'components/common/button';
import { t } from 'locale';
import { copyToClipboard } from 'utils/clipboard';
export const TextInput = props => {
    const { theRef, ...rest } = props;
    return React.createElement("input", Object.assign({ ref: theRef, type: "text" }, rest, { className: cx(styles.text, props.className) }));
};
export const TextAreaInput = props => {
    const { theRef, ...rest } = props;
    return React.createElement("textarea", Object.assign({ ref: theRef }, rest, { className: cx(styles.text, props.className) }));
};
export const InputGroup = props => {
    return React.createElement("div", { className: styles.inputGroup }, props.children);
};
export class ClipboardTextInput extends React.Component {
    constructor() {
        super(...arguments);
        this.input = null;
        this.copy = () => {
            if (this.input) {
                const { value } = this.input;
                copyToClipboard(value);
            }
        };
    }
    render() {
        return (React.createElement("div", { className: cx(styles.textContainer, this.props.className) },
            React.createElement("input", { ref: e => (this.input = e), className: cx(this.props.inputClassName, styles.textInput), type: "text", defaultValue: this.props.defaultValue, disabled: this.props.disabled }),
            React.createElement(HighlightButton, { icon: "clipboard", onClick: this.copy, highlight: true }, t('copy'))));
    }
}
