import { addUser } from 'lobby/middleware/users';
import { multi_userJoined, client_kick } from 'lobby/actions/users';
import { rpc } from 'network/middleware/rpc';
import { getUser, getNumUsers, isAdmin, getUniqueName } from 'lobby/reducers/users.helpers';
import { getLocalUsername, getLocalColor, getLocalSessionMode, getLocalAvatar } from 'reducers/settings';
import { getMaxUsers } from '../reducers/session';
import { METASTREAM_NETWORK_VERSION } from 'constants/network';
import { setAuthorized, setConnectionStatus, setDisconnectReason } from './session';
import { updateServerClockSkew } from './mediaPlayer';
import { addChat } from './chat';
import { actionCreator } from 'utils/redux';
import { parseQuery } from 'utils/url';
import { translateEscaped } from 'locale';
import { validateDisplayName, validateColor, validateAvatar } from './user-validation';
export const clearPendingUser = actionCreator('CLEAR_PENDING_USER');
/** Initialize client */
export const initialize = (server) => {
    return async (dispatch, getState) => {
        const state = getState();
        let response;
        const { location } = state.router;
        const secret = location ? parseQuery(location.search).secret : undefined;
        try {
            response = await dispatch(server_initClient({
                version: METASTREAM_NETWORK_VERSION,
                name: getLocalUsername(state),
                color: getLocalColor(state),
                avatar: getLocalAvatar(state),
                secret
            }));
        }
        catch (e) {
            console.error('Failed to receive client initialization response.');
            dispatch(setDisconnectReason(2 /* Error */));
            server.close();
            return;
        }
        if (response === 1 /* Pending */) {
            dispatch(setConnectionStatus("Pending" /* Pending */));
        }
    };
};
const validateClientInfo = (info, id, state) => {
    if (info.version !== METASTREAM_NETWORK_VERSION) {
        console.debug(`Client '${info.version}'[${id}] kicked for version mismatch (${info.version})`);
        return 4 /* VersionMismatch */;
    }
    const existingUser = !!getUser(state, id);
    if (existingUser) {
        console.debug(`Client with existing ID already active in session ${id}`);
        return 3 /* InvalidClientInfo */;
    }
    if (!validateDisplayName(info.name)) {
        console.debug(`Client ${id} kicked for name overflow (${info.name})`);
        return 3 /* InvalidClientInfo */;
    }
    if (!validateColor(info.color)) {
        console.debug(`Client ${id} kicked for invalid color (${info.color})`);
        return 3 /* InvalidClientInfo */;
    }
    if (info.avatar && !validateAvatar(info.avatar)) {
        console.debug(`Client ${id} kicked for invalid avatar (${info.avatar})`);
        return 3 /* InvalidClientInfo */;
    }
    if (info.secret && typeof info.secret !== 'string') {
        console.debug(`Client ${id} kicked for invalid secret (${info.secret})`);
        return 3 /* InvalidClientInfo */;
    }
    return true;
};
const clientAuthorized = (info) => (dispatch, getState) => {
    // TODO: take average of multiple samples?
    const dt = Date.now() - info.serverTime;
    dispatch(updateServerClockSkew(dt));
    dispatch(setAuthorized(true));
};
const client_authorized = rpc('clientAuthorized', "client" /* Client */, clientAuthorized);
const initClient = (info) => (dispatch, getState, { client }) => {
    const state = getState();
    const id = client.id.toString();
    console.debug(`Received client info for ${id}`, info);
    let reason;
    let validOrReason;
    try {
        validOrReason = validateClientInfo(info, id, state);
    }
    catch {
        validOrReason = 3 /* InvalidClientInfo */;
    }
    if (validOrReason !== true) {
        reason = validOrReason;
    }
    else if (getNumUsers(state) >= getMaxUsers(state)) {
        reason = 5 /* Full */;
    }
    if (reason) {
        dispatch(client_kick(reason)(id));
        client.close();
        return;
    }
    const sessionMode = getLocalSessionMode(state);
    // Discord invites send session secret
    const secretMismatch = info.secret !== state.session.secret;
    // Determine whether user needs explicit authorization from host to join
    const shouldAwaitAuthorization = sessionMode === 2 /* Private */ ? secretMismatch : false;
    const name = getUniqueName(state, info.name);
    dispatch(addUser({
        conn: client,
        name,
        avatar: info.avatar,
        color: info.color,
        pending: shouldAwaitAuthorization
    }));
    if (shouldAwaitAuthorization) {
        const content = translateEscaped('noticeUserRequestJoin', { userId: id, username: name });
        dispatch(addChat({ content, html: true, timestamp: Date.now() }));
        return 1 /* Pending */;
    }
    dispatch(authorizeClient(client));
    return 0 /* Ok */;
};
const server_initClient = rpc('initClient', "server" /* Server */, initClient, {
    allowUnauthed: true
});
const authorizeClient = (client) => {
    return async (dispatch, getState) => {
        const id = client.id.toString();
        dispatch(multi_userJoined(id));
        // Client has been fully authorized
        client.auth();
        dispatch(client_authorized({ serverTime: Date.now() })(id));
    };
};
const answerClient = (userId, allow) => (dispatch, getState, { client, server }) => {
    const state = getState();
    if (!isAdmin(state, client.id.toString()))
        return;
    const user = getUser(state, userId);
    if (!user || !user.pending)
        return;
    const userClient = server.getClientById(userId);
    if (!userClient)
        return;
    if (allow) {
        dispatch(clearPendingUser(userId));
        dispatch(authorizeClient(userClient));
    }
    else {
        userClient.close();
    }
};
export const server_answerClient = rpc('answerClient', "server" /* Server */, answerClient);
