import React, { Component } from 'react';
import styles from './Media.css';
import { formatMs } from 'utils/time';
import { IconButton } from '../common/button';
import { assetUrl } from 'utils/appUrl';
const DEFAULT_FAVICON = assetUrl('icons/favicon-default.svg');
export class MediaItem extends Component {
    constructor() {
        super(...arguments);
        this.state = {};
    }
    get canShowMenu() {
        return true;
    }
    render() {
        const { media } = this.props;
        let hostname;
        try {
            hostname = new URL(media.url).hostname;
        }
        catch { }
        return (React.createElement("figure", { className: styles.container },
            React.createElement("img", { src: media.favicon || DEFAULT_FAVICON, className: styles.favicon, title: hostname, onError: e => {
                    if (e.target && e.target.tagName === 'IMG') {
                        ;
                        e.target.src = DEFAULT_FAVICON;
                    }
                } }),
            React.createElement("figcaption", { className: styles.media },
                React.createElement("div", { className: styles.title, title: media.title }, media.title),
                typeof media.duration === 'number' && media.duration !== 0 && (React.createElement("span", { className: styles.duration }, formatMs(media.duration))),
                media.ownerName && (React.createElement("div", { className: styles.authorContainer },
                    React.createElement("span", { className: styles.authorLabel }, "Added by"),
                    React.createElement("span", { className: styles.author }, media.ownerName)))),
            this.canShowMenu && (React.createElement(IconButton, { icon: "more-vertical", className: styles.menuBtn, onClick: this.props.onClickMenu }))));
    }
}
