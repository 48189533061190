import React, { Component } from 'react';
import cx from 'classnames';
import { TitleBar } from 'components/TitleBar';
import styles from './Main.css';
export default class LayoutMain extends Component {
    render() {
        return (React.createElement("div", { className: styles.container },
            React.createElement(TitleBar, { className: styles.titleBar, showBackButton: this.props.showBackButton }),
            React.createElement("main", { className: cx(this.props.className, styles.content) }, this.props.children)));
    }
}
