import React, { Component } from 'react';
import { connect } from 'react-redux';
import { SessionJoin } from '../components/SessionJoin';
import { push } from 'connected-react-router';
import { formatSessionPath } from 'utils/network';
class _SessionJoinPage extends Component {
    constructor() {
        super(...arguments);
        this.connect = (uri) => {
            const path = formatSessionPath(uri);
            console.debug(`Connecting to ${path}`);
            this.props.dispatch(push(`/join/${path}`));
        };
    }
    render() {
        return React.createElement(SessionJoin, { connect: this.connect });
    }
}
export const SessionJoinPage = connect()(_SessionJoinPage);
