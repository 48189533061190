import { isType } from 'utils/redux';
import { addChat, recordTyping, clearTyping } from 'lobby/actions/chat';
import { resetLobby } from '../actions/common';
let CHAT_MESSAGE_COUNTER = 0;
const initialState = {
    messages: [],
    typing: []
};
export const chat = (state = initialState, action) => {
    if (isType(action, addChat)) {
        return {
            ...state,
            messages: [
                ...state.messages,
                {
                    ...action.payload,
                    id: `${++CHAT_MESSAGE_COUNTER}`
                }
            ]
        };
    }
    else if (isType(action, recordTyping)) {
        const userId = action.payload;
        // add user to list of typing users if they're not already typing (FIFO)
        const isUserAlreadyTyping = state.typing.some(typingUserId => typingUserId === userId);
        return isUserAlreadyTyping ? state : { ...state, typing: [...state.typing, userId] };
    }
    else if (isType(action, clearTyping)) {
        return {
            ...state,
            typing: state.typing.filter(typingUserId => typingUserId !== action.payload)
        };
    }
    if (isType(action, resetLobby)) {
        return initialState;
    }
    return state;
};
