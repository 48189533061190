import React, { PureComponent } from 'react';
import cx from 'classnames';
import { clamp } from 'utils/math';
import { Slider } from 'components/media/Slider';
import { Ticker } from 'components/Ticker';
import { Time } from 'components/media/Time';
import styles from './Timeline.css';
const HOUR_MS = 3600 * 1000;
let Timeline = /** @class */ (() => {
    class Timeline extends PureComponent {
        constructor() {
            super(...arguments);
            this.state = { time: this.calcTime(), progress: this.calcProgress() };
            this.slider = null;
            this.tick = () => {
                this.setState({ time: this.calcTime(), progress: this.calcProgress() });
            };
        }
        componentDidUpdate(prevProps) {
            if (this.props.time !== prevProps.time) {
                this.tick();
            }
        }
        /** Calculate current timestamp. */
        calcTime() {
            const { time, paused, duration, playbackRate } = this.props;
            if (this.slider && this.slider.state.dragging) {
                const { cursorProgress } = this.slider.state;
                return (cursorProgress || 0) * duration;
            }
            return paused ? time : (Date.now() - time) * playbackRate;
        }
        /** Calculate progress bar amount. */
        calcProgress() {
            const { time, paused, duration, playbackRate } = this.props;
            const curTime = paused ? time : (Date.now() - time) * playbackRate;
            return clamp(curTime / (duration || 1), 0, 1);
        }
        getCuePoints() {
            const { cuePoints, duration } = this.props;
            if (cuePoints && duration) {
                // TODO: store in state since this will be invoked often while scrubbing
                return cuePoints.map(cue => ({
                    ...cue,
                    value: clamp(cue.value / duration, 0, 1)
                }));
            }
        }
        render() {
            const { time, paused, duration, onSeek } = this.props;
            const exceedsHour = duration ? duration >= HOUR_MS : false;
            return (React.createElement("span", { className: cx(this.props.className, styles.container) },
                React.createElement(Time, { className: styles.time, time: this.state.time, leading: true, leadingHours: exceedsHour }),
                React.createElement(Slider, { ref: el => {
                        this.slider = el;
                    }, className: styles.progressSlider, progressBarClassName: styles.progressBar, value: this.state.progress, cuePoints: this.getCuePoints(), hover: true, onChange: progress => {
                        if (onSeek) {
                            onSeek(progress * (duration || 0));
                        }
                    }, onDragStart: () => {
                        this.setState({ seeking: true });
                    }, onDragEnd: () => {
                        this.setState({ seeking: false });
                    }, onHoverStart: () => {
                        this.setState({ hovering: true });
                    }, onHoverEnd: () => {
                        this.setState({ hovering: false });
                    } }),
                React.createElement(Time, { className: styles.time, time: duration, leading: true, leadingHours: exceedsHour }),
                React.createElement(Ticker, { onTick: this.tick, disabled: paused && !this.state.seeking && !this.state.hovering })));
        }
    }
    Timeline.defaultProps = {
        duration: 0,
        playbackRate: 1
    };
    return Timeline;
})();
export { Timeline };
