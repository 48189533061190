import React from 'react';
import { assetUrl } from 'utils/appUrl';
// too lazy to move into css
const DEFAULT_STYLE = {
    display: 'inline-block',
    flexShrink: 0
};
const SIZE_SCALE = {
    small: 0.7,
    default: 1.0
};
/**
 * SVG icon component.
 * Uses a class to hold a ref.
 */
export class Icon extends React.Component {
    render() {
        const { name, size, pointerEvents, ...rest } = this.props;
        const path = assetUrl(`icons/${name}.svg#${name}`);
        let style = DEFAULT_STYLE;
        if (size) {
            style = { ...style, transform: `scale(${SIZE_SCALE[size]}` };
        }
        if (pointerEvents) {
            style = { ...style, pointerEvents };
        }
        return (React.createElement("svg", Object.assign({ width: "24", height: "24", style: style }, rest),
            React.createElement("use", { href: path })));
    }
}
