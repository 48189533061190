import { isType } from 'utils/redux';
import { setMedia, endMedia, playPauseMedia, seekMedia, queueMedia, repeatMedia, updateMedia, deleteMedia, updateServerClockSkew, moveToTop, lockQueue, setPendingMedia, setPlaybackRate } from 'lobby/actions/mediaPlayer';
import { resetLobby, initLobby } from '../actions/common';
import { clamp } from 'utils/math';
import { getPlaybackTime2 } from './mediaPlayer.helpers';
export const mediaPlayerReplicatedState = {
    playback: true,
    repeatMode: true,
    startTime: true,
    pauseTime: true,
    playbackRate: true,
    current: true,
    queue: true,
    queueLocked: true,
    serverClockSkew: false
};
const initialState = {
    playback: 0 /* Idle */,
    repeatMode: 0 /* Off */,
    startTime: undefined,
    pauseTime: undefined,
    playbackRate: 1 /* Default */,
    current: undefined,
    queue: [],
    queueLocked: false,
    serverClockSkew: 0
};
const getMediaStartTime = (media) => media.startTime ? Date.now() - media.startTime : Date.now();
export const mediaPlayer = (state = initialState, action) => {
    if (isType(action, setMedia)) {
        const media = action.payload;
        return {
            ...state,
            playback: 1 /* Playing */,
            current: media,
            playbackRate: 1 /* Default */,
            startTime: getMediaStartTime(media)
        };
    }
    else if (isType(action, endMedia)) {
        let next;
        let queue = state.queue;
        const current = state.current;
        const force = action.payload;
        if (current) {
            if ((force && state.repeatMode !== 0 /* Off */) || state.repeatMode === 1 /* On */) {
                queue = [...queue, current];
            }
            else if (state.repeatMode === 2 /* One */) {
                queue = [current, ...queue];
            }
        }
        // get next item in the queue
        if (queue.length > 0) {
            // create queue copy since `shift()` will mutate it
            queue = [...queue];
            next = queue.shift();
        }
        return {
            ...state,
            playback: next ? 1 /* Playing */ : 0 /* Idle */,
            playbackRate: 1 /* Default */,
            current: next,
            startTime: next ? getMediaStartTime(next) : undefined,
            queue: queue
        };
    }
    else if (isType(action, playPauseMedia)) {
        switch (state.playback) {
            case 1 /* Playing */: {
                const curTime = getPlaybackTime2(state);
                return {
                    ...state,
                    playback: 2 /* Paused */,
                    startTime: undefined,
                    pauseTime: curTime
                };
            }
            case 2 /* Paused */:
                return {
                    ...state,
                    playback: 1 /* Playing */,
                    startTime: Date.now() - state.pauseTime / state.playbackRate,
                    pauseTime: undefined
                };
        }
    }
    else if (isType(action, seekMedia)) {
        const time = action.payload;
        switch (state.playback) {
            case 1 /* Playing */:
                return {
                    ...state,
                    startTime: Date.now() - time / state.playbackRate
                };
            case 2 /* Paused */:
                return {
                    ...state,
                    pauseTime: time
                };
        }
    }
    else if (isType(action, setPlaybackRate)) {
        const playbackRate = clamp(action.payload, 0.25 /* Min */, 4 /* Max */) || 1 /* Default */;
        switch (state.playback) {
            case 1 /* Playing */:
                const curTime = getPlaybackTime2(state);
                return {
                    ...state,
                    startTime: Date.now() - curTime / playbackRate,
                    playbackRate
                };
            default:
                return { ...state, playbackRate };
        }
    }
    else if (isType(action, queueMedia)) {
        const { media, index } = action.payload;
        const queue = [...state.queue];
        const queueIndex = typeof index === 'number' ? index : queue.length;
        queue.splice(queueIndex, 0, media);
        return { ...state, queue };
    }
    else if (isType(action, repeatMedia)) {
        return {
            ...state,
            // cycle through repeat modes
            repeatMode: (state.repeatMode + 1) % 3 /* Count */
        };
    }
    else if (isType(action, updateServerClockSkew)) {
        return { ...state, serverClockSkew: action.payload };
    }
    else if (isType(action, updateMedia) && state.current) {
        const prevDuration = state.current.duration;
        const nextDuration = action.payload.duration;
        // Reset start time if media previously had unknown duration
        const hasPrevDuration = typeof prevDuration === 'number';
        const hasNextDuration = typeof nextDuration === 'number';
        const startTime = !hasPrevDuration && hasNextDuration ? Date.now() : state.startTime;
        return {
            ...state,
            startTime,
            current: {
                ...state.current,
                duration: hasNextDuration ? nextDuration : prevDuration
            }
        };
    }
    else if (isType(action, deleteMedia)) {
        const mediaId = action.payload;
        const mediaIdx = state.queue.findIndex(media => media.id === mediaId);
        if (mediaIdx > -1) {
            const queue = [...state.queue];
            queue.splice(mediaIdx, 1);
            return { ...state, queue };
        }
    }
    else if (isType(action, moveToTop)) {
        const mediaId = action.payload;
        const mediaIdx = state.queue.findIndex(media => media.id === mediaId);
        if (mediaIdx > -1) {
            const queue = [...state.queue];
            queue.unshift(queue.splice(mediaIdx, 1)[0]);
            return { ...state, queue };
        }
    }
    else if (isType(action, lockQueue)) {
        return {
            ...state,
            queueLocked: !state.queueLocked
        };
    }
    else if (isType(action, setPendingMedia)) {
        return {
            ...state,
            pendingMedia: action.payload
        };
    }
    // Save session snapshot on disconnect
    if (isType(action, resetLobby)) {
        if (!action.payload.host) {
            return state.localSnapshot
                ? { ...initialState, localSnapshot: state.localSnapshot }
                : initialState;
        }
        const isPlaying = state.playback === 1 /* Playing */;
        return {
            ...initialState,
            localSnapshot: {
                ...state,
                playback: isPlaying ? 2 /* Paused */ : state.playback,
                pauseTime: isPlaying ? getPlaybackTime2(state) : state.pauseTime
            }
        };
    }
    // Restore session snapshot
    if (isType(action, initLobby)) {
        if (action.payload.host && state.localSnapshot) {
            const { localSnapshot } = state;
            return {
                ...state,
                ...initialState,
                ...localSnapshot,
                localSnapshot: undefined,
                // #227 a bug appeared where the snapshot was restored in an idle playback state with media.
                // here we force the state to be paused to ensure media can be skipped.
                playback: localSnapshot.current ? 2 /* Paused */ : 0 /* Idle */,
                serverClockSkew: initialState.serverClockSkew
            };
        }
        else {
            // Clear out old state in case tab shutdown unexpectedly
            return { ...state, ...initialState };
        }
    }
    return state;
};
