export default {
    add: 'Añadir',
    addMedia: 'Agregar Medios',
    admin: 'Administrador',
    advanced: 'Avanzado',
    allow: 'Autorizar',
    allowTracking: 'Enviar información sobre el uso de la aplicación',
    allowTrackingDesc: 'Información anónima sobre el uso de la aplicación es enviada al desarrollador para comprender mejor cómo se utiliza la aplicación.',
    always: 'Siempre',
    appearance: 'Apariencia',
    autoFullscreen: 'Auto ampliar pantalla',
    autoFullscreenDesc: 'Ampliar para enfocar el video dentro del tamaño de la ventana.',
    avatar: 'Foto de perfil',
    avatarCredit: '<0>Foto de perfil seleccionada hecha por</0> <1>{{artist}}</1>',
    cancel: 'Cancelar',
    changelog: 'Registro de cambios',
    chat: 'Chat',
    chatColor: 'Color de Chat',
    chatMessageFailed: 'No se pudo enviar su mensaje. Inténtalo de nuevo.',
    chatPlaceholder: 'Mensaje o URL',
    chatRevealHint: 'Presione <1></1> para mostrar chat.',
    chatNewMessage: '¡Se ha publicado un nuevo mensaje!',
    chatTyping1: '<0></0> esta escribiendo…',
    chatTyping2: '<0></0> y <2></2> están escribiendo…',
    chatTyping3: '<0></0>, <2></2>, y <4></4> están escribiendo…',
    chatTypingSeveral: 'Varios usuarios están escribiendo…',
    chooseDisplayName: 'Elija su nombre de usuario',
    connecting: 'Conectando',
    continue: 'Continuar',
    copy: 'Copiar',
    copyLink: 'Copiar enlace',
    debug: 'Depurar',
    developerMode: 'Modo desarrollador',
    developerModeDesc: 'Mostrar herramientas de desarrollador para inspeccionar páginas web.',
    directIP: 'IP Directo',
    disallow: 'Rechazar',
    disconnect: 'Desconectar',
    disconnected: 'Desconectado',
    displayName: 'Nombre para mostrar',
    dj: 'DJ',
    donate: 'Donar',
    donators: 'Donadores',
    duplicate: 'Duplicar',
    endSessionTitle: '¿Finalizar sesión?',
    endSessionDescription: 'Cambiar a una sesión privada desconectará a todos los usuarios conectados.',
    endSessionModalDescription: 'Todos los usuarios serán desconectados de la sesión.',
    endSessionButton: 'Finalizar sesión',
    enterJoinDest: 'Ingrese una URL de sesión de Metastream.',
    findSession: 'Encontrar sesión',
    findSessionDescription: 'Únete al <1>#sessions</1> canal Discord de la comunidad Metastream para encontrar sesiones de otros usuarios. Presione el logotipo de Discord a continuación para unirte.',
    friendCode: 'Código de amigo',
    getStarted: 'Comenzar',
    help: 'Ayuda',
    info: 'Información',
    installToDesktop: 'Instalar en el escritorio',
    interact: 'Interactuar',
    invite: 'Invitar',
    join: 'Unirse',
    joinSession: 'Unirse a la sesión',
    kick: 'Expulsar',
    language: 'Lenguaje',
    lockQueue: 'Bloquear cola',
    moveToTop: 'Mover al principio',
    maxUsers: 'Máximo de usuarios',
    next: 'Siguiente',
    nextUp: 'Próximamente',
    networkDisconnectHostDisconnect: 'Anfitrión de la sesión ha cerrado la conexión.',
    networkDisconnectError: 'Error de conexión.',
    networkDisconnectInvalidClientInfo: 'Información de cliente invalida.',
    networkDisconnectVersionMismatch: `Versión de cliente diferente. Su cliente, o el anfitrión de la sesión, requiere una actualización.`,
    networkDisconnectFull: 'La sesión está llena.',
    networkDisconnectKicked: 'Expulsado de la sesión.',
    networkDisconnectMultiTab: 'Está alojando una sesión Metastream desde más de un navegador o pestaña. Cierre las otras sesiones, y cargue nuevamente la página.',
    networkDisconnectSessionNotFound: 'Sesión no encontrada.',
    networkTroubleshootingHelp: 'Mire la <1>Guía para diagnosticar conexión</1> para ayuda.',
    noticeAddedMedia: 'Se agregó <Media id="{{mediaId}}">{{mediaTitle}}</Media>',
    noticeMediaError: 'Hubo un error solicitando {{url}}',
    noticeNowPlaying: 'Reproduciendo <Media id="{{mediaId}}">{{mediaTitle}}</Media> solicitado por <Username id="{{userId}}">{{username}}</Username>.',
    noticeUserRequestJoin: '<Username id="{{userId}}">{{username}}</Username> está pidiendo permiso para unirse.',
    offline: 'Sin conexión',
    ok: 'OK',
    once: 'Una vez',
    openInBrowser: 'Abrir en navegador',
    private: 'Privado',
    profile: 'Perfil',
    public: 'Público',
    reload: 'Recargar',
    remove: 'Remover',
    repeat: 'Repetir',
    retry: 'Procesar de nuevo',
    requestUrl: 'Añadir a sesión',
    requestUrlPlaceholder: '',
    requiresDJPermissions: 'Requiere permisos de DJ',
    requiresPortForward: 'Para aceptar conexiones directas se requiere <1>enrutar los siguientes puertos</1> of <3>{{port}}</3> para aceptar conexiones directas.',
    safeBrowse: 'Navegación segura',
    safeBrowseDesc: 'Solicitar aprobación antes de cargar un sitio web no reconocido.',
    safeBrowseLoadContent: 'Cargar contenido desde <1>{{host}}</1>?',
    safeBrowseDisableForSession: 'Deshabilitar navegación segura para esta sesión',
    session: 'Sesión',
    sessionMode: 'Modo de sesión',
    sessionModePublicDescription: 'Cualquier usuario puede unirse.',
    sessionModePrivateDescription: 'Permiso para unirse debe ser otorgado explicitamente.',
    sessionModeOfflineDescription: 'Nadie está permitido unirse.',
    settings: 'Ajustes',
    shareFriendCode: 'Comparte el siguiente código de amigo para invitar amigos.',
    shareFriendCodeOther: 'Comparte el siguiente código de amigo de {{name}} para invitar amigos.',
    sharePublicIP: 'Comparte <1>tu IP público</1> para permitir que sus amigos se conecten directamente.',
    shareSessionUrl: 'Comparta la URL de la sesión a continuación para invitar amigos.',
    startSession: 'Comenzar Sesión',
    theaterMode: 'Modo teatro',
    theaterModeDesc: 'Ocultar todo el contenido que no sea de video en la página web. Tenga en cuenta que esto también podría ocultar subtítulos suaves.',
    thirdPartyIntegrations: 'Integraciones externas',
    toggleDJ: 'Activar/Desactivar DJ',
    unlimited: 'Ilimitado',
    unlockQueue: 'Desbloquear cola',
    username: 'Usuario',
    users: 'Usuarios',
    userJoined: '<Username id="{{userId}}">{{username}}</Username> se ha unido.',
    userLeft: '<Username id="{{userId}}">{{username}}</Username> se ha ido.',
    userNameChanged: '<Username id="{{userId}}">{{prevName}}</Username> ahora se conoce como<Username id="{{userId}}">{{name}}</Username>.',
    viewOnGitHub: 'Ver en GitHub',
    waitingForHost: 'Esperando que el servidor permita la conexión',
    welcome: 'Bienvenido'
};
