import React from 'react';
import cx from 'classnames';
import styles from './button.css';
import { Icon } from 'components/Icon';
import Tooltip from '@material-ui/core/Tooltip';
const nbsp = '\u00A0';
export const IconButton = ({ icon, iconSize, title, tooltipProps, children, highlight, ...props }) => {
    return (React.createElement("button", Object.assign({ type: "button", className: cx(styles.iconButton, props.className, {
            [styles.iconHighlight]: highlight
        }), style: title
            ? {
                position: 'relative'
            }
            : undefined }, props),
        title ? (React.createElement(Tooltip, Object.assign({}, tooltipProps, { title: title }),
            React.createElement("div", { className: styles.tooltip }))) : null,
        React.createElement(Icon, { name: icon, size: iconSize, pointerEvents: "none" }),
        !!children && nbsp,
        children ? React.createElement("span", { className: styles.iconChildren }, children) : undefined));
};
export const HighlightButton = ({ highlight, glass, blend, size, ...props }) => {
    const className = cx(props.className, styles.highlightBtn, styles[size || 'small'], {
        [styles.highlight]: highlight,
        [styles.outline]: !highlight,
        [styles.glass]: glass,
        [styles.blend]: blend
    });
    return React.createElement(IconButton, Object.assign({}, props, { className: className }));
};
