import { EventEmitter } from 'events';
class NetServer extends EventEmitter {
    constructor(opts) {
        super();
        this.connections = new Map();
        this.coordinators = [];
        this.closing = false;
        this.connect = (conn) => {
            console.log(`[NetServer] New client connection from ${conn}`);
            {
                const prevConn = this.getClientById(conn.id.toString());
                if (prevConn) {
                    // TODO: notify dropped
                    prevConn.close();
                    console.log(`[NetServer] Dropped old client for ${conn}`);
                }
            }
            const id = conn.id.toString();
            this.connections.set(id, conn);
            conn.once('close', () => this.disconnect(conn));
            conn.on('data', (data) => this.receive(conn, data));
            if (!this.isHost) {
                conn.auth(); // auth host
            }
            this.connected = true;
            this.emit('connect', conn);
        };
        this.error = (err) => {
            this.emit('error', err);
        };
        this.isHost = opts.isHost;
        this.connected = opts.isHost;
        this.coordinators = [...opts.coordinators];
        for (let coordinator of this.coordinators) {
            coordinator.on('connection', this.connect);
            coordinator.on('error', this.error);
        }
    }
    disconnect(conn) {
        const id = conn.id.toString();
        this.connections.delete(id);
        this.emit('disconnect', conn);
        conn.removeAllListeners();
        console.log(`[NetServer] Client ${conn} has disconnected`);
        if (!this.isHost) {
            this.close();
        }
    }
    getClientById(clientId) {
        return this.connections.get(clientId);
    }
    forEachClient(cb) {
        this.connections.forEach(cb);
    }
    close() {
        this.closing = true;
        this.forEachClient(conn => conn.close());
        this.connections.clear();
        this.coordinators.forEach(coord => {
            coord.removeListener('connection', this.connect);
            coord.removeListener('error', this.error);
            coord.close();
        });
        this.coordinators = [];
        if (this.connected) {
            this.emit('close');
            this.connected = false;
        }
        this.closing = false;
    }
    receive(conn, data) {
        this.emit('data', conn, data);
    }
    send(data) {
        if (this.closing)
            return;
        this.forEachClient(conn => {
            if (conn.isAuthed()) {
                conn.send(data);
            }
        });
    }
    sendTo(clientId, data) {
        if (this.closing)
            return;
        const conn = this.getClientById(clientId);
        if (conn) {
            conn.send(data);
        }
        else {
            console.error(`No client found with an ID of '${clientId}'`);
        }
    }
    sendToHost(data) {
        if (this.isHost) {
            throw new Error('Attempted to send data to self');
        }
        // If we're not the host, the only other connection we have is the host.
        this.send(data);
    }
}
export default NetServer;
/**
 * Coordinates signaling of peers.
 */
export class PeerCoordinator extends EventEmitter {
    constructor() {
        super(...arguments);
        this.host = false;
    }
}
