export default {
    // Credit for Hopeless#4061
    // Made with love ♥
    add: 'Ajouter',
    addMedia: 'Ajouter un média',
    admin: 'Administrateur',
    advanced: 'Avancé',
    allow: 'Autoriser',
    allowTracking: `Envoyer les informations d'utilisation`,
    allowTrackingDesc: `Des informations d'utilisation anonymes sont envoyées au développeur afin de mieux comprendre comment l'application est utilisée.`,
    avatar: 'Photo de profil',
    avatarCredit: '<0>Image sélectionnée fait par</0> <1>{{artist}}</1>',
    cancel: 'Annuler',
    changelog: 'Journal des modifications',
    chat: 'Chat',
    chatColor: 'Couleur du chat',
    chatPlaceholder: 'Message or URL',
    chatRevealHint: 'Appuie sur <1></1> pour révéler le chat.',
    chatNewMessage: 'Un nouveau message à été posté !',
    chooseDisplayName: `Choisissez votre nom d'utilisateur`,
    connecting: 'Connection..',
    continue: 'Continuer',
    copy: 'Copier',
    copyLink: 'Copier le lien',
    debug: 'Debug',
    developerMode: 'Mode développeur',
    developerModeDesc: 'Afficher les outils de développement pour inspecter la page Web.',
    directIP: 'IP directe',
    disallow: 'Refuser',
    disconnect: 'Déconnecter',
    disconnected: 'Déconnexion',
    dj: 'DJ',
    donate: 'Contribuer',
    donators: 'Donateurs',
    duplicate: 'Dupliquer',
    endSessionTitle: 'Terminer la session ?',
    endSessionDescription: 'Passer à une session privée déconnectera tous les utilisateurs connectés.',
    enterJoinDest: 'Entrez l’URL de la session Metastream.',
    findSession: 'Trouver une session',
    findSessionDescription: 'Rejoignez le canal <1>#sessions</1> de la communauté Metastream Discord pour rechercher les sessions de d’autres utilisateurs. Cliquez sur le logo Discord ci-dessous pour y rejoindre. ',
    friendCode: 'Code ami',
    getStarted: 'Commencer',
    info: 'Information',
    installToDesktop: 'Installer l’application bureau',
    invite: 'Inviter',
    interact: 'Interagir',
    join: 'Rejoindre',
    joinSession: 'Rejoindre une session',
    kick: 'Expulsé',
    language: 'Langues',
    lockQueue: `Verrouiller la file d'attente`,
    moveToTop: 'Déplacer vers le haut',
    maxUsers: 'Maximum d’utilisateurs',
    next: 'Suivant',
    nextUp: 'File d’attente',
    networkDisconnectHostDisconnect: 'L’hôte s’est déconnecté.',
    networkDisconnectError: 'Connexion de l’hôte perdue.',
    networkDisconnectInvalidClientInfo: 'Information du client invalide.',
    networkDisconnectVersionMismatch: 'Version du client non prise en charge.',
    networkDisconnectFull: 'La session est complète.',
    networkDisconnectKicked: 'Expulsé de la session.',
    networkTroubleshootingHelp: "Consultez le <1>Guide de dépannage réseau</1> pour obtenir de l'aide.",
    noticeAddedMedia: 'Ajout <Media id="{{mediaId}}">{{mediaTitle}}</Media>',
    noticeMediaError: 'Une erreur s’est produite lors du rajout de : {{url}}',
    noticeNowPlaying: 'Lecture en cours de <Media id="{{mediaId}}">{{mediaTitle}}</Media> rajouté par <Username id="{{userId}}">{{username}}</Username>.',
    noticeUserRequestJoin: '<Username id="{{userId}}">{{username}}</Username> demande la permission de rejoindre la session. ',
    offline: 'Hors ligne',
    ok: 'OK',
    openInBrowser: 'Ouvrir dans le navigateur',
    private: 'Privé',
    profile: 'Profil',
    public: 'Publique',
    reload: 'Recharger',
    remove: 'Retirer',
    repeat: 'Répéter',
    retry: 'Réessayez',
    requestUrl: 'Ajouter à la session',
    requestUrlPlaceholder: '',
    requiresDJPermissions: 'Nécessite l’autorisation DJ',
    requiresPortForward: 'Nécessite <1>l’ouverture du port</1> <3>{{port}}</3> pour accepter les connexions directes.',
    sessionMode: 'Mode session',
    sessionModePublicDescription: 'Tout le monde est autorisé à rejoindre.',
    sessionModePrivateDescription: 'La permission de rejoindre doit être accordée.',
    sessionModeOfflineDescription: `Personne n'est autorisé à rejoindre.`,
    settings: 'Réglages',
    shareFriendCode: `Partagez le code de votre ami ci-dessous pour inviter des amis.`,
    shareFriendCodeOther: `Partagez le code d'ami de {{name}} ci-dessous pour inviter des amis.`,
    sharePublicIP: 'Partager <1>votre adresse IP public</1> pour permettre à des amis de se connecter directement.',
    shareSessionUrl: `Partager l'URL de la session ci-dessous pour inviter des amis.`,
    startSession: 'Démarrer la session',
    thirdPartyIntegrations: 'Intégrations tierces',
    toggleDJ: 'Lui rajouter le rôle de DJ',
    unlimited: 'Illimité',
    unlockQueue: `Déverrouiller la file d'attente`,
    username: `Nom d'utilisateur`,
    users: 'Usagers',
    userJoined: '<Username id="{{userId}}">{{username}}</Username> a rejoint.',
    userLeft: '<Username id="{{userId}}">{{username}}</Username> est parti.',
    viewOnGitHub: 'Voir sur GitHub',
    waitingForHost: `En attente de l'hôte pour autoriser la connexion.`,
    welcome: 'Bienvenue'
};
