export default {
    add: 'Hinzufügen',
    addMediaCTA: 'Starte, indem du Medien hinzufügst.',
    addMedia: 'Medien hinzufügen',
    admin: 'Admin',
    advanced: 'Erweitert',
    allow: 'Erlauben',
    allowTracking: 'Nutzungsdaten senden',
    allowTrackingDesc: 'Sende anonyme Informationsstatistiken an den Entwickler um diese App besser zu machen.',
    avatar: 'Avatar',
    avatarCredit: '<0>Gewählter Avatar von</0> <1>{{artist}}</1>',
    cancel: 'Abbrechen',
    changelog: 'Changelog',
    chatColor: 'Chatfarbe',
    chatPlaceholder: 'Nachricht oder URL',
    chatRevealHint: 'Drücke <1></1> um den Chat zu öffnen.',
    chatNewMessage: 'Eine neue Nachricht wurde gepostet!',
    chooseDisplayName: 'Wähle deinen Benutzernamen',
    connecting: 'Verbinden',
    continue: 'Weiter',
    copy: 'Kopieren',
    copyLink: 'Link kopieren',
    debug: 'Debug',
    developerMode: 'Entwicklermodus',
    developerModeDesc: 'Zeige Entwicklerwerkzeuge um Webseiten zu untersuchen.',
    directIP: 'Direkte IP',
    disallow: 'Verbieten',
    disconnect: 'Verbindung trennen',
    disconnected: 'Verbindung getrennt',
    dj: 'DJ',
    donate: 'Spenden',
    donators: 'Spender',
    duplicate: 'Duplizieren',
    endSessionTitle: 'Sitzung beenden?',
    endSessionDescription: 'Der Umstieg auf eine private Sitzung wird alle Benutzer trennen.',
    enterJoinDest: 'Metastream Sitzungs-URL eingeben.',
    embedBlocked: 'Um die Wiedergabe mit <1>{{host}}</1> zu starten, muss Metastream die Webseite in einem Popup öffnen.',
    findSession: 'Sitzung finden',
    findSessionDescription: 'Trete den <1>#sessions</1> Channel auf dem Metastream Discord bei, um die Sitzungen anderer Nutzer zu finden. Klicke auf das Discord Logo, um dem Server beizutreten.',
    friendCode: 'Freundescode',
    focusPopup: 'Popup fokussieren',
    getStarted: 'Los gehts',
    info: 'Info',
    installToDesktop: 'Auf den Desktop installieren',
    invite: 'Einladen',
    join: 'Beitreten',
    joinSession: 'Sitzung beitreten',
    kick: 'Kicken',
    language: 'Sprache',
    lockQueue: 'Warteschlange schließen',
    moveToTop: 'Nach oben',
    maxUsers: 'Nutzerlimit',
    next: 'Weiter',
    nextUp: 'Als nächstes',
    networkDisconnectHostDisconnect: 'Der Host hat die Verbindung geschlossen.',
    networkDisconnectError: 'Netzwerk Timeout.',
    networkDisconnectInvalidClientInfo: 'Ungültige Client Informationen.',
    networkDisconnectVersionMismatch: `Client Versionen stimmen nicht überein.`,
    networkDisconnectFull: 'Sitzung ist voll.',
    networkDisconnectKicked: 'Aus der Sitzung gekickt.',
    networkTroubleshootingHelp: 'Schau dir den <1>Netzwerk Problemguide</1> für Hilfestellungen an.',
    noticeAddedMedia: '<Media id="{{mediaId}}">{{mediaTitle}}</Media> hinzugefügt',
    noticeMediaError: 'Es gab ein Problem die URL {{url}} anzufragen',
    noticeNowPlaying: 'Aktuelle Wiedergabe: <Media id="{{mediaId}}">{{mediaTitle}}</Media> vorgeschlagen von <Username id="{{userId}}">{{username}}</Username>.',
    noticeUserRequestJoin: '<Username id="{{userId}}">{{username}}</Username> möchte beitreten.',
    offline: 'Offline',
    ok: 'OK',
    openInBrowser: 'Im Browser öffnen',
    private: 'Privat',
    profile: 'Profil',
    public: 'Öffentlich',
    reload: 'Neu laden',
    remove: 'Entfernen',
    repeat: 'Wiederholen',
    requestUrl: 'URL hinzufügen',
    requestUrlPlaceholder: '',
    requiresDJPermissions: 'Erfordert DJ Rechte',
    requiresPortForward: 'Erfordert <1>das freigeben des Ports</1> <3>{{port}}</3> um direkte Verbindungen zu ermöglichen.',
    sessionMode: 'Sitzungsmodus',
    sessionModePublicDescription: 'Jedem ist es erlaubt beizutreten.',
    sessionModePrivateDescription: 'Erlaubnis um beizutreten muss gegeben werden.',
    sessionModeOfflineDescription: 'Niemand darf beitreten.',
    settings: 'Einstellungen',
    shareFriendCode: 'Teile den unten stehenden Freundescode mit deinen Freunden.',
    shareFriendCodeOther: 'Teile {{name}}’s unten stehenden Freundescode mit deinen Freunden.',
    sharePublicIP: 'Teile <1>deine öffentliche IP-Adresse</1> um es Freunden zu erlauben direkt beizutreten.',
    shareSessionUrl: 'Teile die Sitzungs-URL um Freunde einzuladen.',
    startSession: 'Sitzung starten',
    thirdPartyIntegrations: 'Drittentwickler Integrationen',
    toggleDJ: 'DJ wechseln',
    unlimited: 'Unlimitiert',
    unlockQueue: 'Warteschlange öffnen',
    username: 'Benutzername',
    users: 'Benutzer',
    userJoined: '<Username id="{{userId}}">{{username}}</Username> ist der Sitzung beigetreten.',
    userLeft: '<Username id="{{userId}}">{{username}}</Username> hat die Sitzung verlassen.',
    viewOnGitHub: 'Auf GitHub ansehen',
    waitingForHost: 'Warte auf Host für die Erlaubnis zum beitreten',
    welcome: 'Willkommen',
    always: 'Immer',
    appearance: 'Aussehen',
    autoFullscreen: 'Auto-Vollbild',
    autoFullscreenDesc: 'Aktives Video automatisch innerhalb des Fensters bestmöglich platzieren.',
    chat: 'Chat',
    chatMessageFailed: 'Nachricht konnte nicht gesendet werden. Bitte versuche es erneut.',
    chatTimestamp: 'Chat Zeitstempel',
    chatTimestampDesc: 'Zeige die Zeitstempel von Chatnachrichten an.',
    chatTyping1: '<0></0> schreibt…',
    chatTyping2: '<0></0> und <2></2> schreiben…',
    chatTyping3: '<0></0>, <2></2>, und <4></4> schreiben…',
    chatTypingSeveral: 'Mehrere Nutzer schreiben…',
    displayName: 'Anzeigename',
    endSessionModalDescription: 'Alle Nutzer werden aus dieser Sitzung geworfen.',
    endSessionButton: 'Sitzung beenden',
    help: 'Hilfe',
    interact: 'Interagieren',
    networkDisconnectMultiTab: 'Du hostest eine Metastream Sitzung über mehr als ein Browser oder Tab. Bitte schließe andere Tabs oder Browser und versuche es erneut.',
    networkDisconnectSessionNotFound: 'Sitzung nicht gefunden.',
    once: 'Einmalig',
    openInPopup: 'Im Popup öffnen',
    retry: 'Erneut versuchen',
    required: 'Benötigt',
    safeBrowse: 'Sicheres Surfen',
    safeBrowseDesc: 'Unbekannte Seiten müssen zunächst freigegeben werden, bevor sie geladen werden.',
    safeBrowseLoadContent: 'Lade Inhalt von <1>{{host}}</1>?',
    safeBrowseDisableForSession: 'Sicheres Surfen für diese Sitzung deaktivieren',
    session: 'Sitzung',
    openSecondPopup: '(noch einmal)',
    theaterMode: 'Theater-Modus',
    theaterModeDesc: 'Verstecke alle Inhalte, die nichts mit dem Videoinhalt zutun haben. Dies könnte jedoch auch Untertitel verstecken.',
    userNameChanged: '<Username id="{{userId}}">{{prevName}}</Username> heißt nun <Username id="{{userId}}">{{name}}</Username>.',
    patreonLogin: 'Login mit Patreon',
    patreonLogout: 'Logout',
    patreonPledge: 'Werde Patron',
    patreonPledgeRequired: 'Nur für Patrons verfügbar',
    patreonPledgeTierRequired: 'Nur für Patrons in der {{tier}} Stufe verfügbar',
    playingInPopup: '<0>{{host}}</0> spielt etwas in einem Popup.',
    popupBlocked: 'Ein Popup konnte nicht geöffnet werden. Erlaube Popups auf dieser Seite oder drücke die Taste erneut.',
    supportMetastream: 'Supporte Metastream um Belohnungen zu erhalten!',
    uiDockToRight: 'An rechte Seite andocken',
    uiUndock: 'Als eigenes Overlay abdocken',
    updateAvailable: 'Ein Update für Metastream steht bereit. Drücke den UPDATE Knopf oben rechts um das Update zu erhalten.',
    welcomeToMetastream: 'Willkommen zu Metastream'
};
