export default {
    add: 'Adicionar',
    addMedia: 'Adicionar Midia',
    addMediaCTA: 'Inicie adicionando uma mídia',
    admin: 'Administrador',
    advanced: 'Avançado',
    allow: 'Permitir',
    allowTracking: 'Enviar informações de uso do aplicativo',
    allowTrackingDesc: 'Informações de uso anônimas serão enviadas para o desenvolvedor para entender melhor como o aplicativo está sendo usado.',
    always: 'Sempre',
    appearance: 'Aparência',
    autoFullscreen: 'Tela cheia automática',
    autoFullscreenDesc: 'Ampliar para ajustar o video a janela',
    avatar: 'Foto de Perfil',
    avatarCredit: '<0>Arte do perfil selecionado feita por</0> <1>{{artist}}</1>',
    cancel: 'Cancelar',
    changelog: 'Registro de Alterações',
    chat: 'Bate-papo',
    chatColor: 'Cor no bate-papo',
    chatMessageFailed: 'Não foi possível enviar sua mensagem. Por favor tente novamente.',
    chatPlaceholder: 'Mensagem ou link',
    chatRevealHint: 'Aperte <1></1> para revelar o bate-papo.',
    chatNewMessage: 'Uma nova mensagem foi enviada!',
    chatTimestamp: 'Horário do bate-papo',
    chatTimestampDesc: 'Mostrar a hora que a mensagem foi enviada.',
    chatTyping1: '<0></0> está digitando…',
    chatTyping2: '<0></0> e <2></2> estão digitando…',
    chatTyping3: '<0></0>, <2></2>, e <4></4> estão digitando…',
    chatTypingSeveral: 'Várias pessoas estão digitando…',
    chooseDisplayName: 'Escolha o seu nick',
    connecting: 'Conectando',
    continue: 'Continuar',
    copy: 'Copiar',
    copyLink: 'Copiar o link',
    debug: 'Inspecionar',
    developerMode: 'Modo do desenvolvedor',
    developerModeDesc: 'Mostrar ferramentas de desenvolvedor para inspecionar paginas da web.',
    directIP: 'IP Direto',
    disallow: 'Negar',
    disconnect: 'Desconectar',
    disconnected: 'Desconectado',
    displayName: 'Apelido',
    dj: 'DJ',
    donate: 'Doar',
    donators: 'Doadores',
    duplicate: 'Duplicar',
    embedBlocked: '<0>{{host}}</0> bloqueia embeds, assistir em popup é necessário.',
    endSessionTitle: 'Encerrar sessão?',
    endSessionDescription: 'Mudar para uma sessão privada irá desconectar todos os usuários conectados.',
    endSessionModalDescripiton: 'Todos os usuários serão desconectados da sessão',
    endSessionButton: 'Encerrar Sessão?',
    enterJoinDest: 'Insira um link de uma sessão do Metastream',
    findSession: 'Encontrar sessões',
    findSessionDescription: 'Entre no canal <1>#sessions</1> do servidor do Metastream no Discord para encontrar sessões de outro usuários. Clique na logo do discord abaixo para entrar.',
    friendCode: 'Codigo de amigo',
    getStarted: 'Começar',
    help: 'Ajuda',
    info: 'Informação',
    installToDesktop: 'Instalar para o desktop',
    interact: 'Interagir',
    invite: 'Convidar',
    join: 'Entrar',
    joinSession: 'Entrar na sessão',
    kick: 'Expulsar',
    language: 'Idioma',
    lockQueue: 'Travar fila',
    moveToTop: 'Mover para o topo',
    maxUsers: 'Máximo de usuários',
    next: 'Proximo',
    nextUp: 'Proximos',
    networkDisconnectHostDisconnect: 'O host fechou a sessão',
    networkDisconnectError: 'Erro de Conexão',
    networkDisconnectInvalidClientInfo: 'Informação de cliente invalida',
    networkDisconnectVersionMismatch: `Versões diferentes`,
    networkDisconnectFull: 'A sessão está cheia',
    networkDisconnectKicked: 'Expulso da sessão',
    networkDisconnectMultiTab: 'Você está hospedando uma sessão do Metastream em mais de um navegador ou aba. Por favor feche as outras abas ou outros navegadores, e recarregue a página.',
    networkDisconnectSessionNotFound: 'Sessão não encontrada',
    networkTroubleshootingHelp: 'Veja o <1>Guia de transtornos de rede</1> para ajuda.',
    noticeAddedMedia: '<Media id="{{mediaId}}">{{mediaTitle}}</Media> foi adcionado.',
    noticeMediaError: 'Erro ao acessar {{url}}',
    noticeNowPlaying: 'Agora reproduzindo <Media id="{{mediaId}}">{{mediaTitle}}</Media> adicionado por <Username id="{{userId}}">{{username}}</Username>.',
    noticeUserRequestJoin: '<Username id="{{userId}}">{{username}}</Username> está pedindo para entrar.',
    offline: 'Offline',
    ok: 'OK',
    once: 'Uma vez',
    openInBrowser: 'Abrir no navegador',
    openInPopup: 'Abrir em popup',
    patreonLogin: 'Logar com o Patreon',
    patreonLogout: 'Deslogar',
    patreonPledge: 'Vire um Patrão',
    patreonPledgeRequired: 'Disponivel apenas para patrões',
    patreonPledgeTierRequired: 'Apenas para patrões nível {{tier}}',
    playingInPopup: 'Tocando no popup',
    private: 'Privada',
    profile: 'Perfil',
    public: 'Pública',
    reload: 'Recarregar',
    remove: 'Remover',
    repeat: 'Repetir',
    retry: 'Tentar Novamente',
    requestUrl: 'Adicionar a sessão',
    requestUrlPlaceholder: '',
    required: 'Necessário',
    requiresDJPermissions: 'Requer permissão de DJ',
    requiresPortForward: 'Requer <1>liberação de portas</1> da porta <3>{{port}}</3> para aceitar coneções diretas.',
    safeBrowse: 'Navegação Segura',
    safeBrowseDesc: 'Pedir aprovação para entrar em sites não reconhecidos',
    safeBrowseLoadContent: 'Carregar conteudo de <1>{{host}}</1>?',
    safeBrowseDisableForSession: 'Desativar a navegação segura nessa sessão',
    session: 'Sessão',
    sessionMode: 'Modo de sessão',
    sessionModePublicDescription: 'Todos podem entrar.',
    sessionModePrivateDescription: 'Quem entrar precisa de permissão.',
    sessionModeOfflineDescription: 'Uma sala privada.',
    settings: 'Configurações',
    shareFriendCode: 'Compartilhe seu codigo de amigo abaixo para convidar amigos.',
    shareFriendCodeOther: 'Compartihle o codigo de amigo de {{name}} para convidar amigos.',
    sharePublicIP: 'Envie <1>seu endereço de IP público</1> para permitir amigos conectarem diretamente.',
    shareSessionUrl: 'Compartilhe o link da sessão abaixo para convidar amigos.',
    supportMetastream: 'Ajude o Metastream para desbloquear prêmios!',
    startSession: 'Iniciar sessão',
    theaterMode: 'Modo Teatro',
    theaterModeDesc: 'Esconder todos os elementos que não são video na página. Isso pode esconder legendas ocultas',
    thirdPartyIntegrations: 'Integrações de terceiros',
    toggleDJ: 'Alternar DJ',
    unlimited: 'Ilimitados',
    unlockQueue: 'Destravar fila',
    updateAvailable: 'Uma atualização do Metastream está disponivel. aperta o botão de ATUALIZAR no canto superior esquerdo para receber a atualização.',
    username: 'Nome de usuário',
    users: 'Usuários',
    userJoined: '<Username id="{{userId}}">{{username}}</Username> entrou.',
    userLeft: '<Username id="{{userId}}">{{username}}</Username> saiu.',
    userNameChanged: '<Username id="{{userId}}">{{prevName}}</Username> agora é conhecido como <Username id="{{userId}}">{{name}}</Username>.',
    viewOnGitHub: 'Ver no GitHub',
    waitingForHost: 'Esperando o host permitir a conexão',
    welcome: 'Bem Vindo(a)',
    welcomeToMetastream: 'Bem vindo(a) ao Metastream'
};
