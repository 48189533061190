import React, { Component } from 'react';
import cx from 'classnames';
import styles from './ListOverlay.css';
import Menu from '@material-ui/core/Menu';
import { PanelHeader } from './PanelHeader';
export class ListOverlay extends Component {
    constructor() {
        super(...arguments);
        this.state = { menuOpen: false };
        this.handleClose = () => {
            this.setState({ menuOpen: false });
        };
        this.onSelect = (e, selection) => {
            if (!e.target)
                return;
            // 'instanceof' does not work with constructors in another window. Need to
            // account for elements rendered in popups
            let targetWindow;
            try {
                targetWindow = e.target.ownerDocument.defaultView;
                if (targetWindow.constructor.name !== 'Window')
                    return;
            }
            catch {
                return;
            }
            if (e.target instanceof targetWindow.HTMLElement ||
                e.target instanceof targetWindow.SVGElement) {
                this.setState({ menuOpen: true, selection, menuAnchorEl: e.target });
            }
        };
    }
    render() {
        return (React.createElement("div", { id: this.props.id, className: cx(this.props.className, styles.container, {
                active: this.state.menuOpen
            }) },
            React.createElement(PanelHeader, { title: this.props.title, tagline: this.props.tagline, action: this.props.action, onTitleClick: this.props.onTitleClick }),
            React.createElement("div", { className: styles.list }, this.props.children),
            this.renderMenu()));
    }
    renderMenu() {
        const { menuAnchorEl, selection, menuOpen: open } = this.state;
        return (React.createElement(Menu, { anchorEl: menuAnchorEl, open: open, onClose: this.handleClose, disableScrollLock: true }, selection && this.props.renderMenuOptions(selection, this.handleClose)));
    }
}
