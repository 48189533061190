import { netRpcMiddleware } from 'network/middleware/rpc';
import { netSyncMiddleware } from 'network/middleware/sync';
import { usersMiddleware } from 'lobby/middleware/users';
import { sessionMiddleware } from 'lobby/middleware/session';
import { pwaMiddleware } from '../middleware/pwa';
import { MediaSessionObserver } from '../lobby/mediaSessionObserver';
import { mediaSessionMiddleware } from '../lobby/middleware/mediaSession';
export const configureAppMiddleware = (opts) => {
    const list = [
        netRpcMiddleware({ extra: opts.extra }),
        netSyncMiddleware(),
        usersMiddleware(),
        sessionMiddleware([new MediaSessionObserver()]),
        pwaMiddleware(),
        mediaSessionMiddleware()
    ];
    const middleware = list.filter(Boolean);
    return middleware;
};
