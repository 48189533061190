import { actionCreator } from 'utils/redux';
import { rpc } from 'network/middleware/rpc';
import { getCurrentMedia, getPlaybackState, getPlaybackTime, getCurrentMediaId, hasPlaybackPermissions, getMediaById } from 'lobby/reducers/mediaPlayer.helpers';
import { getNumUsers } from 'lobby/reducers/users.helpers';
import { translateEscaped } from 'locale';
import { clamp } from 'utils/math';
import { getMediaParser } from './media-common';
import { addChat } from './chat';
export const playPauseMedia = actionCreator('PLAY_PAUSE_MEDIA');
export const repeatMedia = actionCreator('REPEAT_MEDIA');
export const seekMedia = actionCreator('SEEK_MEDIA');
export const setPlaybackRate = actionCreator('SET_PLAYBACK_RATE');
export const setMedia = actionCreator('SET_MEDIA');
export const endMedia = actionCreator('END_MEDIA');
export const queueMedia = actionCreator('QUEUE_MEDIA');
export const updateMedia = actionCreator('UPDATE_MEDIA');
export const deleteMedia = actionCreator('DELETE_MEDIA');
export const moveToTop = actionCreator('MOVE_MEDIA_TO_TOP');
export const lockQueue = actionCreator('LOCK_QUEUE');
export const updateServerClockSkew = actionCreator('UPDATE_SERVER_CLOCK_SKEW');
export const setPendingMedia = actionCreator('SET_PENDING_MEDIA');
/** Media timer until playback ends. This assumes only one media player exists at a time.*/
let mediaTimeoutId = null;
export const nextMedia = (force) => {
    return async (dispatch, getState) => {
        const state = getState();
        const media = getCurrentMedia(state);
        if (media) {
            if (!force && media.hasMore) {
                await dispatch(advanceMedia(media));
            }
            else {
                dispatch(endMedia(force));
                dispatch(updatePlaybackTimer());
            }
        }
        // Announce now playing media
        const current = getCurrentMedia(getState());
        if (current) {
            dispatch(multi_announceMediaChange(current.id));
        }
    };
};
const advanceMedia = (playlist) => {
    return async (dispatch) => {
        console.info('Advancing media', playlist);
        let res;
        try {
            const mediaParser = await getMediaParser();
            res = await mediaParser.resolveMediaPlaylist(playlist);
        }
        catch (e) {
            console.error(e);
        }
        if (!res) {
            // TODO: Notify clients
            console.log(`Failed to resolve media playlist`);
            return;
        }
        console.log('Media response', res);
        const media = {
            ...playlist,
            type: res.type,
            url: res.url,
            title: res.title || res.url,
            duration: res.duration,
            description: res.description,
            imageUrl: res.thumbnails && res.thumbnails[0 /* Default */],
            hasMore: res.hasMore
        };
        if (res.state) {
            media.state = res.state;
        }
        dispatch(setMedia(media));
        dispatch(updatePlaybackTimer());
    };
};
export const updatePlaybackTimer = () => {
    return (dispatch, getState) => {
        const state = getState();
        const media = getCurrentMedia(state);
        const playback = getPlaybackState(state);
        if (mediaTimeoutId) {
            clearTimeout(mediaTimeoutId);
            mediaTimeoutId = null;
        }
        if (playback === 1 /* Playing */) {
            const curTime = getPlaybackTime(state);
            const duration = media && media.duration;
            if (duration && duration > 0) {
                const remaining = (duration - curTime) / state.mediaPlayer.playbackRate;
                // Media end callback
                mediaTimeoutId = setTimeout(() => {
                    dispatch(nextMedia());
                }, remaining);
            }
        }
    };
};
const announceMediaChange = (mediaId) => (dispatch, getState) => {
    if (getNumUsers(getState()) === 1)
        return;
    const media = getMediaById(getState(), mediaId);
    if (!media)
        return;
    const content = translateEscaped('noticeNowPlaying', {
        userId: media.ownerId,
        username: media.ownerName,
        mediaId: media.id,
        mediaTitle: media.title
    });
    dispatch(addChat({ content, html: true, timestamp: Date.now() }));
};
export const multi_announceMediaChange = rpc('announceMediaChange', "multicast" /* Multicast */, announceMediaChange);
export const enqueueMedia = (media, index) => {
    return (dispatch, getState) => {
        const state = getState();
        const current = getCurrentMedia(state);
        let queued;
        if (current) {
            dispatch(queueMedia({ media, index }));
            queued = true;
        }
        else {
            dispatch(setMedia(media));
            dispatch(updatePlaybackTimer());
            dispatch(multi_announceMediaChange(media.id));
            queued = false;
        }
        return queued;
    };
};
const requestPlayPause = () => (dispatch, getState, context) => {
    const state = getState();
    if (!hasPlaybackPermissions(state, context.client))
        return;
    const playback = getPlaybackState(state);
    switch (playback) {
        case 1 /* Playing */:
        case 2 /* Paused */:
            dispatch(playPauseMedia());
            dispatch(updatePlaybackTimer());
            break;
    }
};
export const server_requestPlayPause = rpc('requestPlayPause', "server" /* Server */, requestPlayPause);
const requestNextMedia = () => (dispatch, getState, context) => {
    if (!hasPlaybackPermissions(getState(), context.client))
        return;
    dispatch(nextMedia());
};
export const server_requestNextMedia = rpc('requestNextMedia', "server" /* Server */, requestNextMedia);
const requestRepeatMedia = () => (dispatch, getState, context) => {
    if (!hasPlaybackPermissions(getState(), context.client))
        return;
    dispatch(repeatMedia());
};
export const server_requestRepeatMedia = rpc('requestRepeatMedia', "server" /* Server */, requestRepeatMedia);
const requestSeek = (time) => (dispatch, getState, context) => {
    if (!hasPlaybackPermissions(getState(), context.client))
        return;
    const state = getState();
    const media = getCurrentMedia(state);
    if (!media || !media.duration)
        return;
    time = clamp(time, 0, media.duration);
    if (isNaN(time))
        return;
    dispatch(seekMedia(time));
    dispatch(updatePlaybackTimer());
};
export const server_requestSeek = rpc('requestSeek', "server" /* Server */, requestSeek);
const requestSeekRelative = (relativeTime) => (dispatch, getState, context) => {
    const curTime = getPlaybackTime(getState());
    const time = curTime + relativeTime;
    requestSeek(time)(dispatch, getState, context);
};
export const server_requestSeekRelative = rpc('requestSeekRelative', "server" /* Server */, requestSeekRelative);
const requestSetPlaybackRate = (playbackRate) => (dispatch, getState, context) => {
    if (!hasPlaybackPermissions(getState(), context.client))
        return;
    dispatch(setPlaybackRate(playbackRate));
    dispatch(updatePlaybackTimer());
};
export const server_requestSetPlaybackRate = rpc('requestSetPlaybackRate', "server" /* Server */, requestSetPlaybackRate);
const requestDeleteMedia = (mediaId) => (dispatch, getState, context) => {
    if (!hasPlaybackPermissions(getState(), context.client))
        return;
    const currentId = getCurrentMediaId(getState());
    if (currentId === mediaId) {
        dispatch(nextMedia(true));
        return;
    }
    dispatch(deleteMedia(mediaId));
};
export const server_requestDeleteMedia = rpc('requestDeleteMedia', "server" /* Server */, requestDeleteMedia);
const requestMoveToTop = (mediaId) => (dispatch, getState, context) => {
    if (!hasPlaybackPermissions(getState(), context.client))
        return;
    dispatch(moveToTop(mediaId));
};
export const server_requestMoveToTop = rpc('requestMoveToTop', "server" /* Server */, requestMoveToTop);
const requestToggleQueueLock = () => (dispatch, getState, context) => {
    if (!hasPlaybackPermissions(getState(), context.client))
        return;
    dispatch(lockQueue());
};
export const server_requestToggleQueueLock = rpc('requestToggleQueueLock', "server" /* Server */, requestToggleQueueLock);
