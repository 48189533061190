import * as React from 'react';
import styles from './PanelHeader.css';
export const PanelHeader = ({ title, tagline, action, onTitleClick }) => {
    const clickable = typeof onTitleClick === 'function';
    const titleContainer = React.createElement(clickable ? 'button' : 'div', clickable
        ? { className: styles.titleContainer, type: 'button', onClick: onTitleClick }
        : { className: styles.titleContainer }, React.createElement(React.Fragment, null,
        React.createElement("h2", { className: styles.title }, title),
        tagline && React.createElement("span", { className: styles.tagline }, tagline)));
    return (React.createElement("header", { className: styles.header },
        titleContainer,
        React.createElement("div", { className: styles.actions }, action)));
};
