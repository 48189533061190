import React, { Component } from 'react';
import styles from './MoreButton.css';
import { Icon } from 'components/Icon';
export class MoreButton extends Component {
    constructor() {
        super(...arguments);
        this.menu = null;
        this.state = {
            isOpen: false
        };
        this.toggleMenu = () => {
            this.state.isOpen ? this.close() : this.open();
        };
        this.onDocumentClick = (event) => {
            this.close();
        };
    }
    componentWillUnmount() {
        if (this.state.isOpen) {
            this.close();
        }
    }
    renderMenu() {
        return (React.createElement("div", { ref: e => {
                this.menu = e;
            }, className: styles.menu }, this.props.children));
    }
    render() {
        return (React.createElement("div", { className: styles.container },
            React.createElement("button", { type: "button", className: this.props.buttonClassName, onClick: this.toggleMenu, disabled: this.props.disabled },
                React.createElement(Icon, { name: "more-vertical" })),
            this.state.isOpen && this.renderMenu()));
    }
    open() {
        document.addEventListener('click', this.onDocumentClick, false);
        this.setState({ isOpen: true });
    }
    close() {
        document.removeEventListener('click', this.onDocumentClick, false);
        this.setState({ isOpen: false });
    }
}
