export default {
    add: 'Aggiungi',
    addMedia: 'Aggiungi media',
    addMediaCTA: 'Inizia aggiungendo contenuti multimediali alla sessione.',
    admin: 'Admin',
    advanced: 'Avanzate',
    allow: 'Consenti',
    allowTracking: `Invia informazioni sull'utilizzo dell'app`,
    allowTrackingDesc: `Informazioni di utilizzo anonime vengono inviate allo sviluppatore per comprendere meglio come viene utilizzata l'app.`,
    always: 'Sempre',
    appearance: 'Aspetto',
    autoFullscreen: 'Schermo intero automatico',
    autoFullscreenDesc: `Ingrandisci per adattare il video attivo all'interno della cornice della finestra.`,
    avatar: 'Avatar',
    avatarCredit: '<0>Avatar selezionato creato da</0> <1>{{artist}}</1>',
    cancel: 'Annulla',
    changelog: 'Changelog',
    chat: 'Chat',
    chatColor: 'Colore chat',
    chatMessageFailed: 'Impossibile inviare il tuo messaggio. Per favore riprova.',
    chatPlaceholder: 'Messaggio o URL',
    chatRevealHint: 'Premi <1></1> per rivelare la chat.',
    chatNewMessage: 'È stato pubblicato un nuovo messaggio!',
    chatTimestamp: 'Data e ora della chat',
    chatTimestampDesc: 'Mostra data e ora accanto ai messaggi della chat.',
    chatTyping1: '<0></0> sta scrivendo…',
    chatTyping2: '<0></0> e <2></2> stanno scrivendo…',
    chatTyping3: '<0></0>, <2></2>, e <4></4> stanno scrivendo…',
    chatTypingSeveral: 'Diversi utenti stanno scrivendo…',
    chooseDisplayName: 'Scegli il nome visualizzato',
    connecting: 'Connessione',
    continue: 'Continua',
    copy: 'Copia',
    copyLink: 'Copia link',
    debug: 'Debug',
    developerMode: 'Modalità sviluppatore',
    developerModeDesc: `Mostra strumenti di sviluppo per l'ispezione di pagine web.`,
    directIP: 'IP diretto',
    disallow: 'Rifiuta',
    disconnect: 'Disconnetti',
    disconnected: 'Disconnesso',
    displayName: 'Nome visualizzato',
    dj: 'DJ',
    donate: 'Dona',
    donators: 'Donatori',
    duplicate: 'Duplica',
    endSessionTitle: 'Fine sessione?',
    endSessionDescription: 'Passare a una sessione offline disconnetterà tutti gli utenti connessi.',
    endSessionModalDescription: 'Tutti gli utenti verranno disconnessi dalla sessione.',
    endSessionButton: 'Fine sessione',
    enterJoinDest: 'Inserisci un URL sessione Metastream.',
    findSession: 'Trova Sessione',
    findSessionDescription: 'Unisciti al canale <1>#sessions</1> nella community Discord di Metastream per trovare sessioni di altri utenti. Fai clic sul logo Discord in basso per unirti.',
    friendCode: 'Codice amico',
    getStarted: 'Inizia',
    help: 'Aiuto',
    info: 'Info',
    installToDesktop: 'Installa sul desktop',
    interact: 'Interagisci',
    invite: 'Invita',
    join: 'Unisciti',
    joinSession: 'Unisciti alla sessione',
    kick: 'Caccia',
    language: 'Lingua',
    lockQueue: 'Blocca coda',
    moveToTop: 'Vai in alto',
    maxUsers: 'Utenti massimi',
    next: 'Successivo',
    nextUp: 'Prossimo',
    networkDisconnectHostDisconnect: `L'host ha chiuso la connessione.`,
    networkDisconnectError: 'Errore di rete.',
    networkDisconnectInvalidClientInfo: 'Informazioni sul client non valide.',
    networkDisconnectVersionMismatch: `C'è una mancata corrispondenza della versione client. Il tuo client, o quello dell'host, richiede un aggiornamento.`,
    networkDisconnectFull: 'La sessione è piena.',
    networkDisconnectKicked: 'Cacciato dalla sessione.',
    networkDisconnectMultiTab: 'Stai ospitando una sessione Metastream da più di un browser o scheda. Chiudi le altre schede o gli altri browser, quindi ricarica la pagina.',
    networkDisconnectSessionNotFound: 'Sessione non trovata.',
    networkTroubleshootingHelp: 'Vedi la <1>guida alla risoluzione dei problemi di rete</1> per aiuto.',
    noticeAddedMedia: 'Aggiunto <Media id="{{mediaId}}">{{mediaTitle}}</Media>',
    noticeMediaError: 'Si è verificato un errore nella richiesta di {{url}}',
    noticeNowPlaying: 'Adesso in riproduzione <Media id="{{mediaId}}">{{mediaTitle}}</Media> richiesto da <Username id="{{userId}}">{{username}}</Username>.',
    noticeUserRequestJoin: '<Username id="{{userId}}">{{username}}</Username> richiede il permesso di accedere.',
    offline: 'Offline',
    ok: 'Ok',
    once: 'Una volta',
    openInBrowser: 'Apri nel browser',
    openInPopup: 'Apri in un popup',
    private: 'Privata',
    profile: 'Profilo',
    public: 'Pubblica',
    reload: 'Ricarica',
    remove: 'Rimuovi',
    repeat: 'Ripeti',
    retry: 'Riprova',
    requestUrl: 'Aggiungi alla sessione',
    requestUrlPlaceholder: '',
    required: 'Richiesto',
    requiresDJPermissions: 'Richiede le autorizzazioni DJ',
    requiresPortForward: `Richiede l'<1>impostazione del forwarding</1> della porta <3>{{port}}</3> per accettare connessioni dirette.`,
    safeBrowse: 'Navigazione sicura',
    safeBrowseDesc: `Richiede l'approvazione prima di caricare un sito web non riconosciuto.`,
    safeBrowseLoadContent: 'Caricare contenuti da <1>{{host}}</1>?',
    safeBrowseDisableForSession: 'Disabilita la navigazione sicura per questa sessione',
    session: 'Sessione',
    sessionMode: 'Modalità sessione',
    sessionModePublicDescription: 'Chiunque è autorizzato a partecipare.',
    sessionModePrivateDescription: `L'autorizzazione a partecipare deve essere concessa esplicitamente.`,
    sessionModeOfflineDescription: 'Nessuno è autorizzato a partecipare.',
    settings: 'Impostazioni',
    shareFriendCode: 'Condividi il tuo codice amico qui sotto per invitare amici.',
    shareFriendCodeOther: 'Condividi il codice amico di {{name}} qui sotto per invitare amici.',
    sharePublicIP: 'Condividi <1>il tuo indirizzo IP pubblico</1> per consentire agli amici di connettersi direttamente.',
    shareSessionUrl: `Condividi l'URL della sessione in basso per invitare amici.`,
    startSession: 'Inizia sessione',
    theaterMode: 'Modalità cinema',
    theaterModeDesc: 'Nascondi tutti i contenuti non video sulla pagina web. Nota che questo potrebbe anche nascondere i sottotitoli "soft".',
    thirdPartyIntegrations: 'Integrazioni di terze parti',
    toggleDJ: 'Attiva/disattiva DJ',
    unlimited: 'Illimitata',
    unlockQueue: 'Sblocca coda',
    username: 'Nome utente',
    users: 'Utenti',
    userJoined: '<Username id="{{userId}}">{{username}}</Username> si è unito.',
    userLeft: '<Username id="{{userId}}">{{username}}</Username> è uscito.',
    userNameChanged: '<Username id="{{userId}}">{{prevName}}</Username> è ora noto come <Username id="{{userId}}">{{name}}</Username>.',
    viewOnGitHub: 'Vedi su GitHub',
    waitingForHost: `In attesa dell'host per consentire la connessione`,
    welcome: 'Benvenuto'
};
